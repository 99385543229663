import Loader from "../Loader";

export const Skeleton = () => {
  return (
    <Loader height={700} width="100%">
      <rect x="0" y="0" rx="4" ry="4" width="30%" height="50px" />
      <rect x="50%" y="0" rx="4" ry="4" width="30%" height="50px" />
      <rect x="82%" y="0" rx="4" ry="4" width="18%" height="50px" />
      {[...new Array(20)].map((val, i) => {
        return (<rect key={i} x="0" y={(i + 1) * 55} rx="4" ry="4" width="100%" height="50px" />);
      })}
    </Loader>
  )
};

export default Skeleton;