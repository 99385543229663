import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TableSearch } from '../TableHeader';
import { Checkbox } from '../../common/Checkbox';
import Button, {BUTTON_COLORS} from '../../common/Button/Button';
import { Dropdown } from '../../common/Dropdown';
import { LeftRightSwitch } from '../../common/LeftRightSwitch';
import Skeleton from '../../common/Skeleton';
import sortDefault from 'assets/icons/table/sort-default.svg';
import sortUp from 'assets/icons/table/sort-up.svg';
import sortDown from 'assets/icons/table/sort-down.svg';
import './index.scss';
import classNames from 'classnames';
import {Link} from "react-router-dom";
import {Collapse} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

export class TableContent extends React.Component {

  static propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      attr: PropTypes.string,
    })),
    fixedLeft: PropTypes.arrayOf(PropTypes.string),
    fixedRight: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.object,
    rejectionList: PropTypes.object,
    initialData: PropTypes.array,
    userProfile: PropTypes.object,
    setRejectionList: PropTypes.func,
    setInitialData: PropTypes.func,
    setIsRejectionCommentsOpen: PropTypes.func,
    search: PropTypes.string,
    isFilterBarOpen: PropTypes.bool,
    groupBy: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.shape({
      limit: PropTypes.number,
      page: PropTypes.number,
      total: PropTypes.number,
    }),
    loading: PropTypes.bool,
    onStarChange: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    onFilterChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onSort: PropTypes.func,
    onGroupToggle: PropTypes.func,
    viewRejectionReasonRights: PropTypes.bool,
    hasAsbuiltColumn: PropTypes.bool,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    defaultPaginate: PropTypes.number,
    getErrorsTooltipData: PropTypes.func,
    showGroupCheckbox: PropTypes.bool,
    rejectionRights: PropTypes.bool,
    fixedActionsBtn: PropTypes.array,
    fixedHeader: PropTypes.bool,
    onDragAndDrop: PropTypes.func
  };

  static defaultProps = {
    rows: [],
    headers: [],
    fixedLeft: [],
    fixedRight: [],
    initialData: [],
    options: {},
    rejectionList: {},
    userProfile: {},
    setRejectionList: null,
    setInitialData: null,
    setIsRejectionCommentsOpen: null,
    search: "",
    isFilterBarOpen: false,
    groupBy: [],
    meta: {},
    loading: false,
    onStarChange: null,
    onCheckboxChange: null,
    onFilterChange: null,
    onPageChange: null,
    onSort: null,
    onGroupToggle: null,
    viewRejectionReasonRights: false,
    hasAsbuiltColumn: false,
    link: undefined,
    defaultPaginate: 20,
    getErrorsTooltipData: null,
    showGroupCheckbox: false,
    rejectionRights: false,
    fixedActionsBtn: [],
    fixedHeader: false,
    onDragAndDrop: null
  };

  state = {
    paginate: this.props.defaultPaginate,
    isPaginationDropdownOpen: false,
    checkedItems: [],
    starredItems: [],
    filterSearch: {},
    sort: "",
    isSortDescending: false,
    openGroup: "",
    collapsed: [],
  };

  sortByCell = (attr, isSortDescending = false) => {
    const { onSort } = this.props;

    this.setState({
      sort: attr,
      isSortDescending: isSortDescending,
    }, () => {
      onSort && onSort.apply(this, [attr, isSortDescending ? "DESC" : "ASC"]);
    });
  };

  getScrollableHeaders() {
    const { headers, fixedLeft, fixedRight } = this.props;

    return headers
      .filter(header => !fixedLeft.includes(header.attr))
      .filter(header => !fixedRight.includes(header.attr));
  }

  toggleCollapsed = (item) => {
    const {collapsed} = this.state;
    let newCollapsed;
    if (collapsed.includes(item)) {
      newCollapsed = collapsed.slice().filter(el => el !== item)
    } else {
      newCollapsed = [...collapsed.slice(), item]
    }
    this.setState({collapsed: newCollapsed});
  }

  renderSortButton(attr) {
    const { sort, isSortDescending } = this.state;

    if (attr !== sort) {
      return (
        <img
          src={sortDefault}
          onClick={() => this.sortByCell.call(this, attr)}
          alt=""
        />
      );
    }

    if (isSortDescending) {
      return (
        <img
          src={sortUp}
          onClick={() => this.sortByCell.call(this, attr)}
          alt=""
        />
      );
    }

    return (
      <img
        src={sortDown}
        onClick={() => this.sortByCell.call(this, attr, true)}
        alt=""
      />
    );
  }

  renderFilterBar(columns = []) {

    const { options } = this.props;
    const { filterSearch } = this.state;

    return columns.map((header, i) => {
      return (
        <div
          key={i}
          className={classnames("table-cell", options[header]?.className)}
          style={options[header.attr]?.styles}
        >
          {(header !== 'Actions' && header.attr !== 'Actions') && (
            <TableSearch
              placeholder={"Type"}
              searchText={filterSearch[header.attr || header] || ""}
              onChange={value => this.onFilterChange(header.attr || header, value)}
              onClose={() => this.onFilterChange(header.attr || header)}
            />
          )}
        </div>
      );
    })
  }

  renderFilterBars() {
    const { isFilterBarOpen, fixedLeft, fixedRight, onCheckboxChange } = this.props;
    if (!isFilterBarOpen) {
      return null;
    }

    const showCheckbox = !!onCheckboxChange;

    let res = [
      <div className='table-fixed-filter left'>{this.renderFilterBar(fixedLeft)}</div>,
      this.renderFilterBar(this.getScrollableHeaders()),
      <div className='table-fixed-filter right'>{this.renderFilterBar(fixedRight)}</div>,
    ];

    return (
      <div key={"filter-bar"} className={`table-column table-filter-bar ${showCheckbox ? 'table-filter-bar-checkbox' : ''}`}>
        {res}
      </div>
    );
  }

  renderHeader(isFixed, fixedColumns = []) {
    const { rows, headers, options, fixedLeft, fixedRight, getErrorsTooltipData, onCheckboxChange, fixedActionsBtn } = this.props;
    const { checkedItems } = this.state;
    let res;

    const showCheckbox = !!onCheckboxChange;

    if (!isFixed) {
      res = this.getScrollableHeaders().map((header, i) => {
        return (
          <div
            key={i}
            className={classnames("table-cell", options[header.attr]?.className)}
            style={options[header.attr]?.styles}
          >
            {header.attr === 'Errors' && getErrorsTooltipData()}
            <span>{header.title}</span>
            {((!rows[0]?.Details || header.attr === 'SiteId') && !header.withoutSort) && this.renderSortButton(header.attr)}
          </div>
        );
      });
    } else {
      res = fixedColumns.map((attr, i) => {
        return (
          <div
            key={i}
            className={classnames("table-cell", options[attr]?.className)}
            style={options[attr]?.styles}
          >
            <span>{headers.find(header => header.attr === attr).title}</span>
            {attr !== 'Actions' && (!rows[0]?.Details || attr === 'SiteId') && this.renderSortButton(attr)}
          </div>
        );
      });
    }

    return (
      <div key="header" className="table-column table-header">
        {showCheckbox &&
          <div className='table-cell select-checkbox' style={{ minWidth: 40, flex: 'unset' }}>
            <Checkbox
              checked={rows.length > 0 && rows.length === checkedItems.length}
              onChange={() => {
                this.setState({
                  ...this.state,
                  checkedItems: checkedItems.length === rows.length ? [] : rows,
                }, () => {
                  onCheckboxChange && onCheckboxChange(this.state.checkedItems);
                });
              }}
            />
          </div>
        }
        {this.renderFixedHeaders(fixedLeft, true)}
        {res}
        {this.renderFixedHeaders([...fixedRight, ...fixedActionsBtn], false)}
      </div>
    );
  }

  renderCell(row, key, fixedColumns) {
    const { options, link, onCheckboxChange, onDragAndDrop } = this.props;
    const { checkedItems } = this.state;
    const isFixed = fixedColumns && fixedColumns.length > 0;

    const showCheckbox = !!onCheckboxChange;

    let iterable;

    if (isFixed) {
      iterable = fixedColumns;
    } else {
      iterable = this.getScrollableHeaders().map(header => header.attr);
    }

    const isChecked = !!checkedItems.find(item => String(item.id) === String(row.id));

    return (
      <Fragment key={key}>
        <div
          className={classnames("table-column table-row", {
            hasHover: link,
            'parent-column': row.children?.length,
            collapsed: this.state.collapsed.includes(row.task_id),
            // disabled: row.task_status?.props?.children === 'Pending'
          })}
          {...(row.task_id ? {id: `row-${row.task_id}`} : {})}
          onClick={() => {
            row.children?.length && this.toggleCollapsed(row.task_id)
            // if (link && typeof link !== "string") link.apply(this, [row])
          }}
        >
          {showCheckbox &&
          <div className="table-cell select-checkbox" style={{ zIndex: 10, minWidth: 40, flex: 'unset' }}>
            <Checkbox
              checked={isChecked}
              onChange={() => {
                this.setState({
                  ...this.state,
                  checkedItems: isChecked ?
                    checkedItems.filter(item => String(item.id) !== String(row.id)) :
                    [...checkedItems, row],
                }, () => {
                  onCheckboxChange && onCheckboxChange(this.state.checkedItems);
                });
              }}
            />
          </div>
          }
          {this.renderFixedContent(row, true)}
          {link && typeof link === "string" && (<Link to={`${link}/${row.id}`} className="table-column-link" />)}
          {iterable.map((attr, i) => {
            if (typeof row[attr] === "function") {
              return (
                <div
                  key={i}
                  className={classnames("table-cell", options[attr]?.className)}
                  style={options[attr]?.styles}
                >
                  {row[attr].apply(this, [row])}
                </div>
              );
            }

            return (
              <div
                key={i}
                className={classnames("table-cell", options[attr]?.className, row[attr]?.key || '', { requestDelete: row.RequestDelete })}
                style={options[attr]?.styles}
              >
                <span>{row[attr]}</span>
              </div>
            );
          })}
          {this.renderFixedContent(row, false)}
        </div>
        {!!row.children?.length && <Collapse in={!this.state.collapsed.includes(row.task_id)} timeout="auto" unmountOnExit>{
          !!onDragAndDrop
          ? <Droppable droppableId={String(row.task_id)}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {row.children.map((row, i) => (
                    <Draggable key={row.task_id} draggableId={String(row.task_id)} index={i}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {this.renderCell(row, i)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            : row.children.map((row, i) => this.renderCell(row, i))
        }
        </Collapse>}
      </Fragment>
    );
  }

  renderFixedContent(row, isLeft) {
    const { fixedLeft, fixedRight, options, fixedActionsBtn } = this.props;
    // If we don't have anything to render, don't render anything.
    if ((isLeft && fixedLeft.length === 0) || (!isLeft && fixedRight.length === 0 && fixedActionsBtn.length === 0)) {
      return null;
    }

    const fixedColumns = isLeft ? fixedLeft : [...fixedRight, ...fixedActionsBtn];

    return (
      <div className={classnames("table-fixed-content", {
        left: isLeft,
        right: !isLeft
      })}>
        {fixedColumns.map((attr, i) => {
          return (
            <div
              key={i}
              className={classnames("table-cell", options[attr]?.className)}
              style={options[attr]?.styles}
            >
              <span>{row[attr]}</span>
            </div>
          )
        })}
      </div>
    );
  }

  renderFixedHeaders(row, isLeft) {
    const { fixedLeft, fixedRight, headers, rows, options, getErrorsTooltipData, fixedActionsBtn } = this.props;
    // If we don't have anything to render, don't render anything.
    if ((isLeft && fixedLeft.length === 0) || (!isLeft && fixedRight.length === 0 && fixedActionsBtn.length === 0)) {
      return null;
    }

    return (
      <div className={classnames("table-fixed-content", {
        left: isLeft,
        right: !isLeft
      })}>
        {row.map((attr, i) => {
          const header = headers.find(header => header.attr === attr);
          return (
            <div
              key={i}
              className={classnames("table-cell", options[attr]?.className)}
              style={options[attr]?.styles}
            >
              {attr === 'Errors' && getErrorsTooltipData()}
              <span>{header.title}</span>
              {attr !== 'Actions' && (!rows[0]?.Details || attr === 'SiteId') && this.renderSortButton(attr)}
            </div>
          )
        })}
      </div>
    );
  }

  renderScrollingContent(rows) {
    const { paginate } = this.state;
    const { page, onDragAndDrop } = this.props;
    const res = [
      this.renderHeader(false, []),
      this.renderFilterBars(),
      ((!!rows.length && !rows[0]?.Details) || (rows[0]?.Details))
        ? !!onDragAndDrop
          ? <DragDropContext key="0" onDragEnd={onDragAndDrop}>
              <Droppable type="PARENT" direction="vertical" droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {rows.slice(paginate * page, paginate * (page + 1)).map((row, i) => (
                      <Draggable key={`${row.task_id}-${i}`} draggableId={String(row.task_id)} index={i}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {this.renderCell(row, i)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          : rows.slice(paginate * page, paginate * (page + 1)).map((row, i) => this.renderCell(row, i))
        : <p className='no-records' key={0}>No records to display</p>
    ];

    return (
      <div className="table-scroller">
        <div className="table-scroller-wrapper">
          {res}
        </div>
      </div>
    );
  }

  onPageChange(nextPage) {
    const { onPageChange } = this.props;
    const { paginate } = this.state;

    onPageChange && onPageChange.apply(this, [nextPage, paginate]);
  }

  onFilterChange(attr, value = null) {
    const { onFilterChange } = this.props;
    const { filterSearch } = this.state;
    const copy = { ...filterSearch };

    if (!value) {
      delete copy[attr];
    } else {
      copy[attr] = value;
    }

    this.setState({
      filterSearch: copy
    }, () => {
      onFilterChange && onFilterChange.apply(this, [this.state.filterSearch]);
    });
  }

  onPaginationLimitChange(value) {
    const { onPageChange, onPaginationChange } = this.props;

    this.setState({
      paginate: value,
      isPaginationDropdownOpen: false,
    }, () => {
      onPaginationChange && onPaginationChange(value);
      onPageChange && onPageChange.apply(this, [0, value]);
    });
  }

  renderFooter() {
    const { paginate, isPaginationDropdownOpen } = this.state;
    const { rows, loading, page } = this.props;

    const length = rows.length || 0;

    const pageAmount = Math.ceil(length / paginate);
    const start = (page * paginate) + 1;
    let end = (start - 1) + paginate;

    if (end > length) {
      end = length;
    }

    if(rows.length <= 10) return null;

    return [
      <LeftRightSwitch
        key={0}
        activeIndex={page}
        prefix={`${start}-${end} of ${length}`}
        options={[...new Array(pageAmount)].map((x, i) => i + 1)}
        onLeft={() => {
          !loading && this.onPageChange(page - 1);
        }}
        onRight={() => {
          !loading && this.onPageChange(page + 1);
        }}
        isReverseDirection={true}
      />,
      <Dropdown
        key={1}
        main={
          <Button title={`Show ${paginate} per page`} color={BUTTON_COLORS.GRAY}/>
        }
        dropdown={[10, 20, 50, 100].map((val, i) => {
          return (
            <Checkbox
              key={i}
              title={val}
              checked={val === paginate}
              onChange={() => this.onPaginationLimitChange(val)}
              name={val}
            />
          );
        })}
        active={isPaginationDropdownOpen}
        onClick={() => {
          this.setState({ isPaginationDropdownOpen: true });
        }}
        onClose={() => {
          this.setState({ isPaginationDropdownOpen: false });
        }}
        isRight={true}
      />
    ];
  }

  renderContent() {
    const { rows, loading } = this.props;
    return (
      <React.Fragment>
        <div className={classnames("table-group", { loading })}>
          <div className="loading-screen">
            Loading...
          </div>
          {this.renderScrollingContent(rows)}
        </div>
        <div className="table-footer">
          {this.renderFooter()}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { loading, fixedHeader } = this.props;
    return (
      <div className={classnames("table-component sync")}>
        <div className="table-inner">
          <div className={classNames("table-content", { fixedHeader })}>
            {!loading
              ? this.renderContent()
              : <Skeleton />
            }
          </div>
        </div>
      </div>
    );
  }
}