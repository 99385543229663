import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Table} from "components/Table";
import Skeleton from "components/common/Skeleton";
import useTable from "hooks/useTable";
import {useParams} from "react-router-dom";
import {GET_PROJECT_TICKETS_REQUEST} from "store/actions/project";
import {getHumanDate, getStatusClassname} from "helpers";
import Button, {BUTTON_COLORS} from "../../../../components/common/Button/Button";

const headCells = [
  {attr: "description", title: "Description"},
  {attr: "task_name", title: "Task Name"},
  {attr: "status", title: "Status"},
  {attr: "category", title: "Category"},
  {attr: "created_by_user", title: "created_by_user"},
  {attr: "assigned_to_user", title: "assigned_to_user"},
  {attr: "priority", title: "Priority"},
  {attr: "start_date", title: "start_date"},
  {attr: "due_date", title: "due_date"},
];


const Issues = ({setProjectModal}) => {

  const params = useParams();
  const {id} = params;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const projectTickets = useSelector(state => state.project.projectTickets);
  const loading = useSelector((state) => state.project.modalLoading);

  useEffect(() => {
    dispatch({type: GET_PROJECT_TICKETS_REQUEST, payload: {auth: userData, body: {activity_id: id}}});
  }, [dispatch, id, userData]);

  const {tableData, handleRequestSort} = useTable(projectTickets);

  const options = {
    task_name: {
      description: {minWidth: "200px"},
      styles: {minWidth: "200px"},
    },
  };

  const transformers = {
    status: (row) => (
      <span className={`status ${getStatusClassname(row.status)}`}>
        {row.status}
      </span>
    ),
    start_date: (row) => getHumanDate(row.start_date),
    due_date: (row) => getHumanDate(row.due_date),
  }


  return (
    <div className='sites list-table-page'>
      <div className='sites__header'>
        <h1 className='page-title'>Issues</h1>
      </div>
      {!loading
        ? <Table
            data={tableData}
            headers={headCells}
            onRequireList={handleRequestSort}
            options={options}
            transformers={transformers}
            fixedHeader
          />
        : <Skeleton/>
      }
      <Button title='Report Issue' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal('issueForProject')}/>
    </div>
  );
};

export default Issues;