import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import './MenuList.scss';
import {DocumentsIcon, ParticipantIcon, ProjectsIcon, SettingsIcon, SiteIcon, SpeedIcon, TotalIcon} from "../Icons";
import {Collapse, Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import chevronDown from 'assets/icons/chevron-down.svg';
import {GET_ACTIVE_PROJECT_LIST_REQUEST} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";

const MenuList = (props) => {
  const location = useLocation();
  const [openLink, setOpenLink] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const username = sessionStorage.getItem("username");
  const dispatch = useDispatch();
  const activeProjectList = useSelector((state) => state.project.activeProjectList);

  useEffect(() => {
    dispatch({type: GET_ACTIVE_PROJECT_LIST_REQUEST});
  }, [dispatch])

  const links = [
    {
      name: "Dashboard",
      hasSeparator: false,
      iconNode: TotalIcon,
      linkUrl: "/pdt/dashboard",
    },
    {
      name: "Activities",
      hasSeparator: false,
      iconNode: SpeedIcon,
      childUrls: ["/pdt/activities-list"],
      hasChildren: true,
      children: activeProjectList.map(item => ({
        name: item.project_name,
        iconNode: ProjectsIcon,
        hasSeparator: false,
        linkUrl: `/pdt/activities-list/${item.project_name}`,
      }))
    },
    {
      name: "Projects",
      hasSeparator: false,
      iconNode: ProjectsIcon,
      linkUrl: "/pdt/projects",
    },
    {
      name: "Sites",
      hasSeparator: false,
      iconNode: SiteIcon,
      linkUrl: "/pdt/sites",
    },
    {
      name: "Settings",
      hasSeparator: false,
      iconNode: SettingsIcon,
      childUrls: ["/pdt/project-templates", "/elements"],
      hasChildren: true,
      children: [
        {
          name: "Project templates",
          iconNode: DocumentsIcon,
          hasSeparator: false,
          linkUrl: "/pdt/project-templates",
        },
        {
          name: "User Management",
          iconNode: ParticipantIcon,
          hasSeparator: false,
          linkUrl: "/pdt/user-management",
        },
      ]
    },
    {
      name: "Tickets",
      hasSeparator: false,
      iconNode: DocumentsIcon,
      linkUrl: "/pdt/tickets",
    }
  ];


  const checkActiveLink = (path) => {
    return location.pathname.includes(path);
  };

  const checkActiveSubLink = (paths) => {
    return paths.includes(location.pathname);
  };

  const { title, header, headerLinkUrl, headerIcon, navHide } = props;
  return (
    <div className="menu-list">
      {header && (
        <NavLink
          to={{pathname: headerLinkUrl, search: window.location.search}}
          className="header"
          activeClassName="menu-link-active"
          end
        >
          {headerIcon && <img className="icon" src={headerIcon} alt='icon'/>}
          <span>{header}</span>
        </NavLink>
      )}

      <div className='title-text'>{title}</div>

      <div className="links">
        {links.map((link, i) => (
          link.hasChildren ?
            <div key={link.name}>
              <div
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setOpenLink(openLink === link.name ? null : link.name)
                }}
                className={`link has-icon menu-item-has-children ${
                  checkActiveSubLink(link.childUrls) ? "menu-link-active" : ""
                }`}>
                {link.icon && <img className="icon" src={link.icon} alt={'icon'} />}
                {link.iconNode && <link.iconNode/>}
                <span className="link-name">{link.name}</span>

                <img src={openLink === link.name || checkActiveSubLink(link.childUrls) ? chevronDown : chevronDown} alt='' />
              </div>

              <div className='subLinks'>
                {navHide
                  ? openLink === link.name && <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {
                      setAnchorEl(null);
                      setOpenLink(null);
                    }}
                    className='menu-list menu-list-popup'
                  >
                    {link.children.map((childLink) => {
                      if(childLink.hidden && !childLink.access.includes(username)) return null
                      return (
                        <MenuItem key={childLink.name} onClick={() => {
                          setAnchorEl(null);
                          setOpenLink(null);
                        }}>
                          <NavLink
                            key={childLink.name}
                            className={`link subLink ${
                              checkActiveLink(childLink.linkUrl) ? "menu-link-active" : ""
                            }`}
                            to={{ pathname: childLink.linkUrl, search: window.location.search }}
                            aria-current='page'
                          >
                            <span className="link-name">{childLink.name}</span>
                          </NavLink>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                  : <Collapse in={openLink === link.name} timeout="auto">
                    {link.children.map((childLink) =>
                      (<NavLink
                        key={childLink.name}
                        className={`link subLink ${
                          checkActiveLink(childLink.linkUrl) ? "menu-link-active" : ""
                        }`}
                        to={{ pathname: childLink.linkUrl, search: window.location.search }}
                        aria-current='page'
                      >
                        {childLink.icon && <img className="icon" src={childLink.icon} alt={'icon'} />}
                        {childLink.iconNode && <childLink.iconNode />}
                        <span className="link-name">{childLink.name}</span>
                      </NavLink>))}

                  </Collapse>
                }

              </div>
            </div>
            : (<NavLink
              to={{ pathname: link.linkUrl, search: window.location.search }}
              key={i}
              className={`link ${link.hasSeparator ? 'has-separator' : ''}`}
            >
              {link.icon && <img className="icon" src={link.icon} alt={'icon'} />}
              {link.iconNode && <link.iconNode/>}
              <span className="link-name">{link.name}</span>
            </NavLink>)
        ))}
      </div>
    </div>
  );
}

export default MenuList;