import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import {Provider} from "react-redux";
import {reducers} from "./store/reducers";
import {sagas} from "./store/sagas";
import App from "./containers/App";
import reportWebVitals from "./reportWebVitals";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import setupInterceptors from "./api/services/setupInterceptors";
import {ClearCacheProvider} from "react-clear-cache";

const saga = createSagaMiddleware();
const publicURL = process.env.REACT_APP_PUBLIC_URL;

export const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(saga)
));

saga.run(sagas);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router basename={publicURL}>
      <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{okButtonLabel: "Apply"}}>
        <ClearCacheProvider duration={5000} basePath='/pdt'>
          <App />
        </ClearCacheProvider>
      </LocalizationProvider>
    </Router>
  </Provider>
);

setupInterceptors();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
