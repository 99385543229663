import {call, put, all, takeLatest, select} from "redux-saga/effects";
import { Api } from "../../api";
import * as projectActions from "../actions/project";
import {SET_NOTIFICATION_MESSAGE} from "../actions/notification";

function* getProjectList(action) {
  try {
    const res = yield call(Api.project.getProjectList, action.payload);
    yield put({
      type: projectActions.PROJECT_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put({
      type: projectActions.PROJECT_LIST_FAIL,
      payload: { error: err.message },
    });
  }
}

function* getProjectDetails(action) {
  try {
    const res = yield call(Api.project.getProjectDetails, action.payload);
    yield put({
      type: projectActions.PROJECT_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put({
      type: projectActions.PROJECT_DETAILS_FAIL,
      payload: { error: err.message },
    });
  }
}

function* getActivityDetails(action) {
  try {
    const res = yield call(Api.project.getActivityDetails, action.payload);
    yield put({type: projectActions.ACTIVITY_DETAILS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.ACTIVITY_DETAILS_FAIL, payload: { error: err.message }});
  }
}

function* saveTask(action) {
  try {
    const res = yield call(Api.project.saveTask, action.payload);
    yield put({type: projectActions.SAVE_TASK_SUCCESS, payload: res.data});
    if (action.callback) action.callback();
    yield put({type: projectActions.ACTIVITY_DETAILS_REQUEST, payload: {auth: action.payload.auth, id: action.payload.id}})
  } catch (err) {
    yield put({type: projectActions.SAVE_TASK_FAIL, payload: { error: err.message }});
  }
}

function* saveTaskStakeholder(action) {
  try {
    const res = yield call(Api.project.saveTaskStakeholder, action.payload);
    yield put({type: projectActions.SAVE_TASK_STAKEHOLDER_SUCCESS, payload: res.data});
    if (action.callback) action.callback();
    yield put({type: projectActions.ACTIVITY_DETAILS_REQUEST, payload: {auth: action.payload.auth, id: action.payload.id}})
  } catch (err) {
    yield put({type: projectActions.SAVE_TASK_STAKEHOLDER_FAIL, payload: { error: err.message }});
  }
}

function* saveTaskMedia(action) {
  try {
    const res = yield call(Api.project.saveTaskMedia, action.payload);
    yield put({type: projectActions.SAVE_TASK_MEDIA_SUCCESS, payload: res.data});
    if (action.callback) action.callback();
    yield put({type: projectActions.ACTIVITY_DETAILS_REQUEST, payload: {auth: action.payload.auth, id: action.payload.id}})
  } catch (err) {
    yield put({type: projectActions.SAVE_TASK_MEDIA_FAIL, payload: { error: err.message }});
  }
}

function* getProjectRoles(action) {
  try {
    const res = yield call(Api.project.getProjectRoles, action.payload);
    yield put({type: projectActions.GET_PROJECT_ROLES_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_PROJECT_ROLES_FAIL, payload: { error: err.message }});
  }
}

function* getProjectUsers(action) {
  try {
    const res = yield call(Api.project.getProjectUsers, action.payload);
    yield put({type: projectActions.GET_PROJECT_USERS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_PROJECT_USERS_FAIL, payload: { error: err.message }});
  }
}

function* getProjectDefinitionList(action) {
  try {
    const res = yield call(Api.project.getProjectDefinitionList, action.payload);
    yield put({type: projectActions.GET_PROJECT_DEFINITION_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_PROJECT_DEFINITION_LIST_FAIL, payload: { error: err.message }});
  }
}

function* saveTaskApproval(action) {
  try {
    const res = yield call(Api.project.saveTaskApproval, action.payload);
    yield put({type: projectActions.SAVE_TASK_APPROVAL_SUCCESS, payload: res.data});
    if (action.callback) action.callback();
    yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "The task has been saved", snackbarSeverity: "success", openSnackbar: true}})
    yield put({type: projectActions.ACTIVITY_DETAILS_REQUEST, payload: {auth: action.payload.auth, id: action.payload.id}})
  } catch (err) {
    yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: err.response.data.message, snackbarSeverity: "error", openSnackbar: true}})
    yield put({type: projectActions.SAVE_TASK_APPROVAL_FAIL, payload: { error: err.message }});
  }
}

function* getAttachmentType(action) {
  try {
    const res = yield call(Api.project.getAttachmentType, action.payload);
    yield put({type: projectActions.GET_ATTACHMENT_TYPE_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_ATTACHMENT_TYPE_FAIL, payload: { error: err.message }});
  }
}

function* getProjectDocumentList(action) {
  try {
    const res = yield call(Api.project.getProjectDocumentList, action.payload);
    yield put({type: projectActions.GET_PROJECT_DOCUMENTS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_PROJECT_DOCUMENTS_FAIL, payload: { error: err.message }});
  }
}

function* getProjectTickets(action) {
  try {
    const res = yield call(Api.boards.getBoardTickets, action.payload);
    yield put({type: projectActions.GET_PROJECT_TICKETS_SUCCESS, payload: res.data?.tickets_data});
  } catch (err) {
    yield put({ type: projectActions.GET_PROJECT_TICKETS_FAIL, payload: { error: err.message } });
  }
}

function* getProjectOptions(action) {
  try {
    const res = yield call(Api.project.getProjectOptions, action.payload);
    yield put({type: projectActions.GET_PROJECT_OPTIONS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({ type: projectActions.GET_PROJECT_OPTIONS_FAIL, payload: { error: err.message } });
  }
}

function* getProjectTaskList(action) {
  try {
    const res = yield call(Api.project.getProjectTaskList, action.payload);
    yield put({type: projectActions.GET_PROJECT_TASKS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({ type: projectActions.GET_PROJECT_TASKS_FAIL, payload: { error: err.message } });
  }
}

function* deleteActivities(action) {
  try {
    yield call(Api.project.deleteActivities, action.payload);
    action?.callback();
    let projectList = yield select(state => state.project.projectList);
    const data = projectList.filter(item => !action.payload.includes(item.id));
    yield put({type: projectActions.DELETE_ACTIVITIES_SUCCESS, payload: data});
  } catch (err) {
    yield put({ type: projectActions.DELETE_ACTIVITIES_FAIL, payload: { error: err.message } });
  }
}

function* saveMilestoneTemplate(action) {
  try {
    const res = yield call(Api.project.saveMilestoneTemplate, action.payload);
    if(res.data.status === 'success'){
      action?.callback();
      yield put({type: projectActions.SAVE_MILESTONE_TEMPLATE_SUCCESS});
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: `The Project template has been ${action.payload.is_deleted ? 'deleted' : 'saved'}`, snackbarSeverity: "success", openSnackbar: true}})
      yield put({type: projectActions.GET_MILESTONE_TEMPLATE_LIST_REQUEST})
    }else{
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: res.data.error, snackbarSeverity: "error", openSnackbar: true}})
      yield put({ type: projectActions.SAVE_MILESTONE_TEMPLATE_FAIL })
    }
  } catch (err) {
    yield put({ type: projectActions.SAVE_MILESTONE_TEMPLATE_FAIL, payload: { error: err.message } });
  }
}

function* saveMilestoneTask(action) {
  try {
    const res = yield call(Api.project.saveMilestoneTask, action.payload);
    if(res.data.status === 'success'){
      action?.callback?.();
      yield put({type: projectActions.SAVE_MILESTONE_TASK_SUCCESS});
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: `The Task has been ${action.payload.is_deleted ? 'deleted' : 'saved'}`, snackbarSeverity: "success", openSnackbar: true}})
      yield put({type: projectActions.GET_MILESTONE_TEMPLATE_DETAILS_REQUEST, payload: {id: action.payload.milestone_template_id}})
    }else{
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: res.data.error, snackbarSeverity: "error", openSnackbar: true}})
      yield put({ type: projectActions.SAVE_MILESTONE_TASK_FAIL })
    }
  } catch (err) {
    yield put({ type: projectActions.SAVE_MILESTONE_TASK_FAIL, payload: { error: err.message } });
  }
}

function* getMilestoneTemplateList() {
  try {
    const res = yield call(Api.project.getMilestoneTemplateList);
    yield put({type: projectActions.GET_MILESTONE_TEMPLATE_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({ type: projectActions.GET_MILESTONE_TEMPLATE_LIST_FAIL, payload: { error: err.message } });
  }
}

function* getMilestoneTemplateDetails(action) {
  try {
    const res = yield call(Api.project.getMilestoneTemplateDetails, action.payload);
    yield put({type: projectActions.GET_MILESTONE_TEMPLATE_DETAILS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_MILESTONE_TEMPLATE_DETAILS_FAIL, payload: { error: err.message }});
  }
}

function* saveProject(action) {
  try {
    yield call(Api.project.saveProject, action.payload);
    action?.callback();
    yield put({type: projectActions.SAVE_PROJECT_SUCCESS});
    yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "The Project has been saved", snackbarSeverity: "success", openSnackbar: true}})
    yield put({type: projectActions.GET_PROJECT_OPTIONS_REQUEST})
  } catch (err) {
    yield put({ type: projectActions.SAVE_PROJECT_FAIL, payload: { error: err.message } });
  }
}

function* getActiveProjectList() {
  try {
    const res = yield call(Api.project.getActiveProjectList);
    yield put({type: projectActions.GET_ACTIVE_PROJECT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_ACTIVE_PROJECT_LIST_FAIL, payload: { error: err.message }});
  }
}

function* getAllProjectList() {
  try {
    const res = yield call(Api.project.getProjectList);
    yield put({type: projectActions.GET_ALL_PROJECT_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: projectActions.GET_ALL_PROJECT_LIST_FAIL, payload: { error: err.message }});
  }
}

function* rearrangeMilestoneTask(action) {
  try {
    const res = yield call(Api.project.rearrangeMilestoneTask, action.payload);

    if(res.data.status === 'success'){
      yield put({type: projectActions.REARRANGE_MILESTONE_TASK_SUCCESS, payload: res.data});
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "The Task has been rearranged", snackbarSeverity: "success", openSnackbar: true}})
      yield put({type: projectActions.GET_MILESTONE_TEMPLATE_DETAILS_REQUEST, payload: {id: action.milestone_template_id}})
    }else{
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: res.data.error, snackbarSeverity: "error", openSnackbar: true}})
      yield put({type: projectActions.REARRANGE_MILESTONE_TASK_FAIL})
    }
  } catch (err) {
    yield put({type: projectActions.REARRANGE_MILESTONE_TASK_FAIL, payload: { error: err.message }});
  }
}


export default all([
  takeLatest(projectActions.PROJECT_LIST_REQUEST, getProjectList),
  takeLatest(projectActions.PROJECT_DETAILS_REQUEST, getProjectDetails),
  takeLatest(projectActions.ACTIVITY_DETAILS_REQUEST, getActivityDetails),
  takeLatest(projectActions.SAVE_TASK_REQUEST, saveTask),
  takeLatest(projectActions.SAVE_TASK_STAKEHOLDER_REQUEST, saveTaskStakeholder),
  takeLatest(projectActions.SAVE_TASK_MEDIA_REQUEST, saveTaskMedia),
  takeLatest(projectActions.GET_PROJECT_ROLES_REQUEST, getProjectRoles),
  takeLatest(projectActions.GET_PROJECT_USERS_REQUEST, getProjectUsers),
  takeLatest(projectActions.GET_PROJECT_DEFINITION_LIST_REQUEST, getProjectDefinitionList),
  takeLatest(projectActions.SAVE_TASK_APPROVAL_REQUEST, saveTaskApproval),
  takeLatest(projectActions.GET_ATTACHMENT_TYPE_REQUEST, getAttachmentType),
  takeLatest(projectActions.GET_PROJECT_DOCUMENTS_REQUEST, getProjectDocumentList),
  takeLatest(projectActions.GET_PROJECT_TICKETS_REQUEST, getProjectTickets),
  takeLatest(projectActions.GET_PROJECT_OPTIONS_REQUEST, getProjectOptions),
  takeLatest(projectActions.GET_PROJECT_TASKS_REQUEST, getProjectTaskList),
  takeLatest(projectActions.DELETE_ACTIVITIES_REQUEST, deleteActivities),
  takeLatest(projectActions.SAVE_MILESTONE_TEMPLATE_REQUEST, saveMilestoneTemplate),
  takeLatest(projectActions.SAVE_MILESTONE_TASK_REQUEST, saveMilestoneTask),
  takeLatest(projectActions.GET_MILESTONE_TEMPLATE_LIST_REQUEST, getMilestoneTemplateList),
  takeLatest(projectActions.GET_MILESTONE_TEMPLATE_DETAILS_REQUEST, getMilestoneTemplateDetails),
  takeLatest(projectActions.SAVE_PROJECT_REQUEST, saveProject),
  takeLatest(projectActions.GET_ACTIVE_PROJECT_LIST_REQUEST, getActiveProjectList),
  takeLatest(projectActions.GET_ALL_PROJECT_LIST_REQUEST, getAllProjectList),
  takeLatest(projectActions.REARRANGE_MILESTONE_TASK_REQUEST, rearrangeMilestoneTask),
]);
