import React from 'react';
import GoogleMapReact from "google-map-react";
import Marker from "../common/Marker/Marker";
import {mapStyles} from "./styles";

const createMapOptions = (maps) => {
  return {
    styles: mapStyles,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP,
    },
    keyboardShortcuts: false
  };
}

const MapView = ({data}) => {
  return (
    <div className="map-view">
      <GoogleMapReact
        bootstrapURLKeys={{key: "AIzaSyCscN2ly2eaD8BhkMIOViLXiOuvmBY69Cw"}}
        defaultCenter={{lat: data.latitude || 24.7136, lng: data.longitude || 46.6753}}
        defaultZoom={8}
        options={createMapOptions}
      >
        {data.latitude && data.longitude && (
          <Marker lat={data.latitude} lng={data.longitude}/>
        )}
      </GoogleMapReact>
    </div>
  );
};

export default MapView;