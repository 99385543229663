import React from 'react';
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {BookIcon, DeviationIcon, DistanceIcon, WorldSideIcon} from "components/Icons";
import './SiteGeneralInfo.scss';
import MapView from "components/MapView/MapView";
import {getHumanDate} from "helpers";

const SiteGeneralInfo = ({data, setInitiateProjects, loading}) => {
  return (
    <div className='siteGeneralInfo'>
      <div className="siteGeneralInfo__header">
        <div>
          <span>{data.unique_work_identifier}</span>
          <h3>General information</h3>
        </div>
        {setInitiateProjects && (
          <Button
            title='Initiate project'
            onClick={() => setInitiateProjects([{id: data.id}])}
            disabled={loading}
            color={BUTTON_COLORS.FULLY_TRANSPARENT}
          />
        )}
      </div>
      <div className="siteGeneralInfo__main">
        <div className="siteGeneralInfo__map">
          <MapView data={data}/>
          <div className="map-info">
            <WorldSideIcon/>
            <h6>location name</h6>
            <p>
              <span>{data.latitude},</span>
              <span>{data.longitude}</span>
            </p>
          </div>

        </div>
        <div className="siteGeneralInfo__info">
          <div className="info">
            <div className="info__header">
              <DistanceIcon/>
              <h5>Technology</h5>
            </div>
            <ul className='info__items'>
              <li>STC Telecom Foot print <span>{data.stc_telecom_footprint}</span></li>
              <li>PO Owner <span>{data.po_owner}</span></li>
              <li>Site Ownership <span>{data.site_ownership}</span></li>
              <li>Site Type <span>{data.site_type}</span></li>
              <li>Tower Type <span>{data.tower_type}</span></li>
              <li>Tower Height <span>{data.tower_height}</span></li>
            </ul>
          </div>
          <div className="info info--full">
            <div className="info__header">
              <BookIcon/>
              <h5>Project information</h5>
            </div>
            <ul className='info__items'>
              <li>Unique Work Identifier <span>{data.unique_work_identifier}</span></li>
              <li>Project Name <span>{data.project_name}</span></li>
              <li>Subproject Name <span>{data.subproject_name}</span></li>
              <li>Customer Project Name <span>{data.customer_project_name}</span></li>
              <li>Customer Site ID <span>{data.customer_site_id}</span></li>
              <li>TAWAL ID <span>{data.tawal_id}</span></li>
              <li>STC Site ID <span>{data.stc_id}</span></li>
              <li>Site Priority <span>{data.site_priority}</span></li>
              <li>Col/BTS <span>{data.work_type}</span></li>
              <li>Technology <span>{data.technology}</span></li>
            </ul>
          </div>
          <div className="info">
            <div className="info__header">
              <DeviationIcon/>
              <h5>System Details</h5>
            </div>
            <ul className='info__items'>
              <li>Owner <span>{data.owner}</span></li>
              <li>OLO Number <span>{data.olo_number}</span></li>
              <li>OLO Date <span>{getHumanDate(data.olo_date)}</span></li>
              <li>SR Number (TMS) <span>{data.srs_number_tms}</span></li>
              <li>SO Number (TMS) <span>{data.so_number_tms}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteGeneralInfo;