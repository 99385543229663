import {call, put, all, takeEvery, takeLatest} from "redux-saga/effects";
import * as commonActions from "../actions/common";
import {Api} from "api";
import {saveAs} from "file-saver";
import * as projectActions from "../actions/project";


function* uploadMedia(action) {
  try {
    const res = yield call(Api.common.uploadMedia, action.payload);
    yield put({type: commonActions.UPLOAD_MEDIA_SUCCESS, payload: res.data});
    if(action.callback) action.callback(res.data);
  } catch (err) {
    yield put({type: commonActions.UPLOAD_MEDIA_FAIL, payload: {error: err.response?.data?.message || 'An error occurred'}});
  }
}

function* uploadMediaAll(action) {
  try {
    const files = [...action.payload.body];
    const res = yield all(files.map(file => {
      const formData = new FormData();
      formData.append("file", file);
      const payload = {body: formData, bucket: action.payload.bucket}
      return call(Api.common.uploadMedia, payload)
    }));
    const data = res.map((item, i) => ({
      Id: 0,
      MediaName: files[i].name,
      Size: files[i].size,
      MediaReferenceId: item.data.id,
      TableName: action.payload.addFields.TableName,
      ObjectId: action.payload.addFields.ObjectId,
      FieldName: action.payload.addFields.FieldName,
      Remarks: null,
    }));
    if(action.callback) action.callback(data);
    yield put({type: commonActions.UPLOAD_MEDIA_ALL_SUCCESS, payload: res});
  } catch (err) {
    yield put({type: commonActions.UPLOAD_MEDIA_ALL_FAIL, payload: {error: err.response?.data?.message || 'An error occurred'}});
  }
}

function* downloadMedia(action) {
  try {
    const res = yield call(Api.common.downloadMedia, action.payload);
    const blob = new Blob([res.data], { type: res.data.type });
    saveAs(blob, action.payload.file.media_name);
    yield put({ type: commonActions.DOWNLOAD_MEDIA_SUCCESS, payload: action.payload });
  } catch (err) {
    yield put({type: commonActions.DOWNLOAD_MEDIA_FAIL, payload: {error: err.response?.data?.message || 'An error occurred'}});
  }
}

function* downloadMediaAll(action) {
  for (let i = 0; i < action.payload.length; i++) {
    yield put({ type: commonActions.DOWNLOAD_MEDIA_REQUEST, payload: action.payload[i] });
  }
}

function* getLookupList(action) {
  try {
    const res = yield call(Api.common.getLookupList, action.payload);
    yield put({type: commonActions.GET_LOOKUP_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: commonActions.GET_LOOKUP_LIST_FAIL, payload: { error: err.message }});
  }
}


function* siteProgressBulkUpload(action) {
  try {
    const res = yield call(Api.common.bulkUpload, action.payload);
    yield put({type: commonActions.PARSE_BULK_UPLOAD_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: commonActions.PARSE_BULK_UPLOAD_FAIL, payload: { error: err.message }});
  }
}

function* deleteMedia(action) {
  try {
    yield call(Api.common.deleteMedia, action.payload);
    action.callback?.();
    yield put({ type: commonActions.DELETE_MEDIA_SUCCESS, payload: action.payload });
    yield put({ type: projectActions.ACTIVITY_DETAILS_REQUEST, payload: {id: action.payload.id}});
  } catch (err) {
    yield put({type: commonActions.DELETE_MEDIA_FAIL, payload: {error: err.response?.data?.message || 'An error occurred'}});
  }
}


export default all([
  takeLatest(commonActions.UPLOAD_MEDIA_REQUEST, uploadMedia),
  takeLatest(commonActions.UPLOAD_MEDIA_ALL_REQUEST, uploadMediaAll),
  takeEvery(commonActions.DOWNLOAD_MEDIA_REQUEST, downloadMedia),
  takeLatest(commonActions.DOWNLOAD_MEDIA_ALL_REQUEST, downloadMediaAll),
  takeLatest(commonActions.GET_LOOKUP_LIST_REQUEST, getLookupList),
  takeLatest(commonActions.PARSE_BULK_UPLOAD_REQUEST, siteProgressBulkUpload),
  takeLatest(commonActions.DELETE_MEDIA_REQUEST, deleteMedia),
]);
