import React, {useEffect, useState} from 'react';
import {PROJECT_DETAILS_REQUEST} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {Tab, Tabs} from "@mui/material";
import './Project.scss';
import {Doughnut} from "react-chartjs-2";
import {doughnutTextPlugin, getHumanDate} from "../../helpers";
import CountUp from "react-countup";
import {Table} from "components/Table";
import useTable from "hooks/useTable";
import {ArrowLeftIcon} from "../../components/Icons";
import Loader from "components/common/Loader";

const headCellsActivity = [
  {attr: "unique_work_identifier", title: "Task Name"},
  {attr: "progress", title: "Progress"},
  {attr: "site", title: "Site"},
  {attr: "start_date", title: "start date"},
  {attr: "active_milestone", title: "Active milestone"},
  {attr: "latest_milestone_completed_date", title: "Latest Milestone Completed Date"},
];
const headCellsSite = [
  {attr: "id", title: "№"},
  {attr: "site_type", title: "Site Type"},
  {attr: "site_code", title: "Site Code"},
  {attr: "tower_category", title: "Tower category"},
  {attr: "vendor_fp", title: "Vendor FP"},
];

const DoughnutStat = ({total}) => {
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: `${total}%`,
        data: [total, 100 - total],
        backgroundColor: [
          '#0579DF',
          '#CFD2CD',
        ],
        borderWidth: 0,
        weight: 1,
        cutout: "75%",
      },
    ],
  };

  return (
    <Doughnut data={data} type='doughnut' plugins={[doughnutTextPlugin]}/>
  )
}


const Project = () => {

  const params = useParams();
  const {id} = params;
  const dispatch = useDispatch();
  const projectDetails = useSelector((state) => state.project.projectDetails);
  const [activeTab, setActiveTab] = useState("activities");
  const {tableData: tableDataActivity, handleRequestSort: handleRequestSortActivity} = useTable(projectDetails.activity);
  const {tableData: tableDataSite, handleRequestSort: handleRequestSortSite} = useTable(projectDetails.site);
  const loading = useSelector((state) => state.project.loading);

  useEffect(() => {
    dispatch({
      type: PROJECT_DETAILS_REQUEST,
      payload: {id},
    });
  }, [id, dispatch]);

  const transformers = {
    start_date: (row) => getHumanDate(row.start_date),
  }

  return (
    <div className='Project'>
      {!loading
        ? <>
          <div className="projectInfo">
            <div className="page-title-wrap">
              <div className="page-title-left">
                <Link to='/pdt/projects' className='back-btn'>
                  <ArrowLeftIcon/>
                </Link>
                <h1 className='page-title'>{projectDetails.project_name}</h1>
              </div>
            </div>

            <Tabs
              variant="scrollable"
              scrollButtons
              aria-label="scrollable force tabs"
              className='box'
              value={false}
            >
              <Tab disableRipple label={<><p>Unique Work Identifier</p><h5>3213</h5></>}/>
              <Tab disableRipple label={<><p>Activity Name</p><h5>{projectDetails.project_name}</h5></>}/>
              <Tab disableRipple label={<><p>Subproject Name</p><h5>{projectDetails.sub_project_name}</h5></>}/>
              <Tab disableRipple
                   label={<><p>Customer Activity Name</p><h5>{projectDetails.customer_project_name}</h5></>}/>
            </Tabs>
          </div>
          <div className="projectStats">
            <div className="box">
              <div className="box__main">
                <span>Sites</span>
                <CountUp end={projectDetails.site_count} separator="," delay={0} duration={1.5}>
                  {({ countUpRef }) => <h2 ref={countUpRef} >{projectDetails.site_count}</h2>}
                </CountUp>
              </div>
            </div>
            <div className="box">
              <div className="box__main">
                <span>Activities</span>
                <CountUp end={projectDetails.activity_count} separator="," delay={0} duration={1.5}>
                  {({ countUpRef }) => <h2 ref={countUpRef} >{projectDetails.activity_count}</h2>}
                </CountUp>
              </div>
            </div>
            <div className="box">
              <div className="box__main">
                <span>Ongoing activities</span>
                <DoughnutStat total={projectDetails.ongoing_percentage || 0}/>
              </div>
            </div>
            <div className="box">
              <div className="box__main">
                <span>Completed activities</span>
                <DoughnutStat total={projectDetails.completed_activity_percentage || 0}/>
              </div>
            </div>
            <div className="box">
              <div className="box__main">
                <span>Activities at risk</span>
                <DoughnutStat total={projectDetails.activity_at_risk_percentage || 0}/>
              </div>
            </div>
          </div>
          <div className="tabs">
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              orientation="horizontal"
              sx={{borderBottom: 1, borderColor: 'divider'}}
            >
              <Tab label="Activities" value="activities"/>
              <Tab label="Sites" value="sites"/>
            </Tabs>
            {activeTab === 'activities' && (
              <Table
                data={tableDataActivity}
                headers={headCellsActivity}
                onRequireList={handleRequestSortActivity}
                link={true}
                fixedHeader
                transformers={transformers}
              />
            )}
            {activeTab === 'sites' && (
              <Table
                data={tableDataSite}
                headers={headCellsSite}
                onRequireList={handleRequestSortSite}
                link={true}
                fixedHeader
              />
            )}
          </div>
        </>
        : <Loader height={700} width="100%">
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="50px"/>
          <rect x="0" y="60px" rx="4" ry="4" width="100%" height="70px"/>
          {[...new Array(5)].map((val, i) => {
            return (<rect key={i} x={i * 20 + '%'} y="140px" rx="4" ry="4" width="19%" height="170px"/>);
          })}
          <rect x="0" y="320px" rx="4" ry="4" width="30%" height="50px"/>
          <rect x="50%" y="320px" rx="4" ry="4" width="30%" height="50px"/>
          <rect x="82%" y="320px" rx="4" ry="4" width="18%" height="50px"/>
          {[...new Array(20)].map((val, i) => {
            return (<rect key={i} x="0" y={(i + 1) * 55 + 320} rx="4" ry="4" width="100%" height="50px"/>);
          })}
        </Loader>
      }

    </div>
  );
};

export default Project;