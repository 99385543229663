import React, {useEffect, useMemo, useState} from "react";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {
  GET_PROJECT_OPTIONS_REQUEST,
  GET_PROJECT_ROLES_REQUEST,
  GET_PROJECT_USERS_REQUEST
} from "store/actions/project";
import {CREATE_ACTIVITY_BULK_REQUEST} from "store/actions/site";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Modal from "components/Modal/Modal";
import UserChip from "components/common/UserChip/UserChip";
import classNames from "classnames";
import {GET_USERS_LIST_REQUEST} from "../../store/actions/userManagement";

const InitiateProjectModal = ({onBackgroundClick, initiateProjects, successCallback}) => {

  const params = useParams();
  const {id} = params;

  const [state, setState] = useState({
    start_date: null,
    project: '',
    project_manager: '',
    owner: '',
    stakeholder: []
  })

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const projectUsers = useSelector((state) => state.project.projectUsers);
  const projectList = useSelector((state) => state.project.projectOptions);
  const projectRoles = useSelector((state) => state.project.projectRoles);
  const usersList = useSelector((state) => state.userManagement.usersList);
  const modalLoading = useSelector((state) => state.site.modalLoading);
  const [errors, setErrors] = useState({start_date: '', project: ''});

  useEffect(() => {
    if(!projectUsers){
      dispatch({type: GET_PROJECT_USERS_REQUEST, payload: {auth: userData}})
    }
    if(!projectRoles){
      dispatch({type: GET_PROJECT_ROLES_REQUEST, payload: {auth: userData}})
    }

    dispatch({type: GET_USERS_LIST_REQUEST, payload: {role: null}})

    dispatch({type: GET_PROJECT_OPTIONS_REQUEST, payload: {auth: userData}})
  }, []); //eslint-disable-line

  const roles = useMemo(() => (
    usersList.reduce((acc, item) => {
      if(item.role_name === 'NW PM') acc.pm.push(item);
      if(item.role_name === 'NW Submitter') acc.submitter.push(item);
      return acc;
    }, {pm: [], submitter: []})
  ), [usersList])

  const handleSubmit = () => {
    const err = {...errors};
    Object.entries(state).forEach(([key, value]) => {
      if(!value && errors.hasOwnProperty(key)) err[key] = 'This field is required';
    });
    setErrors(err);

    if(Object.values(err).some(item => item !== '')) return;
    const {project, ...rest} = state;
    const data = initiateProjects.map(item => ({
      ...rest,
      object_id: item.id,
      stakeholder: state.stakeholder.toString(),
      project_id: state.project.project_id
    }));

    dispatch({
      type: CREATE_ACTIVITY_BULK_REQUEST,
      payload: {
        auth: userData,
        id,
        body: data
      },
      callback: successCallback ? () => successCallback(state.project.project_name) : onBackgroundClick
    })
  }

  const onHandleChange = (name, value) => {
    setState({...state, [name]: value});
    errors.hasOwnProperty(name) && setErrors({...errors, [name]: ''});
  }

  const handleStakeholders = (role) => {
    setState({
      ...state,
      stakeholder: state.stakeholder.includes(role) ? state.stakeholder.filter(item => item !== role) : [...state.stakeholder, role]
    });
  };

  return (
    <Modal
      className='small-width'
      onBackgroundClick={onBackgroundClick}
    >
    <div className='initiation-modal'>
      <h3>Initiate project</h3>

      <div className="modal-field modal-select">
        <h6>Start Date</h6>
        <DatePicker
          views={['year', 'month', 'day']}
          value={state.start_date}
          onChange={value => onHandleChange('start_date', value)}
          format="dd/MM/yyyy"
          className={classNames({error: errors.start_date})}
        />
        {errors.start_date && <p className='validation-message'>{errors.start_date}</p>}
      </div>
      <div className="modal-field modal-select">
        <h6>Project</h6>
        <Select
          value={state.project || 'none'}
          variant="outlined"
          onChange={e => onHandleChange('project', e.target.value)}
          className={classNames({error: errors.project})}
        >
          <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
          {projectList?.map(item =>
            <MenuItem key={item.project_id} value={item}>{item.project_name}</MenuItem>
          )}
        </Select>
        {errors.project && <p className='validation-message'>{errors.project}</p>}
      </div>
      <div className="modal-field modal-select">
        <h6>Manager</h6>
        <Select
          value={state.project_manager || 'none'}
          variant="outlined"
          onChange={e => setState({...state, project_manager: e.target.value})}
          displayEmpty={true}
          // renderValue={value => value ?  value.full_name : 'Select owner'}
          // MenuProps={MENU_PROPS}
          // className={classNames({empty: !state.vendor})}
        >
          <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
          {roles.pm.map(item =>
            <MenuItem key={item.user_id} value={item.user_id}>{item.full_name}</MenuItem>
          )}
        </Select>
      </div>
      <div className="modal-field modal-select">
        <h6>Submitter</h6>
        <Select
          value={state.owner || 'none'}
          variant="outlined"
          onChange={e => setState({...state, owner: e.target.value})}
          displayEmpty={true}
        >
          <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
          {roles.submitter.map(item =>
            <MenuItem key={item.user_id} value={item.user_id}>{item.full_name}</MenuItem>
          )}
        </Select>
      </div>
      <div className="modal-field stakeholders">
        <h6>Stakeholders ({state.stakeholder.length})</h6>
        <div className='stakeholders__items'>
          {projectRoles?.stakeholder.map((item, i) =>
            <UserChip
              key={i}
              checked={state.stakeholder.includes(item.role_name)}
              title={item.role_name}
              onClick={() => handleStakeholders(item.role_name)}
            />
          )}
        </div>
      </div>

      <div className='modal-btns'>
        <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={onBackgroundClick}/>
        <Button title='Submit' color={BUTTON_COLORS.DEFAULT} disabled={modalLoading} onClick={handleSubmit}/>
      </div>
    </div>
    </Modal>
  );
};

export default InitiateProjectModal;
