import React, {useEffect, useState} from 'react';
import {Table} from "components/Table";
import Skeleton from "components/common/Skeleton";
import {useDispatch, useSelector} from "react-redux";
import useTable from "hooks/useTable";
import {
  GET_MILESTONE_TEMPLATE_LIST_REQUEST,
  SAVE_MILESTONE_TEMPLATE_REQUEST
} from "store/actions/project";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import AddProjectTemplateModal from "./AddProjectTemplateModal";
import {DeleteIcon, EditIcon} from "components/Icons";
import ConfirmModal from "../../../components/Modal/ConfirmModal";

const ProjectTemplates = () => {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.project.secondaryLoading);
  const mileStoneTemplateList = useSelector((state) => state.project.mileStoneTemplateList);
  const userData = useSelector(state => state.auth.user);
  const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);
  const isPMOTeam = userData.role === 'PMO';
  const [currentProject, setCurrentProject] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {tableData, handleRequestSort} = useTable(mileStoneTemplateList);

  useEffect(() => {
    dispatch({type: GET_MILESTONE_TEMPLATE_LIST_REQUEST})
  }, [dispatch]);

  const headCells = [
    {attr: "name", title: "template name"},
    {attr: "id", title: "template id"},
    ...isPMOTeam ? [{attr: "Actions", title: ""}] : [],
  ];


  const options = {

    Actions: {
      styles: { minWidth: "40px", maxWidth: "40px" },
      className: 'actions'
    }
  };

  const transformers = {
    Actions: (row) => {
      if(isPMOTeam){
        return (
          <>
            <div className='actions__wrap' onClick={(e) => {
              e.stopPropagation();
              setIsAddTemplateModalOpen(true);
              setCurrentProject(row);
            }}>
              <EditIcon/>
            </div>
            {row.is_deletable && (
              <div className='actions__wrap' onClick={(e) => {
                e.stopPropagation();
                setIsConfirmModalOpen(true);
                setCurrentProject(row);
              }}>
                <DeleteIcon/>
              </div>
            )}
          </>
        )
      }
    }
  }

  const deleteTemplate = () => {
    dispatch({
      type: SAVE_MILESTONE_TEMPLATE_REQUEST,
      payload: {template_id: currentProject.id, is_deleted: true},
      callback: () => setCurrentProject(null)
    })
  }

  return (
    <div className='ProjectTemplates'>
      <h1 className='page-title'>Project Templates</h1>
      {!loading
        ? <Table
          data={tableData}
          options={options}
          headers={headCells}
          onRequireList={handleRequestSort}
          userData={{}}
          link='/pdt/project-templates'
          transformers={transformers}
          hideSettings
          extraFilters={
            isPMOTeam && (
              <Button
                type="button"
                color={BUTTON_COLORS.DEFAULT}
                title='Add project template'
                style={{marginRight: 10 }}
                onClick={() => setIsAddTemplateModalOpen(true)}
              />
            )
          }
        />
        : <Skeleton/>
      }
      {isAddTemplateModalOpen && (
        <AddProjectTemplateModal
          onBackgroundClick={() => {
            setIsAddTemplateModalOpen(false);
            setCurrentProject(null);
          }}
          currentProject={currentProject}
        />
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          onBackgroundClick={() => {
            setCurrentProject(null);
            setIsConfirmModalOpen(false);
          }}
          open={isConfirmModalOpen}
          onSubmitClick={deleteTemplate}
          title='Are you sure'
          subTitle='This action is permanent and can’t be undone'
          withOutRemarks
        />
      )}


    </div>
  );
};

export default ProjectTemplates;