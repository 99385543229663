import {call, put, all, takeLatest, select} from "redux-saga/effects";
import { Api } from "api";
import * as siteActions from "../actions/site";
import * as projectActions from "../actions/project";

function* getSiteList(action) {
  try {
    const res = yield call(Api.site.getSiteList, action.payload);
    yield put({
      type: siteActions.SITE_LIST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put({
      type: siteActions.SITE_LIST_FAIL,
      payload: { error: err.message },
    });
  }
}

function* getSiteDetails(action) {
  try {
    const res = yield call(Api.site.getSiteDetails, action.payload);
    yield put({
      type: siteActions.SITE_DETAILS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    yield put({
      type: siteActions.SITE_DETAILS_FAIL,
      payload: { error: err.message },
    });
  }
}

function* createActivityBulk(action) {
  try {
    const res = yield call(Api.site.createActivityBulk, action.payload);
    yield put({type: siteActions.CREATE_ACTIVITY_BULK_SUCCESS, payload: res.data});
    if (action.callback) action.callback();
    if(action.payload.id){
      yield put({type: siteActions.SITE_DETAILS_REQUEST, payload: {auth: action.payload.auth, id: action.payload.id}})
    }
    yield put({type: projectActions.GET_ACTIVE_PROJECT_LIST_REQUEST})
  } catch (err) {
    yield put({type: siteActions.CREATE_ACTIVITY_BULK_FAIL, payload: { error: err.message }});
  }
}

function* deleteSite(action) {
  try {
    yield call(Api.site.deleteSite, action.payload);
    action?.callback();
    let siteList = yield select(state => state.site.siteList);
    const data = siteList.filter(item => !action.payload.includes(item.id));
    yield put({type: siteActions.DELETE_SITES_SUCCESS, payload: data});
  } catch (err) {
    yield put({ type: siteActions.DELETE_SITES_FAIL, payload: { error: err.message } });
  }
}



export default all([
  takeLatest(siteActions.SITE_LIST_REQUEST, getSiteList),
  takeLatest(siteActions.SITE_DETAILS_REQUEST, getSiteDetails),
  takeLatest(siteActions.CREATE_ACTIVITY_BULK_REQUEST, createActivityBulk),
  takeLatest(siteActions.DELETE_SITES_REQUEST, deleteSite),
]);
