import React from "react";
import './Switcher.scss';
import {FormControlLabel, Switch} from "@mui/material";

const Switcher = ({label, checked, onChange, disabled}) => (
  <FormControlLabel
    disabled={disabled}
    control={
      <Switch
        checked={checked}
        onChange={() => onChange(!checked)}
        color="primary"
      />
    }
    labelPlacement="start"
    label={label}
  />
);

export default Switcher;