import React from 'react';
import {Routes, Route, Navigate} from "react-router-dom";
import { useSelector } from "react-redux";
import './Auth.scss'

import Login from "./Login/Login"
import {LogoIcon} from "../../components/Icons";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import UpdatePassword from "./UpdatePassword/UpdatePassword";

const Auth = () => {

  const user = useSelector((state) => state.auth.user);

  const authenticate = (page) => {
    if (user) {
      return <Navigate to="/pdt" replace />
    } else return page
  }

  return (
    <div className="auth-page">
      <div className='right'>
        <div className="logo"><LogoIcon/></div>
        <Routes>
          <Route path="/" element={authenticate(<Login/>)} exact />
          <Route path="/forgot-password" element={authenticate(<ForgotPassword/>)} exact />
          <Route path="/reset-password" element={authenticate(<UpdatePassword/>)} exact />
        </Routes>
      </div>
    </div>
  )
}

export default Auth