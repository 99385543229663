import React, {useEffect, useState} from "react";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/Modal/Modal";
import {ValidatorForm, TextValidator, SelectValidator} from "react-material-ui-form-validator";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {InputAdornment} from "@mui/material";
import {EyeClosedIcon, EyeIcon} from "components/Icons";
import {SAVE_USER_REQUEST} from "store/actions/userManagement";

const AddUserModal = ({onBackgroundClick, currentUser}) => {

  const [state, setState] = useState({
    user_id: currentUser?.user_id || null,
    first_name: currentUser?.first_name || '',
    middle_name: currentUser?.middle_name || '',
    last_name: currentUser?.last_name || '',
    email: currentUser?.email || '',
    phone: currentUser?.phone || '',
    must_change_password: currentUser?.must_change_password ?? true,
    requires_2fa: currentUser?.requires_2fa ?? true,
    is_active: currentUser?.is_active ?? true,
    password: currentUser ? null : '',
    role: currentUser?.role_name || '',
  })

  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.userManagement.modalLoading);
  const lookup = useSelector((state) => state.common.lookup);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const handleSubmit = () => {
    dispatch({
      type: SAVE_USER_REQUEST,
      payload: state,
      callback: onBackgroundClick
    })
  }

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => value === state.password);
  }, [state.password])

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordValid', (value) => {
      return value.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/);
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordValid');
    };
  }, []);


  return (
    <Modal
      className='small-width'
      onBackgroundClick={onBackgroundClick}
    >
      <div className='initiation-modal'>
        <h3>{currentUser ? 'Edit' : 'Add'} user</h3>
        <ValidatorForm onSubmit={handleSubmit} className='modal-form' autoComplete='off'>
          <div className="modal-row modal-row-flex">
            <div className="modal-field">
              <h6>First name</h6>
              <TextValidator
                value={state.first_name}
                name='first_name'
                type="text"
                placeholder='First name'
                variant="outlined"
                onChange={onHandleChange}
                validators={['required']}
                errorMessages={['This field is required']}
              />
            </div>
            <div className="modal-field">
              <h6>Middle name</h6>
              <TextValidator
                value={state.middle_name}
                name='middle_name'
                type="text"
                placeholder='Middle name'
                variant="outlined"
                onChange={onHandleChange}
              />
            </div>
          </div>
          <div className="modal-row modal-row-flex">
            <div className="modal-field">
              <h6>Last name</h6>
              <TextValidator
                value={state.last_name}
                name='last_name'
                type="text"
                placeholder='Last name'
                variant="outlined"
                onChange={onHandleChange}
                validators={['required']}
                errorMessages={['This field is required']}
              />
            </div>
            <div className="modal-field">
              <h6>Email</h6>
              <TextValidator
                value={state.email}
                name='email'
                type="email"
                placeholder='Email'
                variant="outlined"
                onChange={onHandleChange}
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Email is not valid']}
                autoComplete="new-email"
              />
            </div>
          </div>
          <div className="modal-row modal-row-flex">
            <div className="modal-field">
              <h6>Phone</h6>
              <TextValidator
                value={state.phone}
                name='phone'
                type="text"
                placeholder='Phone'
                variant="outlined"
                onChange={onHandleChange}
                validators={['matchRegexp:^[0-9]+$']}
                errorMessages={['Invalid phone']}
              />
            </div>
            <div className="modal-field modal-select">
              <h6>Role</h6>
              <SelectValidator
                value={state.role || ''}
                variant="outlined"
                name='role'
                onChange={onHandleChange}
                validators={['required']}
                errorMessages={['This field is required']}
              >
                {lookup?.Role.map(item =>
                  <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                )}
              </SelectValidator>
            </div>
          </div>
          <div className="modal-row">
            <div className="modal-field">
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state.must_change_password}
                      onChange={(e) => setState({...state, must_change_password: e.target.checked})}
                    />
                  )}
                  label='Must change password'
                />
              </FormGroup>
            </div>
            <div className="modal-field">
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state.requires_2fa}
                      onChange={(e) => setState({...state, requires_2fa: e.target.checked})}
                    />
                  )}
                  label='Requires 2fa'
                />
              </FormGroup>
            </div>
            <div className="modal-field">
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state.is_active}
                      onChange={(e) => setState({...state, is_active: e.target.checked})}
                    />
                  )}
                  label='Is Active'
                />
              </FormGroup>
            </div>
          </div>
          {!currentUser && (
            <div className="modal-row modal-row-flex">
              <div className="modal-field">
                <h6>Password</h6>
                <TextValidator
                  value={state.password}
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  variant="outlined"
                  onChange={onHandleChange}
                  validators={['required', 'minStringLength:8', 'isPasswordValid']}
                  errorMessages={['This field is required', 'Password must be at least 8 characters long', 'Password must contain at least one uppercase letter, one number, and one special character']}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <EyeClosedIcon/> : <EyeIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="modal-field">
                <h6>Re-enter password</h6>
                <TextValidator
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  placeholder='Re-enter password'
                  variant="outlined"
                  onChange={e => setConfirmPassword(e.target.value)}
                  validators={['isPasswordMatch', 'required']}
                  errorMessages={['password mismatch', 'this field is required']}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <EyeClosedIcon/> : <EyeIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          )}


          <div className='modal-btns'>
            <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={onBackgroundClick}/>
            <Button title='Submit' color={BUTTON_COLORS.DEFAULT} disabled={modalLoading}/>
          </div>
        </ValidatorForm>
      </div>
    </Modal>
  );
};

export default AddUserModal;
