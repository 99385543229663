class TokenService {

  getToken() {
    return localStorage.getItem("token");
  }

  setToken(token) {
    localStorage.setItem("token", token);
  }

  removeToken() {
    localStorage.removeItem("token");
  }
}

const tokenServiceInstance = new TokenService();
export default tokenServiceInstance;