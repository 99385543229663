import React, { useState } from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {AUTH_LOGIN_REQUEST} from "store/actions/auth";
import {InputAdornment, TextField} from "@mui/material";
import {EyeClosedIcon, EyeIcon} from "components/Icons";

const Login = () => {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [state, setState] = useState({
    username: '',
    password: ''
  });

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: AUTH_LOGIN_REQUEST,
      payload: state,
      navigate
    })
  }

  return (
    <div className="auth-card">
      <div className="auth-card-head">
        <h2>Login</h2>
      </div>
      <form className="auth-card-form">
        <div className="auth-field">
          <h6>Username</h6>
          <TextField
            fullWidth
            name='username'
            type="text"
            value={state.username}
            placeholder='Username'
            onChange={onHandleChange}
          />
        </div>
        <div className="auth-field">
          <h6>Password</h6>
          <TextField
            fullWidth
            name='password'
            type={showPassword ? 'text' : 'password'}
            value={state.password}
            placeholder='Password'
            onChange={onHandleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <EyeClosedIcon/> : <EyeIcon />}
                </InputAdornment>
              ),
            }}
          />
        </div>

        <NavLink to='/pdt/auth/forgot-password' className='fPass'>Forgot password?</NavLink>

        {error && <span className='auth-failed'>{error}</span>}

        <button className={classnames({loading})} type="submit" onClick={handleSubmit}>Login</button>
      </form>
    </div>
  )
}

export default Login;