import classnames from 'classnames';
import React from "react";
import PropTypes from 'prop-types'
import "./index.scss";
import leftIcon from "../../../assets/icons/chevron-left-sm.svg";
import rightIcon from "../../../assets/icons/chevron-right-sm.svg";

export class LeftRightSwitch extends React.Component {

  static propTypes = {
    icon: PropTypes.string,
    options: PropTypes.array,
    prefix: PropTypes.string,
    activeIndex: PropTypes.number,
    onLeft: PropTypes.func,
    onRight: PropTypes.func,
    isReverseDirection: PropTypes.bool,
  };

  static defaultProps = {
    icon: "",
    options: false,
    prefix: PropTypes.string,
    activeIndex: 0,
    onLeft: null,
    onRight: null,
    isReverseDirection: false,
  };

  render () {
    const { icon, options, prefix, activeIndex, onLeft, onRight, isReverseDirection } = this.props;

    const rule1 = activeIndex === (options.length - 1);
    const rule2 = activeIndex === 0;

    return (
      <div className="left-right-switch">
        {icon && <img className="icon" src={icon} alt=''/>}
        <span>{prefix ? `${prefix}` : options[activeIndex]}</span>
        <div className="right-icons">
          <img
            onClick={onLeft}
            className={classnames("left", { disabled: isReverseDirection ? rule2 : rule1 })}
            src={leftIcon}
            alt=""
          />
          <img
            onClick={onRight}
            className={classnames("right", { disabled: isReverseDirection ? rule1 : rule2 })}
            src={rightIcon}
            alt=""
          />
        </div>
      </div>
    );
  }
}