import React, {Fragment, useState} from "react";
import classNames from "classnames";
import './AgingTable.scss';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import Loader from "components/common/Loader";

const headCells = [
  { id: 'Name', label: ''},
  { id: 'Less3d', label: '< 3 DAYS', sort: true},
  { id: 'Less1w', label: '< 1 WEEK', sort: true},
  { id: 'Less1m', label: '< 1 Month', sort: true },
  { id: 'Less3m', label: '< 3 Months', sort: true },
  { id: 'More3m', label: '> 3 Months', sort: true },
  { id: 'Total', label: 'Total', sort: true },
];

const AgingTable = ({data}) => {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [expanded, setExpanded] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortRows = (arr) => {
    if(!orderBy) return arr;
    const newArr = arr.slice(0, -1).sort((a, b) => order === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy]);
    return [...newArr, ...arr.slice(-1)];
  };

  const toggleExpanded = (item) => {
    let newExpanded;
    if(expanded.includes(item)){
      newExpanded = expanded.slice().filter(el => el !== item)
    }else {
      newExpanded = [...expanded.slice(), item]
    }
    setExpanded(newExpanded);
  };

  return (
    <div className="chart">
      <div className="chart__header">
        <h5>
          Aging
        </h5>
      </div>
      <div className="chart__main">
        {!data.loading
          ? <TableContainer className='agingTable'>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      headCell.sort
                        ? <TableCell
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? order : false}
                          align="right"
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                        : <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortRows(data.chart).map((item, i) => (
                    <Fragment key={i}>
                      <TableRow
                        key={item.Name}
                        className={classNames("table-row parent-row", {rowClosed: !expanded.includes(item.Name)})}
                        onClick={() => toggleExpanded(item.Name)}
                      >
                        <TableCell component="th" scope="row" className='classification'>{item.Name}</TableCell>
                        <TableCell align="right">{item.Less3d}</TableCell>
                        <TableCell align="right">{item.Less1w}</TableCell>
                        <TableCell align="right">{item.Less1m}</TableCell>
                        <TableCell align="right">{item.Less3m}</TableCell>
                        <TableCell align="right">{item.More3m}</TableCell>
                        <TableCell align="right">{item.Total}</TableCell>
                      </TableRow>
                      {item.Data?.map(row => (
                        <TableRow key={row.Priority} className={classNames("collapse-row", {rowClosed: !expanded.includes(item.Name)})}>
                          <TableCell component="th" scope="row">
                            <Collapse in={expanded.includes(item.Name)}>{row.Priority}</Collapse>
                          </TableCell>
                          <TableCell align="right">
                            <Collapse in={expanded.includes(item.Name)}>{row.Less3d}</Collapse>
                          </TableCell>
                          <TableCell align="right">
                            <Collapse in={expanded.includes(item.Name)}>{row.Less1w}</Collapse>
                          </TableCell>
                          <TableCell align="right">
                            <Collapse in={expanded.includes(item.Name)}>{row.Less1m}</Collapse>
                          </TableCell>
                          <TableCell align="right">
                            <Collapse in={expanded.includes(item.Name)}>{row.Less3m}</Collapse>
                          </TableCell>
                          <TableCell align="right">
                            <Collapse in={expanded.includes(item.Name)}>{row.More3m}</Collapse>
                          </TableCell>
                          <TableCell align="right">
                            <Collapse in={expanded.includes(item.Name)}>{row.Total}</Collapse>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          : <Loader height={330} width="100%">
              {[...new Array(10)].map((val, i) => {
                return (<rect key={i} x="0" y={i * 10 + '%'} rx="4" ry="4" width="100%" height="9%"/>);
              })}
            </Loader>
        }
      </div>
    </div>
  )
};

export default AgingTable;