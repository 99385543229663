import classnames from 'classnames';
import React from "react";
import { Checkbox } from "../../common/Checkbox";
import "./index.scss";
import freezeOn from "../../../assets/icons/table/freeze-on.svg";
import freezeOff from "../../../assets/icons/table/freeze-off.svg";
import reorder from "../../../assets/icons/table/reorder.svg";

export function ColumnOptionRow(props) {
  const {
    option,
    checked,
    frozenLeft,
    frozenRight,
    onFrozenLeftChange,
    onFrozenRightChange,
    onCheckboxChange
  } = props;

  return (
    <div className={classnames("column-option-row")}>
      <div className="checkbox-container">
        <img className="reorder" src={reorder} alt=""/>
        <Checkbox
          onChange={onCheckboxChange}
          checked={checked}
        />
        <p>{option.title}</p>
      </div>
      <div className="freeze-container">
        <img
          className="freeze-left"
          src={frozenLeft ? freezeOn : freezeOff}
          onClick={() => {
            onFrozenLeftChange && onFrozenLeftChange.call(this, !frozenLeft);
          }}
          alt=""
        />
        <img
          className="freeze-right"
          src={frozenRight ? freezeOn : freezeOff}
          onClick={() => {
            onFrozenRightChange && onFrozenRightChange.call(this, !frozenRight);
          }}
          alt=""
        />
      </div>
    </div>
  );
}