import React, { useState } from "react";
import classnames from "classnames";
import Modal from "components/Modal/Modal";
import SurveyModal from "./SurveyModal";
import "./index.scss";
import InitiationModal from "./InitiationModal";
import ForecastModal from "./ForecastModal";
import AddDocumentsModal from "./AddDocumentsModal";
import {useSelector} from "react-redux";
import AddTask from "./AddTask";
import TicketModal from "containers/Boards/modals/TicketModal/TicketModal";

const ProjectModal = ({ projectModal, setProjectModal, activeMilestone, setActiveMilestone, id }) => {
  const [isFullPage, setFullPage] = useState(false);
  const modalLoading = useSelector((state) => state.project.modalLoading);

  const changeModal = (type) => {
    setProjectModal(type);
  };

  const getActiveModal = () => {
    switch (projectModal){
      case 'survey':
        return (
          <SurveyModal
            changeModal={changeModal}
            isFullPage={isFullPage}
            setFullPage={setFullPage}
            activeMilestone={activeMilestone}
            setActiveMilestone={setActiveMilestone}
            setProjectModal={setProjectModal}
          />
        )
      case 'initiation':
        return (
          <InitiationModal activeMilestone={activeMilestone} setProjectModal={setProjectModal}/>
        )
      case 'forecast':
        return (
          <ForecastModal activeMilestone={activeMilestone} setProjectModal={setProjectModal}/>
        )
      case 'documents':
        return (
          <AddDocumentsModal activeMilestone={activeMilestone} setProjectModal={setProjectModal}/>
        )
      case 'tasks':
        return (
          <AddTask activeMilestone={activeMilestone} setProjectModal={setProjectModal}/>
        )
      case 'issueForProject':
        return (
          <TicketModal open={true} setIsModalOpen={() => setProjectModal(null)} activityId={id}/>
        )
      case 'issueForTask':
        return (
          <TicketModal open={true} setIsModalOpen={() => setProjectModal(null)} activityId={id} taskId={activeMilestone.task_id}/>
        )
      default:
        return null;
    }
  }

  return (
    <div className='product-modal'>
      <Modal
        className={classnames({
          "full-width": isFullPage,
          "small-width": projectModal !== 'survey',
          "survey-modal-wrap": projectModal === 'survey',
        })}
        onBackgroundClick={() => setProjectModal(null)}>
        {!modalLoading
          ? getActiveModal()
          : <p>loading</p>
        }
      </Modal>
    </div>
  );
};

export default ProjectModal;