import React from 'react';
import {Line} from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales : {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: false
      },
    }
  }
};

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 100, 50, 20, 33, 21, 43],
      borderColor: '#0579DF',
      borderWidth: 2,
      pointRadius: 0
    },
  ],
};


const LineChart = () => {
  return (
    <div>
      <div className="chart">
        <div className="chart__header">
          <h5>Projects completed</h5>
        </div>
        <div className="chart__main">
          <Line data={data} options={options} type='line'/>
        </div>
      </div>
    </div>
  );
};

export default LineChart;