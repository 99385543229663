import React, {useEffect} from 'react';
import {GET_PROJECT_DOCUMENTS_REQUEST} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";
import {Table} from "components/Table";
import Skeleton from "components/common/Skeleton";
import useTable from "hooks/useTable";
import {useParams} from "react-router-dom";
import {DownloadIcon} from "components/Icons";
import {DOWNLOAD_MEDIA_REQUEST} from "store/actions/common";
import {fileSize} from "helpers";

const headCells = [
  {attr: "field_name", title: "Milestone Name"},
  {attr: "file_type", title: "Document Type"},
  {attr: "media_name", title: "Document Title"},
  {attr: "size", title: "Size"},
  {attr: "Actions", title: ""}
];


const Documents = () => {

  const params = useParams();
  const {id} = params;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const projectDocuments = useSelector((state) => state.project.projectDocuments);
  const loading = useSelector((state) => state.project.modalLoading);

  useEffect(() => {
    dispatch({
      type: GET_PROJECT_DOCUMENTS_REQUEST,
      payload: {auth: userData, id},
    })
  }, [dispatch, userData, id]);

  const {tableData, handleRequestSort} = useTable(projectDocuments);

  const options = {
    field_name: {
      styles: {minWidth: "300px"},
    },
    file_type: {
      styles: {minWidth: "300px"},
    },
    media_name: {
      styles: {minWidth: "300px"},
    },
    Actions: {
      styles: {minWidth: "40px", maxWidth: "40px"},
      className: 'actions'
    },
  };

  const transformers = {
    size: (row) => fileSize(row.size),
    Actions: (file) => {
      return (
        <div className='actions__wrap' onClick={() => {
          dispatch({type: DOWNLOAD_MEDIA_REQUEST, payload: {auth: userData, file}})
        }}>
          <DownloadIcon/>
        </div>
      )
    }
  }


  return (
    <div className='sites list-table-page'>
      <div className='sites__header'>
        <h1 className='page-title'>Documents</h1>
      </div>
      {!loading
        ? <Table
            data={tableData}
            headers={headCells}
            onRequireList={handleRequestSort}
            link={true}
            options={options}
            transformers={transformers}
            fixedHeader
          />
        : <Skeleton/>
      }
    </div>
  );
};

export default Documents;