const ENVIRONMENT_DEVELOPMENT = "development";
// const ENVIRONMENT_STAGING = "staging";
// const ENVIRONMENT_PRODUCTION = "production";

const PASSWORD_HASH_SECRET = "q#46safgjk#@skfn";

export function isDevelopment() {
  return process.env.NODE_ENV.toLowerCase() === ENVIRONMENT_DEVELOPMENT;
}

export const decryptData = str =>{
  var CryptoJS = require("crypto-js");

  const ciphertext = CryptoJS.enc.Base64.parse(str);
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  const key = CryptoJS.enc.Utf8.parse(PASSWORD_HASH_SECRET);

  // decryption
  const decrypted = CryptoJS.AES.decrypt({ciphertext: ciphertext}, key, {
    iv: iv,
    mode: CryptoJS.mode.CFB
  });

  let data = decrypted.toString(CryptoJS.enc.Utf8);
  data = data.replaceAll("u'","'");
  data = data.replaceAll("'",'"');
  return data ? JSON.parse(data) : {};
};
