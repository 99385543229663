import React  from "react";
import {Table} from "../Table";
import { useSelector } from "react-redux";
import classnames from "classnames";
import useTable from "hooks/useTable";
import CountUp from "react-countup";

const SummaryBulkTable = ({data, hasAsbuiltColumn, headCells, title}) => {

  const userData = useSelector(state => state.user);
  const {tableData, handleRequestSort} = useTable(data.errors);

  const { total_sites, sites_with_errors, TotalItems, ItemsWithErrors } = data;

  const summaryInfo = {
    'Total Sites': total_sites,
    'Sites With Errors': sites_with_errors,
    'Total Items': TotalItems,
    'Items With Errors': ItemsWithErrors
  }

  return (
    <>
      <div className='upload-summary'>
        <h2>{title}</h2>
        <ul className='summary'>
          {Object.entries(summaryInfo)?.map(([key, value]) => {
            return (
              <li key={key} className='summary-item'>
                <div className='summary-item-top'>
                  <h5>{key}</h5>
                  <h3 className={classnames({error: key.includes('Errors')})}><CountUp end={+value} separator="," delay={0} duration={1.5} /></h3>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <Table
        title="Errors"
        data={tableData}
        headers={headCells}
        onRequireList={handleRequestSort}
        userData={userData}
        hasAsbuiltColumn={hasAsbuiltColumn}
        withExport
      />
    </>
  )
};

export default SummaryBulkTable;