import {combineReducers} from "redux";

import auth from "./auth";
import project from "./project";
import common from "./common";
import site from "./site";
import notification from "./notification";
import dashboard from "./dashboard";
import boards from "./boards";
import userManagement from "./userManagement";

const reducers = combineReducers({
  auth,
  project,
  common,
  site,
  notification,
  dashboard,
  boards,
  userManagement,
});

export {reducers};
