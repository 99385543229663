import classnames from 'classnames';
import React from "react";
import PropTypes from 'prop-types'
import { DebounceInput } from "react-debounce-input";
import Button, {BUTTON_COLORS} from "../../common/Button/Button";
import Modal from "../../Modal/Modal";
import { ColumnOptionRow } from "../ColumnOptionRow";
import "./index.scss";
import cogwheel from "../../../assets/icons/table/cogwheel.svg";
import filter from "../../../assets/icons/table/filter.svg";
import search from "../../../assets/icons/table/search.svg";
import close from "../../../assets/icons/table/close.svg";
import {DragAndDrop} from "../../common/DragAndDrop";
import {CSVLink} from "react-csv";

export function TableSearch(props) {
  return (
    <div className={classnames("table-search")}>
      <img className="search" src={search} alt=""/>
      <DebounceInput
        type="text"
        debounceTimeout={500}
        onChange={e => {
          props.onChange.apply(null, [e.target.value]);
        }}
        value={props.searchText}
        placeholder={props.hasOwnProperty("placeholder") ? props.placeholder : "Search by keyword..."}
        name='search'
      />
      <img
        className={classnames("close", { active: props.searchText })}
        src={close}
        onClick={props.onClose}
        alt=""
      />
    </div>
  );
}

export class TableHeader extends React.Component {

  static propTypes = {
    searchText: PropTypes.string,
    onSearchChange: PropTypes.func,
    onSearchClose: PropTypes.func,
    onFilterClick: PropTypes.func,
    onGroupSave: PropTypes.func,
    userData: PropTypes.object,
    onSettingsSave: PropTypes.func,
    filteredAmount: PropTypes.number,
    withExport: PropTypes.bool,
    groupOptions: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      attr: PropTypes.string,
    })),
    popularGroupOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    data: PropTypes.array,
    extraFilters: PropTypes.any,
    hideSettings: PropTypes.bool
  };

  static defaultProps = {
    searchText: "",
    onSearchChange: null,
    onSearchClose: null,
    onFilterClick: null,
    onGroupSave: null,
    onSettingsSave: null,
    filteredAmount: 0,
    withExport: false,
    groupOptions: [],
    userData: {},
    popularGroupOptions: [],
    data: [],
    extraFilters: null,
    hideSettings: false
  };

  state = {
    isFilterBarOpen: false,
    isGroupModalOpen: false,
    isSettingsModalOpen: false,
    levelDropdownIndex: null,
    groupBy: [],
    initialColumns: [],
    frozenLeftColumns: [],
    frozenRightColumns: this.props.fixedRight || [],
    invisibleColumns: [],
    orderedColumns: [],
  };

  componentDidMount() {
    const { columns } = this.props;

    if (columns.length !== this.state.orderedColumns.length) {
      this.setState({
        initialColumns: columns,
        orderedColumns: columns
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { groupBy } = this.state;
    const { groupOptions, onGroupSave } = props;

    if (!groupBy.every(attr => groupOptions.some(option => option.attr === attr))) {
      const filtered = groupBy.filter(attr => groupOptions.some(option => option.attr === attr));
      this.setState({
        groupBy: filtered
      });
      onGroupSave && onGroupSave.call(this, filtered);
    }
  }

  onFilterClick = () => {
    const { onFilterClick } = this.props;
    const { isFilterBarOpen } = this.state;

    this.setState({
      isFilterBarOpen: !isFilterBarOpen
    }, () => {
      onFilterClick && onFilterClick.apply(this, [!isFilterBarOpen]);
    });
  };

  onModalBackgroundClick = () => {
    this.setState({
      isGroupModalOpen: false,
      isSettingsModalOpen: false,
    });
  };

  onSettingsClick = () => {
    const { isSettingsModalOpen } = this.state;

    this.setState({
      isSettingsModalOpen: !isSettingsModalOpen
    });
  };

  onSettingsSave = () => {
    const { onSettingsSave } = this.props;
    const { orderedColumns, invisibleColumns, frozenLeftColumns, frozenRightColumns } = this.state;

    const visibleColumns = orderedColumns.filter(option => {
      return !invisibleColumns.includes(option.attr);
    });

    const fixedLeft = visibleColumns.filter(col => frozenLeftColumns.includes(col.attr)).map(col => col.attr);
    const fixedRight = visibleColumns.filter(col => frozenRightColumns.includes(col.attr)).map(col => col.attr);

    this.setState({
      isSettingsModalOpen: false
    }, () => {
      onSettingsSave && onSettingsSave.apply(this, [
        visibleColumns,
        fixedLeft,
        fixedRight,
      ]);
    });
  };

  onSettingsReset = () => {
    const { initialColumns } = this.state;

    this.setState({
      isSettingsModalOpen: false,
      frozenLeftColumns: [],
      frozenRightColumns: [],
      invisibleColumns: [],
      orderedColumns: initialColumns,
    }, () => {
      this.onSettingsSave.call(this);
    });
  };

  onFreezeChange(isLeft, option, frozen) {
    const primary = isLeft ? "frozenLeftColumns" : "frozenRightColumns";
    const secondary = isLeft ? "frozenRightColumns" : "frozenLeftColumns";

    if (frozen) {
      if (this.state[primary].length >= 3) {
        return;
      }

      this.setState({
        [primary]: [...new Set([option.attr, ...this.state[primary]])],
        [secondary]: this.state[secondary].filter(attr => attr !== option.attr)
      });
      return;
    }

    this.setState({
      [primary]: this.state[primary].filter(attr => attr !== option.attr)
    });
  }

  onCheckboxChange(option, checked) {
    const { invisibleColumns } = this.state;

    if (!checked) {
      this.setState({
        invisibleColumns: [...new Set([option.attr, ...invisibleColumns])]
      });
      return;
    }

    this.setState({
      invisibleColumns: invisibleColumns.filter(attr => attr !== option.attr)
    });
  }

  generateCSVContent = () => {
    const { columns, data } = this.props;

    const res = [];
    const skipColumns = ['Errors', 'Actions'];
    res.push(columns.reduce((acc, header) => !skipColumns.includes(header.attr) ? [...acc, header.title] : acc, []));
    data.forEach((_, i) => {
      res.push(columns.reduce((acc, header) => !skipColumns.includes(header.attr)
        ? [...acc, data[i][header.attr]]
        : acc
      , []))
    })
    return res;
  }

  renderSettingsModal() {
    const {
      orderedColumns,
      invisibleColumns,
      frozenLeftColumns,
      frozenRightColumns,
    } = this.state;

    return (
      <React.Fragment>
        <DragAndDrop
          items={orderedColumns.map((option, i) => {
            return {
              id: String(i),
              data: option,
              node: (
                <ColumnOptionRow
                  checked={!invisibleColumns.includes(option.attr)}
                  option={option}
                  frozenLeft={frozenLeftColumns.includes(option.attr)}
                  frozenRight={frozenRightColumns.includes(option.attr)}
                  onFrozenLeftChange={frozen => this.onFreezeChange(true, option, frozen)}
                  onFrozenRightChange={frozen => this.onFreezeChange(false, option, frozen)}
                  onCheckboxChange={checked => this.onCheckboxChange(option, checked)}
                />
              )
            };
          })}
          onOrder={items => {
            this.setState({
              orderedColumns: items.map(item => item.data)
            });
          }}
        />
        <div key={"save-button"} className="row">
          <Button className="reset" title="Reset" onClick={this.onSettingsReset} />
          <Button className="save" title="Apply changes" onClick={this.onSettingsSave} />
        </div>
      </React.Fragment>
    );
  }

  render () {
    const {
      onSearchChange,
      onSearchClose,
      filteredAmount,
      searchText,
      withExport,
      extraFilters,
      hideSettings
    } = this.props;
    const { isFilterBarOpen, isSettingsModalOpen } = this.state;

    return (
      <div className={classnames("table-header-component")}>
        <TableSearch
          searchText={searchText}
          onChange={onSearchChange}
          onClose={onSearchClose}
        />
        {extraFilters &&
          <div className='table-extra-filters'>
            {extraFilters}
          </div>
        }
        <div className="table-header-btns">
          {withExport && (
            <CSVLink
              className='export-btn'
              filename={"export.csv"}
              data={this.generateCSVContent()}
            >
              <Button
                title='Export'
                color={BUTTON_COLORS.TRANSPARENT}
              />
            </CSVLink>
          )}

          <Button
            title={filteredAmount > 0 ? `Filter (${filteredAmount})` : `Filter`}
            icon={filter}
            color={BUTTON_COLORS.TRANSPARENT}
            active={isFilterBarOpen}
            onClick={this.onFilterClick}
          />

          {!hideSettings && (
            <Button
              title=""
              icon={cogwheel}
              color={BUTTON_COLORS.TRANSPARENT}
              active={isSettingsModalOpen}
              onClick={this.onSettingsClick}
            />
          )}

        </div>

        {isSettingsModalOpen && (
          <Modal
            className="settings-modal small-width"
            onBackgroundClick={this.onModalBackgroundClick}
          >
            <h3>Manage columns</h3>
            {this.renderSettingsModal()}
          </Modal>
        )}
      </div>
    );
  }
}
