import React, {useState} from 'react';
import {TextField} from "@mui/material";
import {NavLink} from "react-router-dom";
import {ArrowLeftIcon} from "../../../components/Icons";
import {RESET_EMAIL_REQUEST} from "../../../store/actions/userManagement";
import {useDispatch, useSelector} from "react-redux";
import classnames from "classnames";

const ForgotPassword = () => {

  const [username, setUsername] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: RESET_EMAIL_REQUEST,
      payload: username,
    })
  }

  return (
    <div className="auth-card">
      <div className="auth-card-head">
        <h2>
          <NavLink to='/pdt/auth'>
            <ArrowLeftIcon/>
          </NavLink>
          Forgot password
        </h2>
        <p>To recover your password, enter your Username to receive a reset link</p>
      </div>
      <form className="auth-card-form">
        <div className="auth-field">
          <h6>Username</h6>
          <TextField
            fullWidth
            type="text"
            value={username}
            placeholder='Username'
            onChange={e => setUsername(e.target.value)}
          />
        </div>

        <button
          className={classnames({loading})}
          type="submit"
          onClick={handleSubmit}
          disabled={!username}
        >
          Reset password
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;