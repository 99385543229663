import React, {useEffect, useState} from 'react';
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import UploadDragAndDrop from "components/common/UploadDragAndDrop/UploadDragAndDrop";
import {useDispatch, useSelector} from "react-redux";
import {UPLOAD_MEDIA_ALL_REQUEST} from "store/actions/common";
import {CLEAR_ATTACHMENT_TYPE, GET_ATTACHMENT_TYPE_REQUEST, SAVE_TASK_MEDIA_REQUEST} from "store/actions/project";
import {useParams} from "react-router-dom";

const AddDocumentsModal = ({activeMilestone, setProjectModal}) => {

  const params = useParams();
  const {id} = params;
  const [state, setState] = useState([]);
  const loading = useSelector((state) => state.common.loadingMedia);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const projectDetails = useSelector((state) => state.project.activity);
  const fileTypes = useSelector((state) => state.project.fileTypes);

  useEffect(() => {
    dispatch({
      type: GET_ATTACHMENT_TYPE_REQUEST,
      payload: {
        auth: userData,
        FieldName: projectDetails?.Tasks.find(item => item.task_id === activeMilestone.parent_id)?.task_name
      },
    })
    return () => dispatch({type: CLEAR_ATTACHMENT_TYPE});
  }, []);//eslint-disable-line

  const addFiles = e => {
    dispatch({
      type: UPLOAD_MEDIA_ALL_REQUEST,
      payload: {body: e.target.files, bucket: 'Milestone', addFields: {TableName: 'dbo.task', FieldName: activeMilestone.task_name, "ObjectId": activeMilestone.task_id}},
      callback: (res) => setState([...state, ...res]),
    });
  };

  const removeFile = (id) => {
    setState(state.filter(el => el.MediaReferenceId !== id));
  }

  const handleFileState = (e, index, fileObj, field) => {
    setState([
      ...state.slice(0, index),
      {...fileObj, [field]: e.target.value},
      ...state.slice(index + 1)
    ])
  }


  const addFileType = (e, index) => {
    const {Remarks: _, ...fileObj} = state[index];
    handleFileState(e, index, fileObj, 'FileType');
  }


  const handleSubmit = () => {
    dispatch({
      type: SAVE_TASK_MEDIA_REQUEST,
      payload: {
        auth: userData,
        id,
        body: state
      },
      callback: () => setProjectModal(null)
    })
  }



  return (
    <div className='initiation-modal'>
      <h3>Add documents</h3>

      <UploadDragAndDrop
        files={state}
        addFiles={addFiles}
        removeFile={removeFile}
        loading={loading}
        addFileType={addFileType}
        fileTypes={fileTypes}
      />

      <div className='modal-btns'>
        <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal(null)}/>
        <Button title='Submit' color={BUTTON_COLORS.DEFAULT} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default AddDocumentsModal;