export const UPLOAD_MEDIA_REQUEST = "UPLOAD_MEDIA_REQUEST";
export const UPLOAD_MEDIA_SUCCESS = "UPLOAD_MEDIA_SUCCESS";
export const UPLOAD_MEDIA_FAIL = "UPLOAD_MEDIA_FAIL";

export const UPLOAD_MEDIA_ALL_REQUEST = "UPLOAD_MEDIA_ALL_REQUEST";
export const UPLOAD_MEDIA_ALL_SUCCESS = "UPLOAD_MEDIA_ALL_SUCCESS";
export const UPLOAD_MEDIA_ALL_FAIL = "UPLOAD_MEDIA_ALL_FAIL";

export const PARSE_BULK_UPLOAD_REQUEST = "PARSE_BULK_UPLOAD_REQUEST";
export const PARSE_BULK_UPLOAD_SUCCESS = "PARSE_BULK_UPLOAD_SUCCESS";
export const PARSE_BULK_UPLOAD_FAIL = "PARSE_BULK_UPLOAD_FAIL";

export const DOWNLOAD_MEDIA_REQUEST = "DOWNLOAD_MEDIA_REQUEST";
export const DOWNLOAD_MEDIA_SUCCESS = "DOWNLOAD_MEDIA_SUCCESS";
export const DOWNLOAD_MEDIA_FAIL = "DOWNLOAD_MEDIA_FAIL";

export const DOWNLOAD_MEDIA_ALL_REQUEST = "DOWNLOAD_MEDIA_ALL_REQUEST";

export const CLEAR_PREVIOUS_BULK_UPLOAD = "CLEAR_PREVIOUS_BULK_UPLOAD";

export const GET_LOOKUP_LIST_REQUEST = "GET_LOOKUP_LIST_REQUEST";
export const GET_LOOKUP_LIST_SUCCESS = "GET_LOOKUP_LIST_SUCCESS";
export const GET_LOOKUP_LIST_FAIL = "GET_LOOKUP_LIST_FAIL";

export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAIL = "DELETE_MEDIA_FAIL";