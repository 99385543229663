import React, {useEffect, useState} from "react";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import UserChip from "components/common/UserChip/UserChip";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {
  GET_PROJECT_ROLES_REQUEST,
  SAVE_TASK_STAKEHOLDER_REQUEST
} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {GET_USERS_LIST_REQUEST} from "store/actions/userManagement";

const InitiationModal = ({activeMilestone, setProjectModal}) => {

  const params = useParams();
  const {id} = params;
  const [owner, setOwner] = useState(activeMilestone?.owner.user_id);
  const [stakeholders, setStakeholders] = useState(activeMilestone?.stakeholder
    ? activeMilestone.stakeholder.map(item => item.role_id) : []
  );
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const projectRoles = useSelector((state) => state.project.projectRoles);
  const usersList = useSelector((state) => state.userManagement.usersList);

  useEffect(() => {
    if(!projectRoles){
      dispatch({type: GET_PROJECT_ROLES_REQUEST})
    }
    dispatch({type: GET_USERS_LIST_REQUEST, payload: {role: "NW Submitter"}});
  }, []); //eslint-disable-line


  const handleStakeholders = (id) => {
    setStakeholders(stakeholders.includes(id) ? stakeholders.filter(item => item !== id) : [...stakeholders, id]);
  };

  const handleSubmit = () => {
    const data = {
      owner: owner,
      stakeholder: stakeholders.map(item => ({role_id: item, deleted: false})),
    }
    if(activeMilestone){
      data.task_id = activeMilestone.task_id;
    }else{
      data.activity_id = id;
    }

    dispatch({
      type: SAVE_TASK_STAKEHOLDER_REQUEST,
      payload: {
        auth: userData,
        id,
        body: data
      },
      callback: () => setProjectModal(null)
    })
  }

  return (
    <div className='initiation-modal'>
      <h3>Assign Submitter and Stakeholders</h3>

      <div className="modal-field modal-select">
        <h6>Submitter</h6>
        <Select
          value={owner}
          variant="outlined"
          onChange={e => setOwner(e.target.value)}
          displayEmpty={true}
          // renderValue={value => value ?  value.full_name : 'Select owner'}
          // MenuProps={MENU_PROPS}
          // className={classNames({empty: !state.vendor})}
        >
          <MenuItem className='hiddenOption' key="0" disabled value="none" >Select owner</MenuItem>
          {usersList?.map(item =>
            <MenuItem key={item.user_id} value={item.user_id}>{item.user_name}</MenuItem>
          )}
        </Select>
      </div>

      <div className="modal-field stakeholders">
        <h6>Stakeholders ({stakeholders.length})</h6>

        <div className='stakeholders__items'>
          {projectRoles?.stakeholder.map((item, i) =>
            <UserChip
              key={i}
              checked={stakeholders.includes(item.role_id)}
              title={item.role_name}
              onClick={() => handleStakeholders(item.role_id)}
            />
          )}
        </div>
      </div>

      <div className='modal-btns'>
        <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal(null)}/>
        <Button title='Submit' color={BUTTON_COLORS.DEFAULT} onClick={handleSubmit}/>
      </div>
    </div>
  );
};

export default InitiationModal;
