import React from 'react';
import Button, {BUTTON_COLORS} from "../Button/Button";
import './UploadDragAndDrop.scss';
import {fileSize} from "helpers";
import {DeleteIcon, SuccessIcon, UploadIcon, WarningIcon} from "components/Icons";
import Loader from "../Loader";
import {InputBase, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const UploadDragAndDrop = (props) => {

  const {
    files = [],
    addFiles,
    loading,
    removeFile,
    addFileType,
    fileTypes
  } = props;

  return (
    <div className='uploadDND'>
      <form className="uploadDND-form">
        <div className="uploadDND-wrapper">
          <input name='files' value='' type="file" onChange={addFiles} multiple/>
          <div className='uploadDND-description'>
            <Button
              title='Select file'
              color={BUTTON_COLORS.GRAY}
            />
            <span/>
            <p className='uploadDND-caption'><UploadIcon/>Drag and drop file here...</p>
          </div>
        </div>
      </form>
      {(!!files.length || loading) && (
        <div className="uploadDND-preview">
          {files.map((file, i) =>
            <div className="file-preview" key={i}>
              {(!file.FileType || (file.FileType === 'Other' && !file.Remarks))
                ? <div className='file-icon warning'><WarningIcon/></div>
                : <div className='file-icon'><SuccessIcon/></div>
              }
              <div className="file-preview-info">
                {file.MediaName}
                <p>{fileSize(file.Size)}</p>
              </div>
              <div className="select-field">
                <Select
                  value={file.FileType || 'none'}
                  type="text"
                  variant="outlined"
                  input={<InputBase />}
                  onChange={e => addFileType(e, i)}
                >
                  <MenuItem className='hiddenOption' key="0" disabled value="none" >File type</MenuItem>
                  {fileTypes.map(item =>
                    <MenuItem key={item.Id} value='Site Handover Document Package'>{item.file_type}</MenuItem>
                  )}
                </Select>
              </div>
              <span className='file-remove' onClick={() => removeFile(file.MediaReferenceId)}>
                <DeleteIcon/>
              </span>
            </div>
          )}
          {loading && (
            <div className="file-preview">
              <Loader height={32} width="100%"><rect x="0" y="0" rx="4" ry="4" width="100%" height="32px" /></Loader>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadDragAndDrop;