import React, {useEffect} from 'react';
import {GET_USER_DETAILS_REQUEST, TOGGLE_USER_AVAILABILITY_REQUEST} from "store/actions/userManagement";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {ArrowLeftIcon, SettingsIcon, UserIcon} from "components/Icons";
import './UserDetails.scss';
import Switcher from "../../../components/common/Switcher/Switcher";
import Loader from "../../../components/common/Loader";

const UserDetails = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const {id} = params;
  const loading = useSelector((state) => state.userManagement.loading);
  const userDetails = useSelector((state) => state.userManagement.userDetails);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    dispatch({type: GET_USER_DETAILS_REQUEST, payload: id})
  }, [dispatch, id]);

  const changeUserAvailability = () => {
    dispatch({
      type: TOGGLE_USER_AVAILABILITY_REQUEST,
      payload: {user_id: id, status: userDetails.is_active ? 'inactive' : 'active'}
    })
  }

  return (
    <div className='userDetails'>
      {!loading
        ? <>
          <div className="userDetails__header">
            <div className="page-title-wrap">
              <div className="page-title-left">
                <Link to='/pdt/user-management' className='back-btn'>
                  <ArrowLeftIcon/>
                </Link>
                <h1 className='page-title'>{userDetails.user_name}</h1>
              </div>
            </div>
          </div>
          <div className="userDetailsInfo">
            <div className="info">
              <div className="info__header">
                <UserIcon/>
                <h5>User information</h5>
              </div>
              <ul className='info__items'>
                <li>First name <span>{userDetails.first_name}</span></li>
                <li>Middle name <span>{userDetails.middle_name}</span></li>
                <li>Last name <span>{userDetails.last_name}</span></li>
                <li>Email <span>{userDetails.email}</span></li>
                <li>Phone <span>{userDetails.phone}</span></li>
                <li>Role <span>{userDetails.role_name}</span></li>
              </ul>
            </div>
            <div className="info">
              <div className="info__header">
                <SettingsIcon/>
                <h5>User settings</h5>
              </div>
              <ul className='info__items'>
                <li>
                  Is active
                  <Switcher
                    checked={userDetails.is_active}
                    onChange={changeUserAvailability}
                    disabled={!user?.is_admin}
                  />
                </li>
                <li>
                  Requires 2fa
                  <Switcher
                    checked={userDetails.requires_2fa}
                    onChange={() => {}}
                    disabled
                  />
                </li>
                <li>
                  Must change password
                  <Switcher
                    checked={userDetails.must_change_password}
                    onChange={() => {}}
                    disabled
                  />
                </li>
                <li>
                  Is admin
                  <Switcher
                    checked={userDetails.is_admin}
                    onChange={() => {}}
                    disabled
                  />
                </li>
              </ul>
            </div>
          </div>
        </>
        : <Loader height={700} width="100%">
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="50px"/>
            <rect x="0" y="70px" rx="4" ry="4" width="49%" height="500px"/>
            <rect x="51%" y="70px" rx="4" ry="4" width="49%" height="500px"/>
          </Loader>
      }
    </div>
  );
};

export default UserDetails;