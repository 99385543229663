import axiosInstance from "./axiosInstance";
import TokenService from "./token.service";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      console.log(originalConfig, 'originalConfig')
      if (originalConfig.url !== "/pdt/auth" && err.response) {
        if (err.response.status === 401) {
          TokenService.removeToken();
          localStorage.removeItem('user');
          window.location.href = '/pdt/auth';
        }
      }

      // return Promise.reject(err);
    }
  );
};

export default setup;