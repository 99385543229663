import React, {useState} from 'react'
import { Draggable } from 'react-beautiful-dnd'
import classnames from "classnames";
import './Ticket.scss';
import {useSelector} from "react-redux";
import DropDown from "../components/DropDown/DropDown";
import {ChatIcon, MoreIcon, TotalIcon, UserIcon, WarningIcon} from "components/Icons";

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

const Ticket = (props) => {
  const {
    item,
    column,
    index,
    toggleTicketModal,
    openTicketSupportModal,
    removeTicket,
    openAssignTicketModal,
    setUpdateAction,
    openUpdateModal
  } = props;

  const [listItems, setListItems] = useState({});
  const userData = useSelector(state => state.auth.user);

  const getOptions = (e) => {
    e.stopPropagation();
    let items = {};
    if(column === 'New'){
      if(userData.role === item.created_by_user || userData.role === 'Project Manager') {
        items = {
          'Edit': () => toggleTicketModal(item),
          'Cancel': () => removeTicket(column, item)
        }
      }else {
        items = {
          'Assign to myself': () => openAssignTicketModal(item, column, true),
          'Assign to Support Group': () => openAssignTicketModal(item, column, false)
        }
      }
    }else if(column === 'Assigned'){
      items = {
        'Close': () => {
          openUpdateModal(item, column);
          setUpdateAction('Closed')
        },
        'Re-assign': () => openAssignTicketModal(item, column),
      };
      if(userData.role === item.created_by_user){
        items['Start work'] = () => {
          openUpdateModal(item, column);
          setUpdateAction('In Progress')
        }
      }
    } else if(column === 'In Progress'){
      items = {
        'Put on Hold': () => {
          openUpdateModal(item, column);
          setUpdateAction('On Hold')
        },
        'Re-assign': () => openAssignTicketModal(item, column),
        'Mark as Done': () => {
          openUpdateModal(item, column);
          setUpdateAction('Resolved')
        },
      }
    }else if(column === 'On Hold'){
      items = {
        'Unhold': () => {
          openUpdateModal(item, column);
          setUpdateAction('In Progress')
        }
      }
    }else if(column === 'Resolved'){
      items = {
        'Move to Archive': () => console.log(3),
        'Close': () => console.log(4),
      }
    }
    setListItems(items);
  };

  return (
    <Draggable
      draggableId={item.id.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classnames("ticket", {active: snapshot.isDragging, reviewed: item.reviewed})}
          onClick={() => openTicketSupportModal(item, column, index)}
        >
          <div className="ticket-header">
            <div className="ticket-main-info">
              {item.type === 'System' ? <UserIcon/> : <TotalIcon/>}
              <p>#{item.category}</p>
            </div>
            <time>
              {new Date(item.date_created).toLocaleString('en-US', {
                hour12: false ,
                day: 'numeric',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </time>
          </div>
          <p className='ticket-description'>{item.description}</p>
          <div className="ticket-footer">
            <div className="ticket-person">
              <UserIcon/>
              <p>{item.assigned_to_user || item.created_by_user}</p>
            </div>
            <div className={classnames("ticket-comments", {noComment: !item.comment_count})}>
              <ChatIcon/>
              {item.comment_count}
            </div>
            <div className={`ticket-priority priority-${item.priority}`}>
              <WarningIcon/>
              {item.priority}
            </div>
            <div className="ticket-actions" onClick={getOptions}>
              <DropDown
                IconNode={MoreIcon}
                withoutArrow
                withoutCheckbox
                listItems={listItems}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
};

export default React.memo(Ticket, areEqual);