import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {GET_MILESTONE_TEMPLATE_DETAILS_REQUEST, SAVE_MILESTONE_TASK_REQUEST, REARRANGE_MILESTONE_TASK_REQUEST} from "store/actions/project";
import Loader from "components/common/Loader";
import {Table} from "components/Table";
import useTable from "hooks/useTable";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import AddMilestoneTaskModal from "./AddMilestoneTaskModal";
import {
  AddIcon,
  ArrowLeftIcon,
  CheckedIcon,
  DeleteIcon,
  EditIcon,
  ErrorIcon,
  ReorderIcon,
  SortDownIcon, SortUpIcon
} from "components/Icons";
import ConfirmModal from "components/Modal/ConfirmModal";
import './ProjectTemplate.scss';

const ProjectTemplate = () => {
  const params = useParams();

  const {id} = params;
  const dispatch = useDispatch();
  const mileStoneTemplateDetails = useSelector((state) => state.project.mileStoneTemplateDetails);
  const loading = useSelector((state) => state.project.loading);
  const userData = useSelector(state => state.auth.user);
  const [isAddMilestoneTaskModalOpen, setIsAddMilestoneTaskModalOpen] = useState(false);
  const isPMOTeam = userData.role === 'PMO';
  const [currentMilestone, setCurrentMilestone] = useState(null);
  const [parentMilestone, setParentMilestone] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_MILESTONE_TEMPLATE_DETAILS_REQUEST,
      payload: {
        id,
      },
    });
  }, [id, dispatch]);

  const getSkeleton = () => {
    return (
      <Loader height={670} width='100%'>
        <rect x='0' y={0} rx='4' ry='4' width='10%' height='40px' />
        <rect x='70%' y={0} rx='4' ry='4' width='30%' height='40px' />

        <rect x='0' y={44} rx='4' ry='4' width='100%' height='80px' />

        <rect x='0' y={136} rx='4' ry='4' width='32%' height='30px' />
        <rect x='0' y={168} rx='4' ry='4' width='40%' height='160px' />

        <rect x='0' y={342} rx='4' ry='4' width='32%' height='40px' />

        <rect x='0' y={392} rx='4' ry='4' width='32%' height='40px' />
        <rect x='80%' y={392} rx='4' ry='4' width='20%' height='40px' />

        <rect x='0' y={436} rx='4' ry='4' width='100%' height='40px' />
        <rect x='0' y={480} rx='4' ry='4' width='100%' height='40px' />
        <rect x='0' y={524} rx='4' ry='4' width='100%' height='40px' />
        <rect x='0' y={568} rx='4' ry='4' width='100%' height='40px' />
        <rect x='0' y={612} rx='4' ry='4' width='100%' height='40px' />
        <rect x='0' y={656} rx='4' ry='4' width='100%' height='40px' />
      </Loader>
    );
  };

  const {Tasks, ProjectDetails} = mileStoneTemplateDetails;
  const {tableData, handleRequestSort} = useTable(Tasks);

  const options = {
    sequence: {
      className: 'sequenceCell'
    },
    task_name: {
      styles: { minWidth: "300px" },
      className: 'taskName'
    },
    Actions: {
      styles: { minWidth: "40px", maxWidth: "40px" },
      className: 'actions'
    }
  };

  const headCells = [
    { attr: "sequence", title: "Sequence" },
    { attr: "task_id", title: "Task Id" },
    { attr: "task_name", title: "Task Name" },
    { attr: "duration", title: "Duration" },
    { attr: "predecessor_task_name", title: "Predecessor" },
    { attr: "requires_approval", title: "Requires Approval" },
    { attr: "approver_role", title: "Approver" },
    { attr: "stakeholder_role", title: "Stakeholder" },
    ...isPMOTeam ? [{attr: "Actions", title: ""}] : []
  ];

  const transformers = {
    sequence: (row) => <><ReorderIcon/> {row.sequence}</>,
    duration: (row) => `${row.duration} days`,
    requires_approval: (row) => row.requires_approval ? <CheckedIcon/> : <ErrorIcon/>,
    Actions: (row, i) => {
      if(isPMOTeam){
        return (
          <>
            <div className='actions__wrap' onClick={(e) => {
              e.stopPropagation();
              setIsAddMilestoneTaskModalOpen(true);
              setCurrentMilestone(row);
            }}>
              <EditIcon/>
            </div>
            {i !== 0 && (
              <div className='actions__wrap' onClick={(e) => {
                e.stopPropagation();
                const data = getDragAndDropData(row, i,-1);
                onDragAndDrop(data)
              }}>
                <SortUpIcon/>
              </div>
            )}
            <div className='actions__wrap actions__down' onClick={(e) => {
              e.stopPropagation();
              const data = getDragAndDropData(row, i, 1);
              onDragAndDrop(data)
            }}>
              <SortDownIcon/>
            </div>
            {!row.parent_id && (
              <div className='actions__wrap' onClick={(e) => {
                e.stopPropagation();
                setIsAddMilestoneTaskModalOpen(true);
                setParentMilestone(row.task_id);
              }}>
                <AddIcon/>
              </div>
            )}
            {row.is_deletable && (
              <div className='actions__wrap' onClick={(e) => {
                e.stopPropagation();
                setIsConfirmModalOpen(true);
                setCurrentMilestone(row);
              }}>
                <DeleteIcon/>
              </div>
            )}
          </>
        )
      }
    }

  }

  const deleteMilestone = () => {
    dispatch({
      type: SAVE_MILESTONE_TASK_REQUEST,
      payload: {id: currentMilestone.task_id, milestone_template_id: ProjectDetails.id, parent_id: currentMilestone.parent_id, is_deleted: true},
      callback: () => setCurrentMilestone(null)
    })
  }

  const getDragAndDropData = (row, i, sign) => {
    return {
      draggableId: row.task_id,
      type: !row.parent_id ? 'PARENT' : 'DEFAULT',
      source: {index: i, droppableId: row.parent_id},
      destination: {index: i + sign, droppableId: row.parent_id},
    }
  }

  const onDragAndDrop = result => {
    const {draggableId, type, source, destination} = result;
    const data = {
      id: +draggableId,
      source_index: source.index,
      destination_index: destination.index,
    }
    if(type === "DEFAULT"){
      data.prev_parent = +source.droppableId;
      data.new_parent = +destination.droppableId;
    }

    dispatch({
      type: REARRANGE_MILESTONE_TASK_REQUEST,
      payload: data,
      milestone_template_id: id
    })
  }

  return (
    <>
      <div className='ProjectTemplate'>
        {!loading
          ? <>
            <div className="page-title-wrap">
              <div className="page-title-left">
                <Link to='/pdt/project-templates' className='back-btn'>
                  <ArrowLeftIcon/>
                </Link>
                <h1 className='page-title'>{ProjectDetails.name}</h1>
              </div>
            </div>
            <Table
              data={tableData}
              options={options}
              headers={headCells}
              onRequireList={handleRequestSort}
              link={true}
              fixedHeader
              transformers={transformers}
              onDragAndDrop={onDragAndDrop}
              extraFilters={
                isPMOTeam && (
                  <Button
                    type="button"
                    color={BUTTON_COLORS.DEFAULT}
                    title='Add milestone'
                    style={{marginRight: 10 }}
                    onClick={() => setIsAddMilestoneTaskModalOpen(true)}
                  />
                )
              }
            />
          </>
          : getSkeleton()
        }
      </div>

      {isAddMilestoneTaskModalOpen && (
        <AddMilestoneTaskModal
          onBackgroundClick={() => {
            setIsAddMilestoneTaskModalOpen(false);
            setCurrentMilestone(null);
            setParentMilestone(null);
          }}
          currentMilestone={currentMilestone}
          parentMilestone={parentMilestone}
        />
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          onBackgroundClick={() => {
            setCurrentMilestone(null);
            setIsConfirmModalOpen(false);
          }}
          open={isConfirmModalOpen}
          onSubmitClick={deleteMilestone}
          title='Are you sure'
          subTitle='This action is permanent and can’t be undone'
          withOutRemarks
        />
      )}
    </>
  );
};

export default ProjectTemplate;
