import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import React from "react";
import {SET_NOTIFICATION_MESSAGE} from "store/actions/notification";
import Slide from "@mui/material/Slide";

const Notification = () => {

  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);
  const {snackbarMessage, snackbarSeverity, openSnackbar} = notification;

  const closeSnackbar = () => {
    dispatch({
      type: SET_NOTIFICATION_MESSAGE,
      payload: {
        snackbarMessage: '',
        snackbarSeverity: 'info',
        openSnackbar: false
      }
    });
  }

  return (
    <Snackbar
      open={openSnackbar}
      onClose={closeSnackbar}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      autoHideDuration={3000}
      TransitionComponent={Slide}
    >
      <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
