import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {SAVE_TASK_REQUEST} from "store/actions/project";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import './DateRangeCalendarField.scss';
import Modal from "@mui/material/Modal";

const DateRangeCalendarField = ({onClose, openedDatePicker, activeMilestone, setActiveMilestone, editMode, editModeFunc}) => {

  const params = useParams();
  const {id} = params;
  const dispatch = useDispatch();
  const {row, fieldName, anchorEl} = openedDatePicker;
  const getInitialDate = (field) => (!row?.[`new_${field}`] && !row?.[field]) ? null : new Date(editMode ? row?.[`new_${field}`] || row?.[field] : row?.[field]);
  const [value, setValue] = useState({
    start: getInitialDate(`${fieldName}_start_date`),
    end: getInitialDate(`${fieldName}_end_date`),
  });

  useEffect(() => {
    setTimeout(() => {
      if(value.start && !value.end){
        document.querySelector('.selected')?.click();
      }
    }, 0)
  }, []); // eslint-disable-line

  const [focusedDate, setFocusedDate] = useState();

  const handleSubmit = () => {
    if(editMode){
      editModeFunc(value);
    }else {
      dispatch({
        type: SAVE_TASK_REQUEST,
        payload: {
          id,
          body: [{
            id: row.task_id,
            [`${fieldName}_start_date`]: value.start,
            [`${fieldName}_end_date`]: value.end,
            // [field]: value
          }
          ]        },
        callback: () => setActiveMilestone && setActiveMilestone({
          ...activeMilestone,
          [`${fieldName}_start_date`]: value.start,
          [`${fieldName}_end_date`]: value.end,
          // [field]: value
        }),
      })
    }
    onClose();
  }

  const onHandleDateChange = (start, end) => {
    if (start) start.setHours((-1 * start.getTimezoneOffset()) / 60);
    if (end) end.setHours((-1 * end.getTimezoneOffset()) / 60);
    setValue({start, end});
  }

  return (
    <Modal
      open={Boolean(anchorEl)}
      onClose={onClose}
      className='DateCalendarField'
    >
      <div className='dateCalendarField__wrap'>
        <RangeDatePicker
          startDate={value.start}
          endDate={value.end}
          onChange={onHandleDateChange}
          dateFormat="DD/MM/YYYY"
          monthFormat="MMM YYYY"
          startDatePlaceholder="Start Date"
          endDatePlaceholder="End Date"
          disabled={false}
          className="dateRangePicker"
          startWeekDay="monday"
          highlightToday={true}
          onFocus={(date) => setFocusedDate(date)}
          minDate={focusedDate === 'End Date' ? value.start : null}
          isOpen="true"
        />
        <div className='calendar-btns'>
          <Button title='Cancel' onClick={onClose} color={BUTTON_COLORS.GRAY} />
          <Button title='Apply' color={BUTTON_COLORS.DEFAULT} onClick={handleSubmit} />
        </div>

      </div>
    </Modal>
  )
}

export default DateRangeCalendarField;