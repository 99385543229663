export const GET_BOARDS_TICKETS_REQUEST = "GET_BOARDS_TICKETS_REQUEST";
export const GET_BOARDS_TICKETS_SUCCESS = "GET_BOARDS_TICKETS_SUCCESS";
export const GET_BOARDS_TICKETS_FAIL = "GET_BOARDS_TICKETS_FAIL";

export const GET_MORE_BOARDS_TICKETS_REQUEST = "GET_MORE_BOARDS_TICKETS_REQUEST";
export const GET_MORE_BOARDS_TICKETS_SUCCESS = "GET_MORE_BOARDS_TICKETS_SUCCESS";
export const GET_MORE_BOARDS_TICKETS_FAIL = "GET_MORE_BOARDS_TICKETS_FAIL";

export const SORT_BOARDS_TICKETS_REQUEST = "SORT_BOARDS_TICKETS_REQUEST";
export const SORT_BOARDS_TICKETS_SUCCESS = "SORT_BOARDS_TICKETS_SUCCESS";
export const SORT_BOARDS_TICKETS_FAIL = "SORT_BOARDS_TICKETS_FAIL";

export const CREATE_TICKET_REQUEST = "CREATE_TICKET_REQUEST";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";

export const EDIT_TICKET_REQUEST = "EDIT_TICKET_REQUEST";
export const EDIT_TICKET_SUCCESS = "EDIT_TICKET_SUCCESS";
export const EDIT_TICKET_FAIL = "EDIT_TICKET_FAIL";

export const UPDATE_TICKET_REQUEST = "UPDATE_TICKET_REQUEST";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAIL = "UPDATE_TICKET_FAIL";

export const REMOVE_TICKET_REQUEST = "REMOVE_TICKET_REQUEST";
export const REMOVE_TICKET_SUCCESS = "REMOVE_TICKET_SUCCESS";
export const REMOVE_TICKET_FAIL = "REMOVE_TICKET_FAIL";

export const GET_TICKET_DETAILS_REQUEST = "GET_TICKET_DETAILS_REQUEST";
export const GET_TICKET_DETAILS_SUCCESS = "GET_TICKET_DETAILS_SUCCESS";
export const GET_TICKET_DETAILS_FAIL = "GET_TICKET_DETAILS_FAIL";

export const ADD_TICKET_REMARKS_REQUEST = "ADD_TICKET_REMARKS_REQUEST";
export const ADD_TICKET_REMARKS_SUCCESS = "ADD_TICKET_REMARKS_SUCCESS";
export const ADD_TICKET_REMARKS_FAIL = "ADD_TICKET_REMARKS_FAIL";

export const DND_TICKET = "DND_TICKET";