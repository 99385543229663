import {call, put, all, takeLatest} from "redux-saga/effects";
import * as userManagementActions from "../actions/userManagement";
import {Api} from "api";
import {SET_NOTIFICATION_MESSAGE} from "../actions/notification";

function* getUsersList(action) {
  try {
    const res = yield call(Api.userManagement.getUserList, action.payload);
    yield put({type: userManagementActions.GET_USERS_LIST_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: userManagementActions.GET_USERS_LIST_FAIL, payload: { error: err.message }});
  }
}

function* saveUser(action) {
  try {
    yield call(Api.userManagement.saveUser, action.payload);
    yield put({type: userManagementActions.SAVE_USER_SUCCESS});
    action?.callback();
    yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "User has been created", snackbarSeverity: "success", openSnackbar: true}})
    yield put({type: userManagementActions.GET_USERS_LIST_REQUEST, payload: {role: null}})
  } catch (err) {
    yield put({type: userManagementActions.SAVE_USER_FAIL, payload: { error: err.message }});
  }
}

function* resetEmail(action) {
  try {
    const res = yield call(Api.userManagement.resetEmail, action.payload);
    if(res.data.response === "failure"){
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: res.data.message, snackbarSeverity: "error", openSnackbar: true}})
    }else{
      yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: res.data.message, snackbarSeverity: "success", openSnackbar: true}})
      yield put({type: userManagementActions.RESET_EMAIL_SUCCESS})
    }
  } catch (err) {
    yield put({type: userManagementActions.RESET_EMAIL_FAIL, payload: { error: err.message }});
  }
}

function* getUserDetails(action) {
  try {
    const res = yield call(Api.userManagement.getUserDetails, action.payload);
    yield put({type: userManagementActions.GET_USER_DETAILS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({type: userManagementActions.GET_USER_DETAILS_FAIL, payload: { error: err.message }});
  }
}

function* updatePassword(action) {
  try {
    const res = yield call(Api.userManagement.updatePassword, action.payload);
    yield put({type: userManagementActions.UPDATE_PASSWORD_SUCCESS});
    yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: res.data.message, snackbarSeverity: "success", openSnackbar: true}})
    action.navigate('/', { replace: true })
  } catch (err) {
    yield put({type: userManagementActions.UPDATE_PASSWORD_FAIL, payload: { error: err.message }});
  }
}

function* toggleUserAvailability(action) {
  try {
    yield call(Api.userManagement.toggleUserAvailability, action.payload);
    yield put({type: userManagementActions.TOGGLE_USER_AVAILABILITY_SUCCESS});
    yield put({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: 'You have successfully changed user availability status', snackbarSeverity: "success", openSnackbar: true}})
  } catch (err) {
    yield put({type: userManagementActions.TOGGLE_USER_AVAILABILITY_FAIL, payload: { error: err.message }});
  }
}


export default all([
  takeLatest(userManagementActions.GET_USERS_LIST_REQUEST, getUsersList),
  takeLatest(userManagementActions.SAVE_USER_REQUEST, saveUser),
  takeLatest(userManagementActions.RESET_EMAIL_REQUEST, resetEmail),
  takeLatest(userManagementActions.GET_USER_DETAILS_REQUEST, getUserDetails),
  takeLatest(userManagementActions.UPDATE_PASSWORD_REQUEST, updatePassword),
  takeLatest(userManagementActions.TOGGLE_USER_AVAILABILITY_REQUEST, toggleUserAvailability),
]);
