import Modal from "components/Modal/Modal";
import React, {useState} from "react";
import classnames from "classnames";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {fileSize} from "helpers";
import close from "assets/icons/table/close.svg";
import MUIButton from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  PARSE_BULK_UPLOAD_REQUEST,
  CLEAR_PREVIOUS_BULK_UPLOAD,
} from "store/actions/common";
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from "../common/Skeleton";
import {CheckedIcon, ExcelIcon, InfoIcon, UploadIcon} from "../Icons";
import SummaryBulkTable from "./SummaryBulkTable";
import './BulkUpload.scss';

const BulkUpload = ({ onBackgroundClick, completeUploadAction, parseApi, template }) => {

  const [file, setFile] = useState(null);
  const userData = useSelector(state => state.auth.user);
  const parsedSummary = useSelector(state => state.common.parsedSummary);
  const loading = useSelector(state => state.common.loadingMedia);
  const dispatch = useDispatch();

  const parseData = () => {
    let formData = new FormData();
    formData.append("file", file);

    dispatch({
      type: PARSE_BULK_UPLOAD_REQUEST,
      payload: {
        auth: userData,
        body: formData,
        api: parseApi
      }
    });
  };

  const closeModal = () => {
    onBackgroundClick();
    dispatch({ type: CLEAR_PREVIOUS_BULK_UPLOAD });
    completeUploadAction()
  };

  const headCells = [
    { attr: 'unique_work_identifier', title: 'Unique Work Identifier' },
    { attr: 'customer_project_name', title: 'Customer Project Name' },
    { attr: 'customer_site_id', title: 'Customer Site Id' },
    { attr: 'error', title: 'Error' },
  ];


  return (
    <Modal
      className="full-width bulk-upload"
      onBackgroundClick={closeModal}
    >
      <div className="modal-header">
        <h5>Bulk Upload</h5>
        {parsedSummary.errors &&
        <div className="modal-header-btns">
          <Button
            IconNode={CheckedIcon}
            title='Ok'
            onClick={closeModal}
            color={BUTTON_COLORS.GRAY}
          />
        </div>
        }
      </div>
      {!parsedSummary?.errors
        ? <div className="modal-body">
          <>
            <div className={classnames("modal-body-img", {fileUploaded: file})}>
              {file ? <CheckedIcon/> : <UploadIcon/>}
            </div>
            <h2>{file ? 'File Selected!' : `Import Excel file`}</h2>
            {file &&
            <div className='parse-data-caption'>{loading ? 'Uploading data. Please wait.' : 'Upload the data to continue. Uploading process can take few minutes'}</div>
            }

            {!file && (
              <p className='upload-caption'>Upload your Excel file to continue</p>
            )}

            <div className="modal-file">
              {file && !loading && (
                <>
                  <div className="file-preview">
                    <div className="file-preview-img">
                      <ExcelIcon/>
                    </div>
                    <div className="file-preview-info">
                      {file.name}
                      <p>{fileSize(file.size)}</p>
                    </div>
                    <span onClick={() => setFile(null)}><img src={close} alt=""/></span>
                  </div>
                  <div className="file-options">
                    <MUIButton
                      component="label"
                      disableRipple
                    >
                      Change file
                      <input
                        onChange={e => setFile(e.target.files[0])}
                        type="file"
                        accept='.xls'
                        hidden
                      />
                    </MUIButton>
                  </div>
                  <Button title='Upload data' onClick={parseData}/>
                </>
              )}
              {loading && <CircularProgress color="primary" />}
              {!file && (
                <MUIButton
                  component="label"
                  disableRipple
                >
                  Import file
                  <input
                    onChange={e => setFile(e.target.files[0])}
                    type="file"
                    accept='.xls, .xlsx'
                    hidden
                  />
                </MUIButton>
              )}
            </div>
            {!loading && <div className="modal-body-bottom">
              <p>
                <InfoIcon/>
                Not sure about file structure?
                <Link
                  to={template.src}
                  target="_blank"
                  download={template.name}
                >
                  Use this template
                </Link>
              </p>
            </div>}
          </>
        </div>
        : <div className={classnames("modal-body-wrapper")}>
          <div className='modal-body'>
            {!loading
              ? <SummaryBulkTable
                data={parsedSummary}
                hasAsbuiltColumn={false}
                headCells={headCells}
                title='Upload Summary'
              />
              : <Skeleton/>
            }
          </div>
        </div>
      }
    </Modal>
  )
};

export default BulkUpload;