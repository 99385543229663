import axiosInstance from "../services/axiosInstance";

export const common = {

  uploadMedia: (data) => {
    const headers = {
      "X-Application-Bucket": data.bucket,
      "X-Application": "PDT",
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    return axiosInstance.post('media', data.body, {headers});
  },

  downloadMedia: (data) => {
    return axiosInstance.get(`media/${data.file.media_reference_id}`, {responseType: 'blob'});
  },

  getLookupList: () => {
    return axiosInstance.get(`Project/GetLookupList`);
  },

  bulkUpload: (data) => {
    return axiosInstance.post(data.api, data.body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  },

  deleteMedia: (data) => {
    return axiosInstance.delete(`media/${data.file.media_reference_id}`);
  }

};
