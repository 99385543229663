import React, {useEffect, useState} from "react";
import {InputAdornment, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import UserChip from "components/common/UserChip/UserChip";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {
  GET_PROJECT_ROLES_REQUEST,
} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";
import {DebounceInput} from "react-debounce-input";
import Switcher from "components/common/Switcher/Switcher";

const AddTask = ({setProjectModal}) => {

  const [state, setState] = useState({
    name: '',
    duration: '',
    owner: '',
    stakeholder: []
  });
  const [taskRequiresApproval, setTaskRequiresApproval] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const projectRoles = useSelector((state) => state.project.projectRoles);

  useEffect(() => {
    if(!projectRoles){
      dispatch({
        type: GET_PROJECT_ROLES_REQUEST,
        payload: {auth: userData},
      })
    }
  }, []); //eslint-disable-line


  const handleStakeholders = (id) => {
    setState({...state, stakeholder: state.stakeholder.includes(id) ? state.stakeholder.filter(item => item !== id) : [...state.stakeholder, id]});
  };

  const handleSubmit = () => {
    // dispatch({
    //   type: SAVE_TASK_STAKEHOLDER_REQUEST,
    //   payload: {
    //     auth: userData,
    //     id,
    //     body: {
    //       task_id: activeMilestone.task_id,
    //       owner_role: state.owner.role_id,
    //       stakeholder: state.stakeholder.map(item => ({role_id: item, deleted: false})),
    //     }
    //   },
    //   callback: () => setProjectModal(null)
    // })
  }

  console.log(state, 'state')

  return (
    <div className='initiation-modal'>
      <h3>Add Task</h3>

      <div className="modal-row">
        <div className="modal-field">
          <h6>Name</h6>
          <DebounceInput
            className='input-field'
            type="text"
            debounceTimeout={500}
            onChange={e => setState({...state, name: e.target.value})}
            value={state.name}
            placeholder='Enter name'
          />
        </div>
      </div>
      <div className="modal-row">
        <div className="modal-field">
          <h6>Duration</h6>
          <TextField
            className='input-field'
            onChange={e => setState({...state, duration: e.target.value})}
            value={state.duration}
            placeholder='Duration'
            InputProps={{
              endAdornment: <InputAdornment position="end">Days</InputAdornment>,
            }}
          />
        </div>
      </div>
      <div className="modal-field modal-select">
        <h6>Owner</h6>
        <Select
          value={JSON.stringify(state.owner)}
          variant="outlined"
          onChange={e => setState({...state, owner: JSON.parse(e.target.value)})}
          displayEmpty={true}
        >
          <MenuItem className='hiddenOption' key="0" disabled value="none" >Select owner</MenuItem>
          {projectRoles?.owner.map(item =>
            <MenuItem key={item.role_id} value={JSON.stringify(item)}>{item.role_name}</MenuItem>
          )}
        </Select>
      </div>

      <div className="modal-field stakeholders">
        <h6>Stakeholders ({state.stakeholder.length})</h6>
        <div className='stakeholders__items'>
          {projectRoles?.stakeholder.map((item, i) =>
            <UserChip
              key={i}
              checked={state.stakeholder.includes(item.role_id)}
              title={item.role_name}
              onClick={() => handleStakeholders(item.role_id)}
            />
          )}
        </div>
      </div>
      <div className="modal-row">
        <h5>Approval permissions (optional)</h5>
        <Switcher
          label='Task requires approval'
          checked={taskRequiresApproval}
          onChange={() => setTaskRequiresApproval(!taskRequiresApproval)}
        />
        {taskRequiresApproval && (
          <div className="modal-field modal-select">
            <h6>Approver</h6>
            <Select
              value={JSON.stringify(state.owner)}
              variant="outlined"
              onChange={e => setState({...state, owner: JSON.parse(e.target.value)})}
              displayEmpty={true}
            >
              <MenuItem className='hiddenOption' key="0" disabled value="none" >Select owner</MenuItem>
              {projectRoles?.owner.map(item =>
                <MenuItem key={item.role_id} value={JSON.stringify(item)}>{item.role_name}</MenuItem>
              )}
            </Select>
          </div>
        )}

      </div>
      <div className='modal-btns'>
        <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal(null)}/>
        <Button title='Add task' color={BUTTON_COLORS.DEFAULT} onClick={handleSubmit}/>
      </div>
    </div>
  );
};

export default AddTask;
