import React from 'react';
import classnames from 'classnames';
import './Button.scss';

export const BUTTON_COLORS = {
  DEFAULT: 0,
  GRAY: 1,
  TRANSPARENT: 2,
  FULLY_TRANSPARENT: 3,
  GREEN: 4,
  RED: 5,
  ORANGE: 6,
  PRIMARY: 7,
  DARK: 8,
};

const Button = ({
  title, color, icon, IconNode, IconNodeStyles, EndIcon, EndIconStyles,
  onClick, active, disabled, className, ...props
}) => {
  return (
    <button
      className={classnames("button", `button-color-${color || BUTTON_COLORS.DEFAULT}`, className, {
        active: active,
        disabled: disabled,
      })}
      onClick={onClick}
      {...props}
    >
      {icon && <img src={icon} alt={'icon'} />}
      {IconNode && <IconNode style={IconNodeStyles} />}
      {title && <p>{title}</p>}
      {EndIcon && <EndIcon style={{ marginLeft: 10, width: 18, position: 'relative', bottom: -1, ...EndIconStyles }} />}
    </button>
  );
}

export default Button;