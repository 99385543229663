import {CloseIcon} from "components/Icons";

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const descendingComparator = (a, b, orderBy) =>
  b[orderBy] < a[orderBy] ? -1 : 1;

export const getComparator = (order, orderBy) => {
  return order === "DESC"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const tableFilter = (arr, filters, searchValue) => {
  return arr.filter(item => !searchValue || Object.values(item).some(el => el?.toString().toLowerCase().includes(searchValue)))
    .filter(el => Object.keys(filters).every(item => el[item]?.toString().toLowerCase().includes(filters[item].toLowerCase())))
};

export const stableSort = (array = [], comparator, filters, searchValue) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return tableFilter(stabilizedThis.map((el) => el[0]), filters, searchValue)
};

export const getStatusClassname = (status) => {
  switch (status) {
    case '':
    case false:
    case 'Rejected':
    case 'Canceled':
      return 'error';
    case true:
      return 'default';
    case 'In progress':
      return 'in-progress';
    case 'Completed':
    case 'Approved':
      return 'success'
    case 'Pending':
      return 'pending'
    case 'On hold':
      return 'on-hold'
    case 'Waiting':
      return 'waiting'
    default:
      return 'default'
  }
}

export const getHumanDate = (date) => {
  return date && new Date(date).toLocaleString('en-GB', {day: 'numeric', month: 'numeric', year: 'numeric'})
}

export const fileSize = size => {
  if (!size) return '0 KB';
  const i = Math.floor(Math.log(size) / Math.log(1000));
  return (size / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

export const dataLabelPlugin = {
  afterDraw: (chart) => {
    const {
      ctx,
      data: {
        datasets
      },
      _metasets
    } = chart;

    datasets[0].data.forEach((item, i) => {
      let barValue = `$${(datasets[0].data[i] + item) / 2}`;
      ctx.textAlign = 'center';
      ctx.fillText(barValue, _metasets[0].data[i].x, (_metasets[0].data[i].y - 5), _metasets[0].data[i].width);
    });
  }
}

export const moveToAnotherColumn = (
  tickets,
  startColumn,
  startColumnTicketIndex,
  finishColumn,
  finishColumnTicketIndex
) => {
  const startTickets = [...tickets[startColumn]];
  startTickets.splice(startColumnTicketIndex, 1);

  const finishTickets = [...tickets[finishColumn]];
  finishTickets.splice(
    finishColumnTicketIndex,
    0,
    tickets[startColumn][startColumnTicketIndex]
  );

  return {
    ...tickets,
    [startColumn]: startTickets,
    [finishColumn]: finishTickets,
  };
};

export const getRequiredTicketFields = data => {
  return (({id, status, description, category, priority, due_date, start_date, assigned_to, media, project_id, task_id}) => ({id, status, description, category, priority, due_date, start_date, assigned_to, media, project_id, task_id}))(data)
}

export const convertMilliseconds = (milliseconds) => {

  const millisecondsInMinute = 60 * 1000;
  const millisecondsInHour = 60 * millisecondsInMinute;
  const millisecondsInDay = 24 * millisecondsInHour;
  const millisecondsInYear = 365.25 * millisecondsInDay;

  const years = Math.floor(milliseconds / millisecondsInYear);
  milliseconds %= millisecondsInYear;

  const days = Math.floor(milliseconds / millisecondsInDay);
  milliseconds %= millisecondsInDay;

  const hours = Math.floor(milliseconds / millisecondsInHour);
  milliseconds %= millisecondsInHour;

  const minutes = Math.floor(milliseconds / millisecondsInMinute);

  const timeValues = [];

  if (years > 0) {
    timeValues.push(`${years}y`);
  }

  if (days > 0) {
    timeValues.push(`${days}d`);
  }

  if (hours > 0 && minutes > 0) {
    return `${hours}h`;
  }

  if (hours > 0) {
    timeValues.push(`${hours}h`);
  }

  if (minutes > 0) {
    timeValues.push(`${minutes}m`);
  }

  if (timeValues.length >= 2) {
    return timeValues.slice(0, 2).join(' ');
  } else {
    return timeValues.join(', ');
  }
}

export const doughnutTextPlugin = {
  beforeDraw: function(chart) {
    const width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    const fontSize = (height / 120).toFixed(2);
    ctx.font = fontSize + "em sans-serif";
    ctx.textBaseline = "top";
    const text = chart.config.data.datasets[0].label,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;
    ctx.fillText(text, textX, textY);
    ctx.save();
  }
}

export const debounce = (func, wait = 500) => {
  let timeout;
  function debounced(...args) {
    const later = () => {
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export const getValueAfterDot = (value) => {
  const parts = value.toString().split('.');
  if (parts.length > 1) {
    return parts[1];
  } else {
    return parts[0];
  }
}

export const getClearableSelectIcon = (field, func, withOutArrow) => (
  field
    ? <>
        <span className='select-clearBtn' onClick={func}>
          <CloseIcon/>
        </span>
        {!withOutArrow && <svg className='MuiSelect-icon'/>}
      </>
    : !withOutArrow ? <svg className='MuiSelect-icon'/> : <></>
)