export const COLUMN_TITLES = [
  {key: "New", title: "New"},
  {key: "Assigned", title: "Assigned"},
  {key: "In Progress", title: "In Progress"},
  {key: "On Hold", title: "On Hold"},
  {key: "Resolved", title: "Resolved"},
  {key: "Closed", title: "Closed"},
  {key: "Reopened", title: "Reopened"}
];

export const BOARD_TICKETS = {
  "New": [],
  "Assigned": [],
  "In Progress": [],
  "On Hold": [],
  "Resolved": [],
  "Closed": [],
  "Reopened": [],
};

export const SEVERITY_OPTIONS = ['Minor', 'Intermediate', 'Major'];

export const MENU_PROPS ={
  anchorOrigin: {
    vertical: "bottom",
      horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
      horizontal: "left"
  },
}