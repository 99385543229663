import React from 'react';
import {MapPinIcon} from "components/Icons";

const Marker = () => {
  return (
    <div className="marker">
      <MapPinIcon />
    </div>
  );
};

export default Marker;