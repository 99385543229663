import React, {useEffect, useState} from 'react';
import {Table} from "components/Table";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import Skeleton from "components/common/Skeleton";
import InitiateProjectModal from "./InitiateProjectModal";
import {useDispatch, useSelector} from "react-redux";
import useTable from "hooks/useTable";
import {DELETE_SITES_REQUEST, SITE_LIST_REQUEST} from "store/actions/site";
import {getHumanDate} from "helpers";
import './Sites.scss';
import BulkUpload from "components/BulkUpload/BulkUpload";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import {useNavigate} from "react-router-dom";
import template from 'assets/templates/sitesTemplate.xlsx';

const headCells = [
  {attr: "customer_site_id", title: "Customer Site ID"},
  {attr: "tawal_id", title: "TAWAL ID"},
  {attr: "site_priority", title: "Site Priority"},
  {attr: "work_type", title: "Col/BTS"},
  {attr: "technology", title: "Technology"},
  {attr: "region", title: "Region"},
  {attr: "district", title: "District"},
  {attr: "city", title: "City"},
  {attr: "latitude", title: "Latitude"},
  {attr: "longitude", title: "Longitude"},
  {attr: "stc_telecom_footprint", title: "STC Telecom Foot print"},
  {attr: "po_owner", title: "PO Owner"},
  {attr: "site_ownership", title: "Site Ownership"},
  {attr: "site_type", title: "Site Type"},
  {attr: "tower_type", title: "Tower Type"},
  {attr: "tower_height", title: "Tower Height"},
  {attr: "olo_number", title: "OLO Number"},
  {attr: "olo_date", title: "OLO Date"},
  {attr: "srs_number_tms", title: "SR Number (TMS)"},
  {attr: "so_number_tms", title: "SO Number (TMS)"},
  {attr: "scope_type", title: "In/Out Scope"},
  {attr: "power_type", title: "Power Type"},
  {attr: "power_upgrade_required", title: "Power Upgrade Required"},
  {attr: "sec_status", title: "SEC Status"},
  {attr: "smart_sho", title: "Smart SHO"},
  {attr: "design_contractor", title: "Design Contractor"},
  {attr: "fded_code", title: "FDED Code (A/B)"},
  {attr: "implementation_contractor", title: "Implementation Contractor"},
];


const Sites = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isInitiateProjectModalOpen, setIsInitiateProjectModalOpen] = useState(false);
  const loading = useSelector((state) => state.site.loading);
  const siteList = useSelector((state) => state.site.siteList);
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const userData = useSelector(state => state.auth.user);
  const isPMOTeam = userData.role === 'PMO';
  const [selectedSites, setSelectedSites] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const {tableData, handleRequestSort} = useTable(siteList);

  useEffect(() => {
    dispatch({type: SITE_LIST_REQUEST});
  }, [dispatch]);


  const options = {
    active_milestone: {
      styles: {minWidth: "300px"},
    },
  };

  const transformers = {
    olo_date: (row) => getHumanDate(row.olo_date),
    power_upgrade_required: (row) => row.power_upgrade_required ? 'Y' : 'N',
    smart_sho: (row) => row.smart_sho ? 'Y' : 'N',
  };


  const handleCheckboxChange = (selectedRows) => {
    setSelectedSites(selectedRows);
  }

  const deleteSites = () => {
    dispatch({
      type: DELETE_SITES_REQUEST,
      payload: selectedSites.map(item => item.id),
      callback: () => setSelectedSites([])
    })
  }


  return (
    <div className='sites list-table-page'>

      <div className='sites__header'>
        <h1 className='page-title'>Sites</h1>
        {isPMOTeam && (
          <Button
            color={BUTTON_COLORS.GRAY}
            title="Bulk Upload"
            onClick={() => setIsBulkUploadOpen(true)}
          />
        )}
      </div>
      {!loading
        ? <Table
          title='Sites'
          data={tableData}
          options={options}
          headers={headCells}
          onRequireList={handleRequestSort}
          userData={{}}
          link='/pdt/sites'
          fixedHeader
          transformers={transformers}
          onCheckboxChange={isPMOTeam ? handleCheckboxChange : undefined}
          extraFilters={selectedSites.length > 0 ? (
            <>
              <Button
                type="button"
                color={BUTTON_COLORS.RED}
                title="Remove selected"
                style={{ marginLeft: 'auto', marginRight: 10 }}
                onClick={() => setIsConfirmModalOpen(true)}
              />
              <Button
                type="button"
                color={BUTTON_COLORS.DEFAULT}
                title="Initiate selected"
                style={{ marginRight: 10 }}
                onClick={() => setIsInitiateProjectModalOpen(true)}
              />
            </>
          ) : null}
        />
        : <Skeleton/>
      }
      {isInitiateProjectModalOpen && (
        <InitiateProjectModal
          onBackgroundClick={() => setIsInitiateProjectModalOpen(false)}
          successCallback={(name) => {
            setIsInitiateProjectModalOpen(false);
            navigate(`/pdt/activities-list/${name}`, { replace: true });
          }}
          initiateProjects={selectedSites}
        />
      )}
      {isBulkUploadOpen && (
        <BulkUpload
          onBackgroundClick={() => setIsBulkUploadOpen(false)}
          completeUploadAction={() => dispatch({type: SITE_LIST_REQUEST})}
          parseApi='Project/SiteBulkUpload'
          template={{src: template, name: 'pdt sites upload'}}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          onBackgroundClick={() => setIsConfirmModalOpen(false)}
          open={isConfirmModalOpen}
          onSubmitClick={deleteSites}
          title='Are you sure'
          subTitle='This action is permanent and can’t be undone'
          withOutRemarks
        />
      )}

    </div>
  );
};

export default Sites;