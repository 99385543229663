import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Activities.scss";
import {Table} from "components/Table";
import {DELETE_ACTIVITIES_REQUEST, EDIT_ACTIVITIES, PROJECT_LIST_REQUEST} from "store/actions/project";
import Skeleton from "components/common/Skeleton";
import useTable from "hooks/useTable";
import {getHumanDate} from "../../helpers";
import BulkUpload from "components/BulkUpload/BulkUpload";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {IconButton, Select} from "@mui/material";
import {CloseIcon} from "components/Icons";
import classNames from "classnames";
import {DebounceInput} from "react-debounce-input";
import MenuItem from "@mui/material/MenuItem";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import ReassignModal from "./components/ReassignModal";
import {useParams} from "react-router-dom";
import template from 'assets/templates/activitiesTemplate.xlsx';

const Activities = () => {

  const params = useParams();
  const {id} = params;
  const dispatch = useDispatch();
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedActivities, setEditedActivities] = useState([]);
  const projectList = useSelector((state) => state.project.projectList);
  const loading = useSelector((state) => state.project.loading);
  const {tableData, handleRequestSort} = useTable(projectList.data);
  const lookup = useSelector((state) => state.common.lookup);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const userData = useSelector(state => state.auth.user);
  const isPMOTeam = userData.role === 'PMO';

  useEffect(() => {
    dispatch({type: PROJECT_LIST_REQUEST, payload: {id}});
  }, [dispatch, id]);

  const transformers = {
    division: (row) => editMode ? textField(row, 'division') : row.division,
    sho_actual_end_date: (row) => editMode ? dateField(row, 'sho_actual_end_date') : getHumanDate(row.sho_actual_end_date),
    mop_actual_end_date: (row) => getHumanDate(row.mop_actual_end_date),
    sow_actual_end_date: (row) => getHumanDate(row.sow_actual_end_date),
    start_date: (row) => getHumanDate(row.start_date),
    wo_actual_end_date: (row) => getHumanDate(row.wo_actual_end_date),
    civil_readiness_actual_end_date: (row) => getHumanDate(row.civil_readiness_actual_end_date),
    tkwo_actual_end_date: (row) => getHumanDate(row.tkwo_actual_end_date),
    fttm_Implementation_actual_end_date: (row) => getHumanDate(row.fttm_Implementation_actual_end_date),
    asbuilt_actual_end_date: (row) => getHumanDate(row.asbuilt_actual_end_date),
    patch_olt_actual_end_date: (row) => getHumanDate(row.patch_olt_actual_end_date),
    fixed_access_integration_actual_end_date: (row) => getHumanDate(row.fixed_access_integration_actual_end_date),
    pat_and_handover_actual_end_date: (row) => getHumanDate(row.pat_and_handover_actual_end_date),
    final_asbuilt_actual_end_date: (row) => getHumanDate(row.final_asbuilt_actual_end_date),
    oil_clearance_actual_end_date: (row) => getHumanDate(row.oil_clearance_actual_end_date),
    site_type: (row) => editMode ? selectField(row, 'site_type', 'Site Type') : row.site_type,
    ...projectList.columns.reduce((acc, item) => {
      if(item.type === 'date'){
        acc[item.attr] = (row) => getHumanDate(row[item.attr])
      }
      return acc;
    }, {})
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedActivities(selectedRows);
  }

  const findAndChange = (rowId, updatedField) => {
    return projectList.data.map(item => {
      if (item.id === rowId) {
        return { ...item, ...updatedField };
      }
      return item;
    });
  }

  const onChange = (rowId, field, value) => {
    dispatch({type: EDIT_ACTIVITIES, payload: findAndChange(rowId, {[`new_${field}`]: value})});
    const index = editedActivities.findIndex(item => rowId === item.id);
    if(index !== -1){
      setEditedActivities([...editedActivities.slice(0, index), {...editedActivities[index], [field]: value}, ...editedActivities.slice(index + 1)])
    }else {
      setEditedActivities([...editedActivities, {id: rowId, [field]: value}]);
    }
  }

  const deleteActivities = () => {
    dispatch({
      type: DELETE_ACTIVITIES_REQUEST,
      payload: selectedActivities.map(item => item.id),
      callback: () => setSelectedActivities([])
    })
  }

  const dateField = (row, field) => {
    return (
      <DatePicker
        value={row[`new_${field}`] || row[field] ? new Date(row[`new_${field}`] || row[field]) : null}
        onChange={value => onChange(row.id, field, value)}
        format="dd/MM/yyyy"
        disableOpenPicker={!row[field]}
        className={classNames('editable-field', {edited: row[`new_${field}`] && row[`new_${field}`] !== row[field]})}
        slots={{
          openPickerButton: row[`new_${field}`] || row[field]
            ? () => <IconButton onClick={() => onChange(row.id, field, null)} edge="end"><CloseIcon/></IconButton>
            : undefined,
        }}
      />
    )

  }

  // const dateDebounceField = (row, field) => (
  //   <DateDebounceField
  //     value={row[`new_${field}`] || row[field] ? new Date(row[`new_${field}`] || row[field]) : null}
  //     className={classNames('editable-field', {edited: row[`new_${field}`] && row[`new_${field}`] !== row[field]})}
  //     onAccept={value => onChange(row.id, field, value)}
  //   />
  // )

  const textField = (row, field) => (
    <div
      className={classNames('editable-field', {edited: row[`new_${field}`] && row[`new_${field}`] !== row[field]})}
    >
      <DebounceInput
        type="text"
        debounceTimeout={500}
        onChange={e => onChange(row.id, field, e.target.value)}
        value={row[`new_${field}`] || row[field]}
      />

    </div>
  );

  const selectField = (row, field, lookupField) => (
    <Select
      value={row[`new_${field}`] || row[field] || 'none'}
      variant="outlined"
      onChange={e => onChange(row.id, field, e.target.value)}
      className={classNames('editable-field', {edited: row[`new_${field}`] && row[`new_${field}`] !== row[field]})}
    >
      {lookup?.[lookupField]?.map(item =>
        <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
      )}
    </Select>
  )

  return (
    <div className='Projects list-table-page'>
      <div className='sites__header'>
        <h1 className='page-title'>Activities - {id}</h1>
        {isPMOTeam && (
          <Button
            color={BUTTON_COLORS.GRAY}
            title="Bulk Upload"
            onClick={() => setIsBulkUploadOpen(true)}
          />
        )}
      </div>
      {!loading
        ? <Table
          title='Activities'
          data={tableData}
          transformers={transformers}
          headers={projectList.columns}
          onRequireList={handleRequestSort}
          link={!editMode && '/pdt/activities'}
          fixedHeader
          onCheckboxChange={!editMode && userData.role === 'PMO' ? handleCheckboxChange : null}
          extraFilters={(
            <>
              {!editMode && !!selectedActivities.length && (
                <>
                  <Button
                    type="button"
                    color={BUTTON_COLORS.RED}
                    title="Remove selected"
                    style={{ marginLeft: 'auto', marginRight: 10 }}
                    onClick={() => setIsConfirmModalOpen(true)}
                  />
                  <Button
                    type="button"
                    color={BUTTON_COLORS.DEFAULT}
                    title="Re-assign selected"
                    style={{ marginRight: 10 }}
                    onClick={() => setIsReassignModalOpen(true)}
                  />
                </>
              )}
              {isPMOTeam && (
                <>
                  <Button
                    type="button"
                    color={BUTTON_COLORS.DEFAULT}
                    title={`${editMode ? 'Review' : 'Edit'} mode`}
                    style={{ marginRight: 10 }}
                    onClick={() => setEditMode(!editMode)}
                  />
                  {!!editedActivities.length && editMode && (
                    <Button
                      type="button"
                      color={BUTTON_COLORS.GREEN}
                      title='Save'
                      style={{marginRight: 10 }}
                    />
                  )}
                </>
              )}
            </>
          )}
        />
        : <Skeleton/>
      }
      {isBulkUploadOpen && (
        <BulkUpload
          onBackgroundClick={() => setIsBulkUploadOpen(false)}
          completeUploadAction={() => dispatch({type: PROJECT_LIST_REQUEST, payload: {id}})}
          parseApi='Project/ActivityBulkUpload'
          template={{src: template, name: 'pdt activity template'}}
        />
      )}

      {isReassignModalOpen && (
        <ReassignModal
          onBackgroundClick={() => setIsReassignModalOpen(false)}
          successCallback={() => {
            setIsReassignModalOpen(false);
            setSelectedActivities([]);
            dispatch({type: PROJECT_LIST_REQUEST, payload: {id}});
          }}
          selectedActivities={selectedActivities}
        />
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          onBackgroundClick={() => setIsConfirmModalOpen(false)}
          open={isConfirmModalOpen}
          onSubmitClick={deleteActivities}
          title='Are you sure'
          subTitle='This action is permanent and can’t be undone'
          withOutRemarks
        />
      )}
    </div>
  );
};

export default Activities;