export const SITE_LIST_REQUEST = "SITE_LIST_REQUEST";
export const SITE_LIST_SUCCESS = "SITE_LIST_SUCCESS";
export const SITE_LIST_FAIL = "SITE_LIST_FAIL";

export const SITE_DETAILS_REQUEST = "SITE_DETAILS_REQUEST";
export const SITE_DETAILS_SUCCESS = "SITE_DETAILS_SUCCESS";
export const SITE_DETAILS_FAIL = "SITE_DETAILS_FAIL";

export const CREATE_ACTIVITY_BULK_REQUEST = "CREATE_ACTIVITY_BULK_REQUEST";
export const CREATE_ACTIVITY_BULK_SUCCESS = "CREATE_ACTIVITY_BULK_SUCCESS";
export const CREATE_ACTIVITY_BULK_FAIL = "CREATE_ACTIVITY_BULK_FAIL";

export const DELETE_SITES_REQUEST = "DELETE_SITES_REQUEST";
export const DELETE_SITES_SUCCESS = "DELETE_SITES_SUCCESS";
export const DELETE_SITES_FAIL = "DELETE_SITES_FAIL";