import axiosInstance from "../services/axiosInstance";

export const dashboard = {

  getAgingData: () => {
    return axiosInstance.get('Project/GetAgingData');
  },

  getDashboardSummary: () => {
    return axiosInstance.get('Project/GetDashboardSummary');
  },

};
