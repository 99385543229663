import React from 'react';
import {CollapsePanelIcon} from "components/Icons";

const ProjectHeader = ({data, toggleSidebar}) => {
  return (
    <>
      <div className='top-tab'>
        <div className='top-tab__left'>
          <h1>{data.unique_work_identifier}</h1>
        </div>
        <div className='top-tab__right'>
          <div onClick={toggleSidebar} className='collapse-btn'>
            <CollapsePanelIcon/>
          </div>
        {/*  <div className='update-time'>*/}
        {/*    <span className='last-update'>Last update</span> 2:25 (51*/}
        {/*    minutes ago)*/}
        {/*    <span className='in-progress'>In progress - 5%</span>*/}
        {/*  </div>*/}
        {/*  <div className='action-btn'>*/}
        {/*    <SettingsIcon/>*/}
        {/*    <MoreIcon/>*/}
        {/*  </div>*/}
        </div>
      </div>
      <div className='second-tab'>
        <div className='second-tab__first-row'>
          <div>
            <span className='title'>Activity project name:</span>{data.activity_project_name}
            <span className='title'>Customer project name:</span>{data.customer_project_name}
            <span className='title'>Cluster:</span> {data.cluster}
            <span className='title'>Site Category:</span> {data.site_category}
            <span className='title'>Project name:</span> {data.project_name}
            <span className='title'>City:</span> {data.city}
            <span className='title'>Project Manager:</span> {data.pm_user_name}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectHeader;