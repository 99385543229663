import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL, AUTH_LOGOUT_SUCCESS,
} from "../actions/auth";

const initialState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  username: "",
  notifications: {},
  loading: false,
  error: ''
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: ''
      };

    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        user: null
      };

    default:
      return state;
  }
}
