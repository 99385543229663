import {
  GET_AGING_DATA_FAIL,
  GET_AGING_DATA_REQUEST,
  GET_AGING_DATA_SUCCESS, GET_DASHBOARD_SUMMARY_FAIL,
  GET_DASHBOARD_SUMMARY_REQUEST, GET_DASHBOARD_SUMMARY_SUCCESS
} from "../actions/dashboard";

const initialState = {
  agingData: {
    loading: true,
    chart: [],
  },
  dashboardSummary: {},
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_AGING_DATA_REQUEST:
      return {
        ...state,
        agingData: {
          ...state.agingData,
          loading: true
        },
      };
    case GET_AGING_DATA_SUCCESS:
      return {
        ...state,
        agingData: {
          loading: false,
          ...action.payload
        },

      };
    case GET_AGING_DATA_FAIL:
      return {
        ...state,
        agingData: {
          ...state.agingData,
          loading: false,
        },
      };
    case GET_DASHBOARD_SUMMARY_REQUEST:
      return {
        ...state,
        dashboardSummary: {
          ...state.dashboardSummary,
          loading: true
        },
      };
    case GET_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        dashboardSummary: {
          loading: false,
          ...action.payload
        },

      };
    case GET_DASHBOARD_SUMMARY_FAIL:
      return {
        ...state,
        dashboardSummary: {
          ...state.dashboardSummary,
          loading: false,
        },
      };

    default:
      return state;
  }
}
