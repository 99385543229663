import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./SideNav.scss";
import {
  ChangelogIcon,
  ChatIcon, CloseIcon,
  DocumentsIcon,
  InfoIcon, IssuesIcon
} from "components/Icons";
import Changelog from "../Changelog/Changelog";
import classnames from "classnames";

const links = [
  {title: 'General information', type: 'general', icon: <InfoIcon/>},
  {title: 'Documents', type: 'documents', icon: <DocumentsIcon/>},
  {title: 'Issues', type: 'issues', icon: <IssuesIcon/>},
]

const SideNav = ({ data, openedAdditionalInfo, setOpenedAdditionalInfo }) => {
  const location = useLocation();
  const checkActiveLink = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <div className='side-nav-section'>
      <div className='menu-list'>
        <div className='links'>
          <NavLink
            to='/chat'
            className={`link has-icon ${
              checkActiveLink("chat") ? "menu-link-active" : ""
            }`}>
            <ChatIcon/>
            <span>Project chat</span>
          </NavLink>

          <span className='link-subtitle'>Project Details</span>

          <ul>
            {links.map((item, i ) =>
              <li key={i} className={classnames('link has-icon', {'menu-link-active': openedAdditionalInfo === item.type})}>
                <div className='link__wrap' onClick={() => setOpenedAdditionalInfo(item.type)}>
                  {item.icon}
                  <span>{item.title}</span>
                </div>
                {openedAdditionalInfo === item.type && (
                  <div onClick={() => setOpenedAdditionalInfo(null)} style={{cursor: 'pointer'}}>
                    <CloseIcon/>
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className='menu-list'>
        <span className='link-divider' />
        <div className='links'>
          <div className="link has-icon">
            <ChangelogIcon/>
            <span>Changelog</span>
          </div>
        </div>
      </div>

      <Changelog data={data}/>
    </div>
  );
};

export default SideNav;
