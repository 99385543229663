import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-down.svg";
import {useDispatch, useSelector} from "react-redux";
import "./Header.scss";
import {LogoIcon} from "../Icons";
import {AUTH_LOGOUT_REQUEST} from "../../store/actions/auth";
import {useNavigate} from "react-router-dom";

const Header = () => {

  const navigate = useNavigate();
  const [anchorLogout, setAnchorLogout] = useState(null);
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch({type: AUTH_LOGOUT_REQUEST, navigate});
  };

  return (
    <header className='header'>
      <div className="section left">
        <div className='logo'>
          <a href={"/#/home"}>
            <LogoIcon/>
          </a>
        </div>
        <h2>Project Delivery Tool</h2>
      </div>
      <div className='section right'>
        <div
          className={`dropdown ${Boolean(anchorLogout) ? 'dropdownOpen' : ''}`}
          onClick={(e) => setAnchorLogout(e.currentTarget)}
        >
          <div className='name-holder'>
            <p className='big'>{userData.user?.user_name}</p>
            <p className='small'>{userData.user?.role}</p>
          </div>
          <ChevronIcon />
        </div>
        <Menu
          className='menu'
          anchorEl={anchorLogout}
          open={Boolean(anchorLogout)}
          onClose={() => setAnchorLogout(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}>
          <MenuItem>
            <a href={"/#/home"}>Home page</a>
          </MenuItem>
          <MenuItem onClick={logoutUser}>Log out</MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
