import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {SITE_DETAILS_REQUEST} from "store/actions/site";
import InitiateProjectModal from "../Sites/InitiateProjectModal";
import Loader from "components/common/Loader";
import SiteGeneralInfo from "./components/SiteGeneralInfo/SiteGeneralInfo";


const Site = () => {
  const params = useParams();

  const {id} = params;
  const dispatch = useDispatch();
  const siteDetails = useSelector((state) => state.site.siteDetails);
  const loading = useSelector((state) => state.site.loading);
  const [initiateProjects, setInitiateProjects] = useState(null);
  const userData = useSelector(state => state.auth.user);
  const isPMOTeam = userData.role === 'PMO';

  useEffect(() => {
    dispatch({
      type: SITE_DETAILS_REQUEST,
      payload: {
        id,
      },
    });
  }, [id, dispatch]);

  const getSkeleton = () => {
    return (
      <Loader height={670} width='100%'>
        <rect x='0' y={0} rx='4' ry='4' width='10%' height='40px'/>
        <rect x='70%' y={0} rx='4' ry='4' width='30%' height='40px'/>

        <rect x='0' y={44} rx='4' ry='4' width='100%' height='80px'/>

        <rect x='0' y={136} rx='4' ry='4' width='32%' height='30px'/>
        <rect x='0' y={168} rx='4' ry='4' width='40%' height='160px'/>

        <rect x='0' y={342} rx='4' ry='4' width='32%' height='40px'/>

        <rect x='0' y={392} rx='4' ry='4' width='32%' height='40px'/>
        <rect x='80%' y={392} rx='4' ry='4' width='20%' height='40px'/>

        <rect x='0' y={436} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={480} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={524} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={568} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={612} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={656} rx='4' ry='4' width='100%' height='40px'/>
      </Loader>
    );
  };


  return (
    <>
      {!loading
        ? <SiteGeneralInfo data={siteDetails} setInitiateProjects={isPMOTeam && setInitiateProjects} loading={loading}/>
        : getSkeleton()
      }
      {initiateProjects && (
        <InitiateProjectModal
          onBackgroundClick={() => setInitiateProjects(null)}
          initiateProjects={initiateProjects}
        />
      )}
    </>
  );
};

export default Site;
