import React, {useEffect, useState} from "react";
import './Boards.scss';
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
// import circleWhite from '../../assets/icons/circle-white.svg';
// import noTickets from '../../assets/icons/no-tickets.svg';
import {
  DND_TICKET,
  GET_BOARDS_TICKETS_REQUEST,
  GET_MORE_BOARDS_TICKETS_REQUEST, REMOVE_TICKET_REQUEST,
  SORT_BOARDS_TICKETS_REQUEST
} from "store/actions/boards";
import {useDispatch, useSelector} from "react-redux";
import TicketModal from "./modals/TicketModal/TicketModal";
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import Ticket from "./Ticket/Ticket";
import classnames from "classnames";
import TicketSupportModal from "./modals/TicketSupportModal/TicketSupportModal";
import {moveToAnotherColumn} from "helpers";
import AssignTicketModal from "./modals/AssignTicketModal/AssignTicketModal";
import Loader from "components/common/Loader";
import UpdateTicketModal from "./modals/UpdateTicketModal/UpdateTicketModal";
import {NoTicketsIcon, SortIcon} from "components/Icons";
import {COLUMN_TITLES} from "../../constants";
import DropDown from "./components/DropDown/DropDown";

const Boards = () => {

  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const dispatch = useDispatch();
  const tickets = useSelector(state => state.boards.tickets);
  const total_tickets = useSelector(state => state.boards.total_tickets);
  const loading = useSelector(state => state.boards.loading);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [isTicketSupportModalOpen, setIsTicketSupportModalOpen] = useState(false);
  const [isAssignTicketModalOpen, setIsAssignTicketModalOpen] = useState(false);
  const [isUpdateTicketModalOpen, setIsUpdateTicketModalOpen] = useState(false);
  const [assignAction, setAssignAction] = useState(null);
  const [updateActionName, setUpdateActionName] = useState(null);
  const [isDropDisabled, setIsDropDisabled] = useState([false, false, false, false, false, false, false]);
  const auth = useSelector(state => state.auth);
  const userData = useSelector((state) => state.auth.user);
  const filters = useSelector(state => state.common.lookup);
  const [sortState, setSortState] = useState(COLUMN_TITLES.reduce((obj, item) => ({...obj, [item.key]: {sortBy: 'Newest', sortLoading: false, loadingMore: false}}), {}));
  const [filtersState, setFiltersState] = useState({category: [], priority: []});

  useEffect(() => {
    const filters = {
      category: filtersState.category.toString(),
      priority: filtersState.priority.toString()
    };
    dispatch({type: GET_BOARDS_TICKETS_REQUEST, payload: {body: filters}});
  }, [filtersState, dispatch]);

  const toggleTicketModal = (ticket) => {
    setCurrentTicket({ticket});
    setIsTicketModalOpen(true);
  };
  const openTicketSupportModal = (ticket, column, ticketIndex) => {
    setCurrentTicket({ticket, column, ticketIndex});
    setIsTicketSupportModalOpen(true);
  };
  const openUpdateModal = (ticket, column, ticketIndex) => {
    setCurrentTicket({ticket, column, ticketIndex});
    setIsUpdateTicketModalOpen(true);
  };
  const openAssignTicketModal = (ticket, column, forMySelf) => {
    setCurrentTicket({ticket, column, forMySelf});
    setIsAssignTicketModalOpen(true);
  };

  const onDragEnd = result => {
    const {destination, source} = result;

    if(!destination) return;
    if(destination.droppableId === source.droppableId && destination.index === source.index) return;

    const start = source.droppableId;
    const finish = destination.droppableId;

    if (start === finish) {
      const newTickets = [...tickets[source.droppableId]];
      newTickets.splice(source.index, 1);
      newTickets.splice(destination.index, 0, tickets[source.droppableId][source.index]);

      dispatch({type: DND_TICKET, payload: {...tickets, [start]: newTickets}});
    } else {
      if(start === 'New'){
        openAssignTicketModal(tickets.New[source.index], destination.droppableId, true);
        setAssignAction({
          type: DND_TICKET,
          payload: moveToAnotherColumn(tickets, source.droppableId, source.index, destination.droppableId, destination.index)
        });
      }else if(start === 'Assigned'){
        openUpdateModal(tickets[start][source.index], start);
        setUpdateActionName('In Progress');
        setAssignAction({
          type: DND_TICKET,
          payload: moveToAnotherColumn(tickets, source.droppableId, source.index, destination.droppableId, destination.index)
        });
      } else {
        dispatch({
          type: DND_TICKET,
          payload: moveToAnotherColumn(tickets, source.droppableId, source.index, destination.droppableId, destination.index)
        });
      }
    }
  };

  const onDragStart = (result) => {
    const index = COLUMN_TITLES.findIndex(item => item.key === result.source.droppableId);
    let newArr;
    if(index === 0) newArr = [false, false, true, true, true, true, true];
    if(index === 1) newArr = [true, false, false, true, true, true, true];
    if(index === 2) newArr = [true, false, false, false, true, true, true];
    if(index === 3) newArr = [true, true, false, false, false, true, true];
    if(index === 4) newArr = [true, true, true, false, false, false, true];
    if(index === 5) newArr = [true, true, true, true, false, false, false];
    if(index === 6) newArr = [true, true, true, true, true, false, false];
    setIsDropDisabled(newArr)
  };

  const getSkeleton = () => {
    return (
      <Loader height={670} width='100%'>
        {[...new Array(4)].map((val, i) => {
          return (<rect key={i} x='0' y={i * 168} rx="4" ry="4" width="100%" height="160px" />);
        })}
      </Loader>
    )
  };

  const sortTickets = (e, column) => {
    const newSort = sortState[column.key].sortBy === 'Newest' ? 'Oldest' : 'Newest';
    setSortState({...sortState, [column.key]: {...sortState[column.key], sortLoading: true, sortBy: newSort}});
    const filters = {
      category: filtersState.category.toString(),
      priority: filtersState.priority.toString()
    };
    dispatch({
      type: SORT_BOARDS_TICKETS_REQUEST,
      payload: {
        body: {
          ...filters,
          status: column.key,
          sort_order: newSort,
        },
        auth: userData
      },
      callback: () => setSortState({...sortState, [column.key]: {...sortState[column.key], sortBy: newSort, sortLoading: false}})
    });
  };

  const loadMoreTickets = (column) => {
    setSortState({...sortState, [column.key]: {...sortState[column.key], loadingMore: true}});
    const filters = {
      category: filtersState.category.toString(),
      priority: filtersState.priority.toString()
    };

    dispatch({
      type: GET_MORE_BOARDS_TICKETS_REQUEST,
      payload: {
        body: {
          ...filters,
          status: column.key,
          sort_order: sortState[column.key].sortBy,
          page_number: Math.floor(tickets[column.key].length / 10) + 1
        },
        auth: userData
      },
      callback: () => setSortState({...sortState, [column.key]: {...sortState[column.key], loadingMore: false}})
    })
  };

  const filterTickets = (name, clear, isChecked, itemId) => {
    const newFilters = {...filtersState, [name]: clear
        ? []
        : isChecked
          ? filtersState[name].filter(el => el !== itemId)
          : [...filtersState[name], itemId]};
    setFiltersState(newFilters);
    // dispatch({
    //   type: GET_BOARDS_TICKETS_REQUEST,
    //   payload: {query: {username: auth.username, filter: newFilters}, session_id: auth.user.session_id}
    // })
  };


  const removeTicket = (column, ticket) => {
    setSortState({...sortState, [column]: {...sortState[column], sortLoading: true}});
    dispatch({
      type: REMOVE_TICKET_REQUEST,
      payload: {username: auth.username, query: {id: ticket.id}, session_id: auth.user.session_id},
      callback: () => setSortState({...sortState, [column]: {...sortState[column], sortLoading: false}})
    })
  };

  return (
    <div className='Boards'>
      <div className="boards-top">
        <h1 className="page-title">Tickets</h1>
        <div className="boards-filters">
          <DropDown
            title='Category'
            name='category'
            listItems={filters?.['Ticket Category']}
            onChange={filterTickets}
            filtersState={filtersState}
          />
          <DropDown
            title='Priority'
            name='priority'
            listItems={filters?.['Ticket Priority']}
            onChange={filterTickets}
            filtersState={filtersState}
          />
        </div>
      </div>

      <div className="managers">
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {COLUMN_TITLES.map((column, index) =>
            <div className='managers-item' key={column.key}>
              <div className="managers-title">
                <h3>{column.title} {!!tickets[column.key]?.length && !loading && <span>{tickets[column.key].length}</span>}</h3>
                {!!tickets[column.key]?.length && !loading && (
                  <span
                    className='managers-sort'
                    onClick={(e) => sortTickets(e, column)}
                  >
                    {sortState[column.key].sortBy}
                    <SortIcon/>
                  </span>
                )}
              </div>
              {!loading && !sortState[column.key].sortLoading
                ? <Droppable droppableId={column.key} isDropDisabled={isDropDisabled[index]}>
                  {(provided, snapshot) => (
                    <div
                      className={classnames("managers-droppable", {active: snapshot.isDraggingOver, current: snapshot.draggingFromThisWith})}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {!!tickets[column.key]?.length
                        ? <>
                          {index === 0 && (
                            <Button
                              title='Create ticket'
                              // icon={circleWhite}
                              color={BUTTON_COLORS.DEFAULT}
                              onClick={() => {
                                currentTicket && setCurrentTicket(null);
                                setIsTicketModalOpen(true);
                              }}
                            />
                          )}
                          {tickets[column.key]?.map((item, index) =>
                            <Ticket
                              key={`${item.id}-${index}`}
                              column={column.key}
                              item={item}
                              index={index}
                              toggleTicketModal={toggleTicketModal}
                              openTicketSupportModal={openTicketSupportModal}
                              openAssignTicketModal={openAssignTicketModal}
                              removeTicket={removeTicket}
                              setUpdateAction={setUpdateActionName}
                              openUpdateModal={openUpdateModal}
                            />
                          )}
                        </>
                        : <div className='no-tickets'>
                          <div className='no-tickets-info'>
                            <span><NoTicketsIcon/></span>
                            There are no tickets
                          </div>
                          {index === 0 && (
                            <Button
                              title='Create ticket'
                              // icon={circleWhite}
                              color={BUTTON_COLORS.DEFAULT}
                              onClick={() => {
                                currentTicket && setCurrentTicket(null);
                                setIsTicketModalOpen(true);
                              }}
                            />
                          )}
                        </div>
                      }
                      {sortState[column.key].loadingMore
                        ? getSkeleton()
                        : total_tickets[column.key] > tickets[column.key]?.length && (
                            <Button
                              title='Load more'
                              color='ongoing'
                              // centered
                              onClick={() => loadMoreTickets(column)}
                            />
                          )
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                : getSkeleton()
              }
            </div>
          )}
        </DragDropContext>
      </div>

      {isTicketModalOpen && (
        <TicketModal
          currentTicket={currentTicket}
          open={isTicketModalOpen}
          setIsModalOpen={() => setIsTicketModalOpen(false)}
        />
      )}
      {isTicketSupportModalOpen && (
        <TicketSupportModal
          currentTicket={currentTicket}
          open={isTicketSupportModalOpen}
          setIsModalOpen={() => setIsTicketSupportModalOpen(false)}
          tickets={tickets}
          openAssignModal={openAssignTicketModal}
          openUpdateModal={setIsUpdateTicketModalOpen}
          setAssignAction={setAssignAction}
          setUpdateAction={setUpdateActionName}
        />
      )}
      {isAssignTicketModalOpen && (
        <AssignTicketModal
          open={isAssignTicketModalOpen}
          setIsModalOpen={() => setIsAssignTicketModalOpen(false)}
          openTicketSupportModal={setIsTicketSupportModalOpen}
          currentTicket={currentTicket}
          assignAction={assignAction}
          filterData={filtersState}
        />
      )}
      {isUpdateTicketModalOpen && (
        <UpdateTicketModal
          open={isUpdateTicketModalOpen}
          setIsModalOpen={() => setIsUpdateTicketModalOpen(false)}
          openTicketSupportModal={setIsTicketSupportModalOpen}
          currentTicket={currentTicket}
          assignAction={assignAction}
          actionName={updateActionName}
          filterData={filtersState}
        />
      )}
    </div>
  )
};

export default Boards