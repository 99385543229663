import ContentLoader from "react-content-loader";

const Loader = ({ height, width, children }) => (
  <ContentLoader
    height={height}
    width={width}
    speed={2}
    primarycolor="#d9d9d9"
    secondarycolor="#ecebeb"
  >
    {children}
  </ContentLoader>
);

export default Loader;