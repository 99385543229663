import {call, put, all, takeLatest, select} from "redux-saga/effects";
import * as boardsActions from '../actions/boards';
import {Api} from "api";
import {BOARD_TICKETS} from "../../constants";
import * as projectActions from "../actions/project";

function* getBoardsTickets(action) {
  try {
    const res = yield call(Api.boards.getBoardTickets, action.payload);
    const newData = {...BOARD_TICKETS};
    res.data?.tickets_data.forEach(item => {
      if(newData[item.status]) newData[item.status] = [...newData[item.status], item]
    });
    yield put({type: boardsActions.GET_BOARDS_TICKETS_SUCCESS, payload: newData, total_tickets: res.data?.total_tickets});
  } catch (err) {
    yield put({ type: boardsActions.GET_BOARDS_TICKETS_FAIL, payload: { error: err.message } });
  }
}

function* getMoreBoardsTickets(action) {
  try {
    const res = yield call(Api.boards.getBoardTickets, action.payload);
    const newTicketsState = {...yield select(state => state.boards.tickets)};
    newTicketsState[action.payload.body.status] = [...newTicketsState[action.payload.body.status], ...res.data?.tickets_data];
    yield put({type: boardsActions.GET_MORE_BOARDS_TICKETS_SUCCESS, payload: newTicketsState});
    if(action.callback) action.callback();
  } catch (err) {
    yield put({ type: boardsActions.GET_MORE_BOARDS_TICKETS_FAIL, payload: { error: err.message } });
  }
}

function* sortBoardsTickets(action) {
  try {
    const res = yield call(Api.boards.getBoardTickets, action.payload);
    const newTicketsState = {...yield select(state => state.boards.tickets)};
    newTicketsState[action.payload.body.status] = res.data?.tickets_data;
    yield put({type: boardsActions.SORT_BOARDS_TICKETS_SUCCESS, payload: newTicketsState});
    if(action.callback) action.callback();
  } catch (err) {
    yield put({ type: boardsActions.SORT_BOARDS_TICKETS_FAIL, payload: { error: err.message } });
  }
}

function* createTicket(action) {
  try {
    const res = yield call(Api.boards.createTicket, action.payload);
    action.callback(false);
    let newTicketsState = yield select(state => state.boards.tickets);
    const newTicket = res.data.ticket;
    newTicketsState = newTicketsState.New
      ? {...newTicketsState, New: [newTicket, ...newTicketsState.New]}
      : {...newTicketsState, New: [newTicket]};
    yield put({type: boardsActions.CREATE_TICKET_SUCCESS, payload: newTicketsState});
    if(action.payload.fromActivity){
      let projectTickets = yield select(state => state.project.projectTickets);
      yield put({type: projectActions.GET_PROJECT_TICKETS_SUCCESS, payload: [...projectTickets, newTicket]})
    }
  } catch (err) {
    yield put({ type: boardsActions.CREATE_TICKET_FAIL, payload: { error: err.message } });
  }
}

function* editTicket(action) {
  try {
    const column = action.payload.body.status || 'New';
    const res = yield call(Api.boards.createTicket, action.payload);
    if(action.callback) action.callback(false);
    let newTicketsState = {...yield select(state => state.boards.tickets)};
    newTicketsState = {...newTicketsState, [column]: newTicketsState[column].map(item => {
      return res.data.id === item.id ? action.payload.body : item;
    })};
    yield put({type: boardsActions.EDIT_TICKET_SUCCESS, payload: newTicketsState});
  } catch (err) {
    yield put({ type: boardsActions.EDIT_TICKET_FAIL, payload: { error: err.message } });
  }
}

function* removeTicket(action) {
  try {
    yield call(Api.boards.removeTicket, action.payload);
    if(action.callback) action.callback();
    let newTicketsState = {...yield select(state => state.boards.tickets)};
    newTicketsState = {...newTicketsState, Created: newTicketsState.Created.filter(item => action.payload.query.id !== item.id)};
    yield put({type: boardsActions.REMOVE_TICKET_SUCCESS, payload: newTicketsState});
  } catch (err) {
    yield put({ type: boardsActions.REMOVE_TICKET_FAIL, payload: { error: err.message } });
  }
}

function* getTicketDetails(action) {
  try {
    const res = yield call(Api.boards.getTicketDetails, action.payload);
    yield put({type: boardsActions.GET_TICKET_DETAILS_SUCCESS, payload: res.data});
  } catch (err) {
    yield put({ type: boardsActions.GET_TICKET_DETAILS_FAIL, payload: { error: err.message } });
  }
}

function* addTicketRemarks(action) {
  try {
    const res = yield call(Api.boards.addTicketRemarks, action.payload);
    yield put({type: boardsActions.ADD_TICKET_REMARKS_SUCCESS, payload: res.data});
    if(action.callback) action.callback();
  } catch (err) {
    yield put({ type: boardsActions.ADD_TICKET_REMARKS_FAIL, payload: { error: err.message } });
  }
}

export default all([
  takeLatest(boardsActions.GET_BOARDS_TICKETS_REQUEST, getBoardsTickets),
  takeLatest(boardsActions.GET_MORE_BOARDS_TICKETS_REQUEST, getMoreBoardsTickets),
  takeLatest(boardsActions.SORT_BOARDS_TICKETS_REQUEST, sortBoardsTickets),
  takeLatest(boardsActions.CREATE_TICKET_REQUEST, createTicket),
  takeLatest(boardsActions.EDIT_TICKET_REQUEST, editTicket),
  takeLatest(boardsActions.REMOVE_TICKET_REQUEST, removeTicket),
  takeLatest(boardsActions.GET_TICKET_DETAILS_REQUEST, getTicketDetails),
  takeLatest(boardsActions.ADD_TICKET_REMARKS_REQUEST, addTicketRemarks),
])
