import {call, put, all, takeLatest} from "redux-saga/effects";
import {Api} from "api";
import * as dashboardActions from "../actions/dashboard";

function* getSiteList(action) {
  try {
    const res = yield call(Api.dashboard.getAgingData, action.payload);
    yield put({type: dashboardActions.GET_AGING_DATA_SUCCESS, payload: {chart: res.data}});
  } catch (err) {
    yield put({type: dashboardActions.GET_AGING_DATA_FAIL, payload: {error: err.message}});
  }
}

function* getDashboardSummary(action) {
  try {
    const res = yield call(Api.dashboard.getDashboardSummary, action.payload);
    yield put({type: dashboardActions.GET_DASHBOARD_SUMMARY_SUCCESS, payload: {chart: res.data}});
  } catch (err) {
    yield put({type: dashboardActions.GET_DASHBOARD_SUMMARY_FAIL, payload: {error: err.message}});
  }
}

export default all([
  takeLatest(dashboardActions.GET_AGING_DATA_REQUEST, getSiteList),
  takeLatest(dashboardActions.GET_DASHBOARD_SUMMARY_REQUEST, getDashboardSummary),
]);
