import {
  GET_BOARDS_TICKETS_REQUEST,
  GET_BOARDS_TICKETS_FAIL,
  GET_BOARDS_TICKETS_SUCCESS,
  CREATE_TICKET_SUCCESS,
  EDIT_TICKET_SUCCESS,
  DND_TICKET,
  REMOVE_TICKET_SUCCESS,
  GET_TICKET_DETAILS_SUCCESS,
  GET_TICKET_DETAILS_REQUEST,
  GET_TICKET_DETAILS_FAIL,
  GET_MORE_BOARDS_TICKETS_SUCCESS,
  SORT_BOARDS_TICKETS_SUCCESS,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_FAIL,
  EDIT_TICKET_FAIL,
  EDIT_TICKET_REQUEST,
  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_FAIL,
  UPDATE_TICKET_SUCCESS,
} from "../actions/boards";

const initialState = {
  loading: false,
  tickets: {},
  total_tickets: {},
  defaultInfo: null,
  ticketDetails: {},
  modalsLoading: false,
};

export default function boards(state = initialState, action) {
  switch (action.type) {
    case GET_BOARDS_TICKETS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case GET_BOARDS_TICKETS_SUCCESS:
    case REMOVE_TICKET_SUCCESS:
    case DND_TICKET:
      return {
        ...state,
        tickets: action.payload,
        total_tickets: action.total_tickets ? action.total_tickets : state.total_tickets,
        loading: false,
      };

    case CREATE_TICKET_SUCCESS:
    case EDIT_TICKET_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
        modalsLoading: false,
      };

    case GET_BOARDS_TICKETS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_TICKET_DETAILS_REQUEST:
    case CREATE_TICKET_REQUEST:
    case EDIT_TICKET_REQUEST:
    case UPDATE_TICKET_REQUEST:
      return {
        ...state,
        modalsLoading: true
      };
    case GET_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        ticketDetails: action.payload,
        modalsLoading: false
      };
    case GET_TICKET_DETAILS_FAIL:
    case CREATE_TICKET_FAIL:
    case EDIT_TICKET_FAIL:
    case UPDATE_TICKET_FAIL:
    case UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        modalsLoading: false
      };

    case GET_MORE_BOARDS_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
      };
    case SORT_BOARDS_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.payload,
      };

    default:
      return state;
  }
}
