import classnames from 'classnames';
import React from "react";
import PropTypes from 'prop-types';
import "./index.scss";

export class Dropdown extends React.Component {

  static propTypes = {
    main: PropTypes.node,
    dropdown: PropTypes.node,
    active: PropTypes.bool,
    isRight: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    main: null,
    dropdown: null,
    active: false,
    isRight: false,
    onClick: null,
  };

  onDropdownClick = (e) => {
    e.stopPropagation();
  };

  render () {
    const { main, active, isRight, dropdown, onClick } = this.props;
    return (
      <div className="dropdown" onClick={onClick}>
        {main}
        <div onClick={this.onDropdownClick} className={classnames("dropdown-content", {
          right: isRight,
          active: active,
        })}>
          {dropdown}
        </div>
      </div>
    );
  }
}