import classnames from 'classnames';
import React from "react";
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./index.scss";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  // background: isDragging ? "transparent" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle
});

export const getListStyle = () => ({
  background: "none",
  padding: 0,
  width: "100%"
});

export class DragAndDrop extends React.Component {

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      data: PropTypes.any,
      node: PropTypes.node
    })),
    onOrder: PropTypes.func
  };

  static defaultProps = {
  };

  onDragEnd = (result) => {
    const { onOrder } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );

    onOrder && onOrder.call(this, items);
  };

  render() {
    const { items } = this.props;

    return (
      <div className={classnames("drag-and-drop-component")}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.node}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}
