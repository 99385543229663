export const PROJECT_LIST_REQUEST = "PROJECT_LIST_REQUEST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAIL = "PROJECT_LIST_FAIL";

export const PROJECT_DETAILS_REQUEST = "PROJECT_DETAILS_REQUEST";
export const PROJECT_DETAILS_SUCCESS = "PROJECT_DETAILS_SUCCESS";
export const PROJECT_DETAILS_FAIL = "PROJECT_DETAILS_FAIL";

export const ACTIVITY_DETAILS_REQUEST = "ACTIVITY_DETAILS_REQUEST";
export const ACTIVITY_DETAILS_SUCCESS = "ACTIVITY_DETAILS_SUCCESS";
export const ACTIVITY_DETAILS_FAIL = "ACTIVITY_DETAILS_FAIL";

export const SAVE_TASK_REQUEST = "SAVE_TASK_REQUEST";
export const SAVE_TASK_SUCCESS = "SAVE_TASK_SUCCESS";
export const SAVE_TASK_FAIL = "SAVE_TASK_FAIL";

export const SAVE_TASK_STAKEHOLDER_REQUEST = "SAVE_TASK_STAKEHOLDER_REQUEST";
export const SAVE_TASK_STAKEHOLDER_SUCCESS = "SAVE_TASK_STAKEHOLDER_SUCCESS";
export const SAVE_TASK_STAKEHOLDER_FAIL = "SAVE_TASK_STAKEHOLDER_FAIL";

export const SAVE_TASK_MEDIA_REQUEST = "SAVE_TASK_MEDIA_REQUEST";
export const SAVE_TASK_MEDIA_SUCCESS = "SAVE_TASK_MEDIA_SUCCESS";
export const SAVE_TASK_MEDIA_FAIL = "SAVE_TASK_MEDIA_FAIL";

export const GET_PROJECT_ROLES_REQUEST = "GET_PROJECT_ROLES_REQUEST";
export const GET_PROJECT_ROLES_SUCCESS = "GET_PROJECT_ROLES_SUCCESS";
export const GET_PROJECT_ROLES_FAIL = "GET_PROJECT_ROLES_FAIL";

export const GET_PROJECT_USERS_REQUEST = "GET_PROJECT_USERS_REQUEST";
export const GET_PROJECT_USERS_SUCCESS = "GET_PROJECT_USERS_SUCCESS";
export const GET_PROJECT_USERS_FAIL = "GET_PROJECT_USERS_FAIL";

export const GET_PROJECT_DEFINITION_LIST_REQUEST = "GET_PROJECT_DEFINITION_LIST_REQUEST";
export const GET_PROJECT_DEFINITION_LIST_SUCCESS = "GET_PROJECT_DEFINITION_LIST_SUCCESS";
export const GET_PROJECT_DEFINITION_LIST_FAIL = "GET_PROJECT_DEFINITION_LIST_FAIL";

export const GET_PROJECT_DEFINITION_DETAILS_REQUEST = "GET_PROJECT_DEFINITION_DETAILS_REQUEST";
export const GET_PROJECT_DEFINITION_DETAILS_SUCCESS = "GET_PROJECT_DEFINITION_DETAILS_SUCCESS";
export const GET_PROJECT_DEFINITION_DETAILS_FAIL = "GET_PROJECT_DEFINITION_DETAILS_FAIL";

export const SAVE_TASK_APPROVAL_REQUEST = "SAVE_TASK_APPROVAL_REQUEST";
export const SAVE_TASK_APPROVAL_SUCCESS = "SAVE_TASK_APPROVAL_SUCCESS";
export const SAVE_TASK_APPROVAL_FAIL = "SAVE_TASK_APPROVAL_FAIL";

export const GET_ATTACHMENT_TYPE_REQUEST = "GET_ATTACHMENT_TYPE_REQUEST";
export const GET_ATTACHMENT_TYPE_SUCCESS = "GET_ATTACHMENT_TYPE_SUCCESS";
export const GET_ATTACHMENT_TYPE_FAIL = "GET_ATTACHMENT_TYPE_FAIL";

export const CLEAR_ATTACHMENT_TYPE = "CLEAR_ATTACHMENT_TYPE";

export const EDIT_TASKS = "EDIT_TASKS";

export const GET_PROJECT_DOCUMENTS_REQUEST = "GET_PROJECT_DOCUMENTS_REQUEST";
export const GET_PROJECT_DOCUMENTS_SUCCESS = "GET_PROJECT_DOCUMENTS_SUCCESS";
export const GET_PROJECT_DOCUMENTS_FAIL = "GET_PROJECT_DOCUMENTS_FAIL";

export const GET_PROJECT_TICKETS_REQUEST = "GET_PROJECT_TICKETS_REQUEST";
export const GET_PROJECT_TICKETS_SUCCESS = "GET_PROJECT_TICKETS_SUCCESS";
export const GET_PROJECT_TICKETS_FAIL = "GET_PROJECT_TICKETS_FAIL";

export const GET_PROJECT_OPTIONS_REQUEST = "GET_PROJECT_OPTIONS_REQUEST";
export const GET_PROJECT_OPTIONS_SUCCESS = "GET_PROJECT_OPTIONS_SUCCESS";
export const GET_PROJECT_OPTIONS_FAIL = "GET_PROJECT_OPTIONS_FAIL";

export const GET_PROJECT_TASKS_REQUEST = "GET_PROJECT_TASKS_REQUEST";
export const GET_PROJECT_TASKS_SUCCESS = "GET_PROJECT_TASKS_SUCCESS";
export const GET_PROJECT_TASKS_FAIL = "GET_PROJECT_TASKS_FAIL";

export const EDIT_ACTIVITIES = "EDIT_ACTIVITIES";

export const DELETE_ACTIVITIES_REQUEST = "DELETE_ACTIVITIES_REQUEST";
export const DELETE_ACTIVITIES_SUCCESS = "DELETE_ACTIVITIES_SUCCESS";
export const DELETE_ACTIVITIES_FAIL = "DELETE_ACTIVITIES_FAIL";

export const SAVE_MILESTONE_TEMPLATE_REQUEST = "SAVE_MILESTONE_TEMPLATE_REQUEST";
export const SAVE_MILESTONE_TEMPLATE_SUCCESS = "SAVE_MILESTONE_TEMPLATE_SUCCESS";
export const SAVE_MILESTONE_TEMPLATE_FAIL = "SAVE_MILESTONE_TEMPLATE_FAIL";

export const SAVE_MILESTONE_TASK_REQUEST = "SAVE_MILESTONE_TASK_REQUEST";
export const SAVE_MILESTONE_TASK_SUCCESS = "SAVE_MILESTONE_TASK_SUCCESS";
export const SAVE_MILESTONE_TASK_FAIL = "SAVE_MILESTONE_TASK_FAIL";

export const GET_MILESTONE_TEMPLATE_LIST_REQUEST = "GET_MILESTONE_TEMPLATE_LIST_REQUEST";
export const GET_MILESTONE_TEMPLATE_LIST_SUCCESS = "GET_MILESTONE_TEMPLATE_LIST_SUCCESS";
export const GET_MILESTONE_TEMPLATE_LIST_FAIL = "GET_MILESTONE_TEMPLATE_LIST_FAIL";

export const GET_MILESTONE_TEMPLATE_DETAILS_REQUEST = "GET_MILESTONE_TEMPLATE_DETAILS_REQUEST";
export const GET_MILESTONE_TEMPLATE_DETAILS_SUCCESS = "GET_MILESTONE_TEMPLATE_DETAILS_SUCCESS";
export const GET_MILESTONE_TEMPLATE_DETAILS_FAIL = "GET_MILESTONE_TEMPLATE_DETAILS_FAIL";

export const SAVE_PROJECT_REQUEST = "SAVE_PROJECT_REQUEST";
export const SAVE_PROJECT_SUCCESS = "SAVE_PROJECT_SUCCESS";
export const SAVE_PROJECT_FAIL = "SAVE_PROJECT_FAIL";

export const GET_ACTIVE_PROJECT_LIST_REQUEST = "GET_ACTIVE_PROJECT_LIST_REQUEST";
export const GET_ACTIVE_PROJECT_LIST_SUCCESS = "GET_ACTIVE_PROJECT_LIST_SUCCESS";
export const GET_ACTIVE_PROJECT_LIST_FAIL = "GET_ACTIVE_PROJECT_LIST_FAIL";

export const GET_ALL_PROJECT_LIST_REQUEST = "GET_ALL_PROJECT_LIST_REQUEST";
export const GET_ALL_PROJECT_LIST_SUCCESS = "GET_ALL_PROJECT_LIST_SUCCESS";
export const GET_ALL_PROJECT_LIST_FAIL = "GET_ALL_PROJECT_LIST_FAIL";

export const REARRANGE_MILESTONE_TASK_REQUEST = "REARRANGE_MILESTONE_TASK_REQUEST";
export const REARRANGE_MILESTONE_TASK_SUCCESS = "REARRANGE_MILESTONE_TASK_SUCCESS";
export const REARRANGE_MILESTONE_TASK_FAIL = "REARRANGE_MILESTONE_TASK_FAIL";