import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {CREATE_TICKET_REQUEST, EDIT_TICKET_REQUEST, GET_TICKET_DETAILS_REQUEST} from "store/actions/boards";
import classNames from "classnames";
import './TicketModal.scss';
import Loader from "components/common/Loader";
import {getRequiredTicketFields} from "helpers";
import {Fade, InputBase, MenuItem, Modal, Select, TextareaAutosize, Button as MUIButton, Backdrop} from "@mui/material";
import {AttachIcon, BookIcon, CloseIcon, WarningIcon} from "components/Icons";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {GET_PROJECT_TASKS_REQUEST, GET_ALL_PROJECT_LIST_REQUEST} from "store/actions/project";
import {UPLOAD_MEDIA_ALL_REQUEST} from "store/actions/common";
import {MENU_PROPS} from "../../../../constants";

const TicketModal = ({open, setIsModalOpen, currentTicket, activityId, taskId}) => {

  const userData = useSelector((state) => state.auth.user);
  const filters = useSelector(state => state.common.lookup);
  const modalsLoading = useSelector(state => state.boards.modalsLoading);
  const allProjectList = useSelector(state => state.project.allProjectList);
  const projectTasks = useSelector(state => state.project.projectTasks);

  console.log(activityId, 'activityId')

  const initialState = {
    id: null,
    activity_id: +activityId || null,
    task_id: taskId || null,
    status: 'New',
    description: '',
    category: '',
    priority: 'Medium',
    due_date: null,
    start_date: null,
    assigned_to: null,
    media: [],
  };

  const initialErrors = {
    name: '',
    description: '',
    category: '',
    supportTeam: '',
    model: '',
  };

  const ticket = currentTicket?.ticket;
  const [state, setState] = useState(ticket || initialState);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(initialErrors);
  const ticketDetails = useSelector(state => state.boards.ticketDetails);

  console.log(ticketDetails, 'ticketDetails')
  console.log(ticket, 'ticket')

  useEffect(() => {
    if(ticket && ticketDetails) setState({...initialState, ...ticket, media: ticketDetails.media});
  },[ticketDetails]); // eslint-disable-line


  useEffect(() => {
    if(ticket){
      dispatch({
        type: GET_TICKET_DETAILS_REQUEST,
        payload: {auth: userData, id: ticket.id}
      });
      if(ticket.activity_id){
        dispatch({
          type: GET_PROJECT_TASKS_REQUEST,
          payload: {auth: userData, id: ticket.activity_id}
        })
      }
    }
    if(!allProjectList.length){
      dispatch({
        type: GET_ALL_PROJECT_LIST_REQUEST,
        payload: {auth: userData}
      })
    }
    if(activityId){
      dispatch({
        type: GET_PROJECT_TASKS_REQUEST,
        payload: {auth: userData, id: activityId}
      })
    }
  }, []); // eslint-disable-line

  const closeModal = () => {
    setIsModalOpen();
  };

  const createTicket = () => {
    const err = {...errors};
    Object.entries(state).forEach(([key, value]) => {
      if(value === '' && errors.hasOwnProperty(key)) err[key] = 'This field is required';
    });
    setErrors(err);

    if(Object.values(err).some(item => item !== '')) return;

    if(ticket){
      let {comment, ...data} = state;
      // data.media = data.files_new;
      if(data.comment_new) data.comment = data.comment_new;
      dispatch({type: EDIT_TICKET_REQUEST, payload: {auth: userData, body: getRequiredTicketFields(data)}, callback: closeModal})
    } else {
      let {files_new, ...data} = state;
      dispatch({type: CREATE_TICKET_REQUEST, payload: {auth: userData, body: data, fromActivity: !!activityId}, callback: closeModal})
    }
  };

  const onHandleChange = (e) => {
    const newState = {...state};
    newState[e.target.name] = e.target.value;
    if(e.target.name === 'type') newState.category = '';
    setState(newState);
    errors.hasOwnProperty(e.target.name) && setErrors({...errors, [e.target.name]: ''});
  };

  const onHandleProjectChange = (e) => {
    dispatch({type: GET_PROJECT_TASKS_REQUEST, payload: {auth: userData, id: e.target.value}});
    setState({...state, activity_id: e.target.value, task_id: null});
  };

  const removeFile = (file) => {
    setState({...state, media: state.media.filter(item => item !== file)});
  };

  const addFiles = e => {
    dispatch({
      type: UPLOAD_MEDIA_ALL_REQUEST,
      payload: {auth: userData, body: e.target.files, bucket: 'Ticket', addFields: {TableName: 'dbo.ticket', FieldName: "Issue", "ObjectId": 1, "FileType": "Issue"}},
      callback: (res) => setState({...state, media: [...state.media, ...res]}),
    });
  };


  return (
    <Modal
      className='modal-wrapper'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableScrollLock
      BackdropProps={{
        timeout: 300,
        style: {opacity: 0}
      }}
    >
      <Fade in={open}>
        <div className='modal-content medium-width'>
          {!modalsLoading
            ? <ValidatorForm onSubmit={createTicket} className='modal-form'>
                <div className="modal-row row-padding">
                  <div className="modal-row-icon">
                    <BookIcon/>
                  </div>
                  <div className="modal-field">
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={1}
                      placeholder="Add description"
                      value={state.description}
                      name='description'
                      onChange={onHandleChange}
                    />
                    {errors.description && <p className='validation-message'>{errors.description}</p>}
                  </div>
                </div>
                <div className="modal-row row-padding">
                  <div className="modal-field modal-select">
                    <h6>Category</h6>
                    <Select
                      value={state.category || 'none'}
                      name='category'
                      type="text"
                      variant="outlined"
                      input={<InputBase />}
                      onChange={onHandleChange}
                      className={classNames({error: errors.category})}
                      MenuProps={MENU_PROPS}
                    >
                      <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
                      {filters?.['Ticket Category'].map(option => (
                        <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="modal-field modal-select">
                    <h6>Activity</h6>
                    <Select
                      value={state.activity_id || 'none'}
                      name='activity_id'
                      type="text"
                      variant="outlined"
                      input={<InputBase />}
                      onChange={onHandleProjectChange}
                      disabled={!!activityId}
                      MenuProps={MENU_PROPS}
                    >
                      <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
                      {allProjectList.map((option, key) => (
                        <MenuItem key={key} value={option.id}>{option.project_name}-{option.client}-{option.customer_site_id}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="modal-field modal-select">
                    <h6>Task</h6>
                    <Select
                      value={state.task_id || 'none'}
                      name='task_id'
                      type="text"
                      variant="outlined"
                      input={<InputBase />}
                      onChange={onHandleChange}
                      disabled={!!taskId}
                      MenuProps={MENU_PROPS}
                    >
                      <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
                      {projectTasks.map((option, key) => (
                        <MenuItem key={key} value={option.task_id}>{option.task_name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="modal-row modal-file row-padding">
                  <div className="modal-row-icon">
                    <AttachIcon/>
                  </div>
                  <div className="modal-field">
                    {state.media?.map((item, i) =>
                      <div className="file-preview" key={item.MediaName ? `${item.MediaName}-${i}` : `${item.name}-${i}`}>
                        {item.MediaName ? item.MediaName : item.name}
                        <span onClick={() => removeFile(item)}>
                          <CloseIcon/>
                        </span>
                      </div>
                    )}
                    <MUIButton
                      component="label"
                      disableRipple
                    >
                      Add attachment
                      <input
                        multiple
                        onChange={addFiles}
                        type="file"
                        hidden
                      />
                    </MUIButton>
                  </div>
                </div>
                <div className="modal-row row-padding">
                  <div className="modal-row-icon">
                    <WarningIcon/>
                  </div>
                  <div className="modal-field modal-select modal-select-custom">
                    <Select
                      className={`select-${state.priority}`}
                      value={`${state.priority}` || ''}
                      name='priority'
                      type="text"
                      variant="outlined"
                      input={<InputBase />}
                      onChange={onHandleChange}
                      renderValue={(option) => `${option} priority`}
                      MenuProps={MENU_PROPS}
                    >
                      <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
                      {filters?.['Ticket Priority'].map((option, key) => (
                        <MenuItem className={`color-${key}`} key={option.id} value={option.name}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="modal-btns">
                  <Button
                    type="button"
                    title='Discard'
                    color={BUTTON_COLORS.GRAY}
                    onClick={closeModal}
                  />
                  <Button
                    type='submit'
                    title='Submit'
                    color={BUTTON_COLORS.DEFAULT}
                  />
                </div>
              </ValidatorForm>
              : <Loader height={'80vh'} width='100%'>
                  <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
                </Loader>
          }
        </div>
      </Fade>
    </Modal>
  )
};

TicketModal.propTypes = {
  open: PropTypes.bool,
  task: PropTypes.object,
};

export default TicketModal;