import React, {useEffect, useMemo, useState, Fragment} from "react";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/Modal/Modal";
import {ValidatorForm, TextValidator, SelectValidator} from "react-material-ui-form-validator";
import {
  GET_PROJECT_ROLES_REQUEST, SAVE_MILESTONE_TASK_REQUEST,
} from "store/actions/project";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import classnames from "classnames";
import {ReorderIcon} from "components/Icons";
import {getClearableSelectIcon, getValueAfterDot} from "helpers";

const AddMilestoneTaskModal = ({onBackgroundClick, currentMilestone, parentMilestone}) => {

  const mileStoneTemplateDetails = useSelector((state) => state.project.mileStoneTemplateDetails);
  const userData = useSelector((state) => state.auth.user);
  const projectRoles = useSelector((state) => state.project.projectRoles);
  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.project.modalLoading);
  const {ProjectDetails, Tasks} = mileStoneTemplateDetails;

  const [state, setState] = useState({
    id: currentMilestone?.task_id || 0,
    name: currentMilestone?.task_name || '',
    parent_id: parentMilestone || currentMilestone?.parent_id || null,
    duration: currentMilestone?.duration || '',
    milestone_template_id: ProjectDetails.id,
    owner_role: currentMilestone?.owner_role_id || null,
    requires_approval: currentMilestone?.requires_approval || false,
    approver_role: currentMilestone?.approver_role_id || null,
    stakeholder_role: currentMilestone?.stakeholder_role_id || null,
    sequence: currentMilestone ? getValueAfterDot(currentMilestone.sequence) - 1 : '',
    predecessor_task_id: currentMilestone?.predecessor_task_id || null,
    predecessor_type: null
  })


  useEffect(() => {
    if(!projectRoles){
      dispatch({type: GET_PROJECT_ROLES_REQUEST, payload: {auth: userData}})
    }
  }, []); //eslint-disable-line

  const handleSubmit = () => {
    const data = {...state, duration: +state.duration};
    data.sequence = (!state.parent_id && !currentMilestone) ? Tasks.length + 1 : state.sequence + 1;
    dispatch({
      type: SAVE_MILESTONE_TASK_REQUEST,
      payload: data,
      callback: onBackgroundClick
    })
  }

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }
  const onHandleParentMilestoneChange = (e) => {
    setState({...state, [e.target.name]: e.target.value, predecessor_task_id: null});
  }
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  const onHandleChangeDuration = (e) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      value = '';
    } else {
      value = value > 0 ? value : 1;
    }
    setState({...state, [e.target.name]: value});
  }

  const getParentChildren = () => Tasks.find(item => item.task_id === state.parent_id).children;

  const parentChildren = useMemo(() => {
    if(state.parent_id){
      const children = getParentChildren();
      if(currentMilestone?.parent_id !== state.parent_id) setState({...state, sequence: children.length});
      return children;
    }else{
      return []
    }
  }, [state.parent_id]); //eslint-disable-line

  const onReorder = (result) => {
    if (!result.destination) return;
    setState({...state, sequence: result.destination.index})
  }

  console.log(state, 'state')

  return (
    <Modal
      className='small-width'
      onBackgroundClick={onBackgroundClick}
    >
      <div className='initiation-modal'>
        <h3>Add milestone</h3>
        <ValidatorForm onSubmit={handleSubmit} className='modal-form'>
          <div className="modal-row modal-row-flex">
            <div className="modal-field">
              <h6>Name</h6>
              <TextValidator
                value={state.name}
                name='name'
                type="text"
                placeholder='Name'
                variant="outlined"
                onChange={onHandleChange}
                validators={['required']}
                errorMessages={['This field is required']}
              />
            </div>
            <div className="modal-field modal-select">
              <h6>Parent milestone</h6>
              <Select
                value={state.parent_id || 'none'}
                variant="outlined"
                name='parent_id'
                onChange={onHandleParentMilestoneChange}
                displayEmpty={true}
                disabled={!!currentMilestone?.children.length}
                IconComponent={() => getClearableSelectIcon(state.parent_id, () => setState({...state, parent_id: null, predecessor_task_id: null}))}
              >
                <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
                {Tasks.map(item => state.id !== item.task_id &&
                  <MenuItem key={item.task_id} value={item.task_id}>{item.task_name}</MenuItem>
                )}
              </Select>
            </div>
          </div>


          {state.parent_id && (
            <div className="modal-row modal-field projectSequence">
              <h6>Sequence</h6>
              <DragDropContext onDragEnd={onReorder}>
                <Droppable droppableId="droppable" key='0'>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {[...parentChildren, {}].filter(item => !item?.task_id || currentMilestone?.task_id !== item?.task_id).map((item, i) =>  (
                        <Fragment key={i}>
                          {state.sequence === i && (
                            <Draggable draggableId={String(state.id)} index={state.sequence}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={classnames({empty: !state.name})}
                                >
                                  <div><ReorderIcon/>{state.name || 'Unnamed milestone'}</div>
                                </div>
                              )}
                            </Draggable>
                          )}
                          {item.task_id && (
                            <Draggable isDragDisabled={true} draggableId={String(item.task_id)} index={i >= state.sequence ? i + 1 : i}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>{item.task_name}</div>
                                </div>
                              )}
                            </Draggable>
                          )}
                        </Fragment>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          <div className="modal-row modal-row-flex">
            <div className="modal-field">
              <h6>Duration</h6>
              <TextValidator
                value={state.duration}
                name='duration'
                type="number"
                placeholder='Duration'
                variant="outlined"
                onChange={onHandleChangeDuration}
                validators={['required']}
                errorMessages={['This field is required']}
                InputProps={{ inputProps: { min: 1 } }}
                onKeyPress={preventMinus}
              />
            </div>
            <div className="modal-field modal-select">
              <h6>Predecessor</h6>
              <Select
                value={state.predecessor_task_id || 'none'}
                variant="outlined"
                name='predecessor_task_id'
                onChange={onHandleChange}
                displayEmpty={true}
                IconComponent={() => getClearableSelectIcon(state.predecessor_task_id, () => setState({...state, predecessor_task_id: null}))}
              >
                <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
                {[...(state.parent_id ? parentChildren : Tasks)].map(item => state.id !== item.task_id &&
                  <MenuItem key={item.task_id} value={item.task_id}>{item.task_name}</MenuItem>
                )}
              </Select>
            </div>
          </div>
          {/*<div className="modal-field modal-select">*/}
          {/*  <h6>Owner</h6>*/}
          {/*  <Select*/}
          {/*    value={state.owner_role || 'none'}*/}
          {/*    variant="outlined"*/}
          {/*    name='owner_role'*/}
          {/*    onChange={onHandleChange}*/}
          {/*    IconComponent={() => getClearableSelectIcon(state.owner_role, () => setState({...state, owner_role: null}))}*/}
          {/*  >*/}
          {/*    <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>*/}
          {/*    {projectRoles?.owner.map(item =>*/}
          {/*      <MenuItem key={item.role_id} value={item.role_id}>{item.role_name}</MenuItem>*/}
          {/*    )}*/}
          {/*  </Select>*/}
          {/*</div>*/}
          <div className="modal-row">
            <div className="modal-field">
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      value="CloseNCR"
                      checked={state.requires_approval}
                      onChange={(e) => setState({...state, requires_approval: e.target.checked})}
                    />
                  )}
                  label='Requires approval'
                />
              </FormGroup>
            </div>
          </div>
          {state.requires_approval && (
            <div className="modal-field modal-select">
              <h6>Approver</h6>
              <SelectValidator
                value={state.approver_role || ''}
                variant="outlined"
                name='approver_role'
                onChange={onHandleChange}
                validators={['required']}
                errorMessages={['This field is required']}
                InputProps={{
                  endAdornment: getClearableSelectIcon(state.approver_role, () => setState({...state, approver_role: null}), true),
                }}
              >
                <MenuItem className='hiddenOption' key="0" disabled value='none' >Not selected</MenuItem>
                {projectRoles?.owner.map(item =>
                  <MenuItem key={item.role_id} value={item.role_id}>{item.role_name}</MenuItem>
                )}
              </SelectValidator>
            </div>
          )}
          <div className="modal-field modal-select">
            <h6>Stakeholder</h6>
            <Select
              value={state.stakeholder_role || 'none'}
              variant="outlined"
              name='stakeholder_role'
              onChange={onHandleChange}
              displayEmpty={true}
              IconComponent={() => getClearableSelectIcon(state.stakeholder_role, () => setState({...state, stakeholder_role: null}))}
            >
              <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
              {projectRoles?.stakeholder.map(item =>
                <MenuItem key={item.role_id} value={item.role_id}>{item.role_name}</MenuItem>
              )}
            </Select>
          </div>

          <div className='modal-btns'>
            <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={onBackgroundClick}/>
            <Button title='Submit' color={BUTTON_COLORS.DEFAULT} disabled={modalLoading}/>
          </div>
        </ValidatorForm>
      </div>
    </Modal>
  );
};

export default AddMilestoneTaskModal;
