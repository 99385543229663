import {all} from "redux-saga/effects";

import auth from "./auth";
import project from "./project";
import common from "./common";
import site from "./site";
import dashboard from "./dashboard";
import boards from "./boards";
import userManagement from "./userManagement";

export function* sagas() {
  yield all([
    auth,
    project,
    common,
    site,
    dashboard,
    boards,
    userManagement,
  ]);
}
