import React, { useState } from "react";
import {ChangelogIcon, ChatIcon, DeleteIcon, DownloadIcon, ExpandIcon, UserIcon} from "components/Icons";
import {fileSize, getHumanDate, getStatusClassname} from "helpers";
import DateCalendarField from "../DateCalendarField/DateCalendarField";
import {DELETE_MEDIA_REQUEST, DOWNLOAD_MEDIA_REQUEST} from "store/actions/common";
import {useDispatch, useSelector} from "react-redux";
import Changelog from "../Changelog/Changelog";
import {Table} from "components/Table";
import useTable from "hooks/useTable";
import {Select, Tab, Tabs} from "@mui/material";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {SAVE_TASK_APPROVAL_REQUEST, SAVE_TASK_REQUEST} from "store/actions/project";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useParams} from "react-router-dom";
import ConfirmModal from "components/Modal/ConfirmModal";
import {SET_NOTIFICATION_MESSAGE} from "store/actions/notification";

const tasksHeadCells = [
  { attr: "task_id", title: "Task Id" },
  { attr: "task_name", title: "Task Name" },
  { attr: "task_status", title: "Status" },
  { attr: "actual_start_date", title: "Actual" },
  { attr: "forecast_start_date", title: "Forecast" },
  { attr: "plan_start_date", title: "Plan" },
  { attr: "Actions", title: "" },
];

const issuesHeadCells = [
  {attr: "Description", title: "Description"},
  {attr: "task_name", title: "Task Name"},
  {attr: "status", title: "Status"},
  {attr: "category", title: "Category"},
  {attr: "created_by_user", title: "created_by_user"},
  {attr: "assigned_to_user", title: "assigned_to_user"},
  {attr: "priority", title: "Priority"},
  {attr: "start_date", title: "start_date"},
  {attr: "due_date", title: "due_date"},
];


const dates = [
  {title: 'Plan start date', field: 'plan_start_date', editable: false},
  {title: 'Plan end date', field: 'plan_end_date', editable: false},
  {title: 'Forecast start date', field: 'forecast_start_date', editable: true, fieldName: 'forecast', type: 'start'},
  {title: 'Forecast end date', field: 'forecast_end_date', editable: true, fieldName: 'forecast', type: 'end'},
  {title: 'Actual start', field: 'actual_start_date', editable: true, fieldName: 'actual', type: 'start'},
  {title: 'Actual end', field: 'actual_end_date', editable: true, fieldName: 'actual', type: 'end'},
]

const SurveyModal = ({ setFullPage, isFullPage, changeModal, activeMilestone, setActiveMilestone, setProjectModal }) => {
  const params = useParams();
  const {id} = params;
  const [tab, setTab] = useState("details");
  const [openedDatePicker, setOpenedDatePicker] = useState({});
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);
  const lookup = useSelector((state) => state.common.lookup);
  const [anchorStatusEl, setAnchorStatusEl] = useState(null)
  const {tableData: tasksTableData, handleRequestSort: tasksHandleRequestSort} = useTable(activeMilestone.children);
  const {tableData: issuesTableData, handleRequestSort: issuesHandleRequestSort} = useTable(activeMilestone.issues);
  const [approveState, setApproveState] = useState(null);
  const activity = useSelector((state) => state.project.activity);

  const isPMOTeam = userData.role === 'PMO';
  const isAssignedPM = userData.user_name === activity.ActivityDetails.pm_user_name;
  const checkRights = isPMOTeam || isAssignedPM || userData.user_id === activeMilestone.owner.user_id;
  const checkStatusesFunc = () => {
    if(activeMilestone.task_status === 'Approved'){
      return true;
    }
    if(activeMilestone.task_status === 'Rejected'){
      return isPMOTeam || isAssignedPM;
    }
    if(activeMilestone.task_status === 'Completed' && !activeMilestone.requires_approval){
      return userData.user_id === activeMilestone.owner.user_id;
    }
    return false;
  }
  const checkStatuses = checkStatusesFunc();

  const saveTaskApproval = () => {
    const additionalFields = {};
    if(approveState.stage === 'Rejected'){
      additionalFields.rejection_reason = approveState.rejectionReason;
    }
    if(approveState.stage !== 'Approved'){
      additionalFields.remarks = approveState.remarksText;
    }
    dispatch({
      type: SAVE_TASK_APPROVAL_REQUEST,
      payload: {
        body: {
          id: activeMilestone.task_id,
          stage: approveState.stage,
          ...additionalFields
        },
        id: activeMilestone.activity_id
      },
      callback: () => changeModal(null)
    })
  }

  const changeTaskStatus = (value) => {
    if (value.name === 'Completed' && (!activeMilestone.actual_start_date || !activeMilestone.actual_end_date)) {
      dispatch({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "You must specify Actual Start and Actual End dates", snackbarSeverity: "error", openSnackbar: true}})
    }else{
      dispatch({
        type: SAVE_TASK_REQUEST,
        payload: {
          auth: userData,
          id: activeMilestone.activity_id,
          body: [{
            id: activeMilestone.task_id,
            status: value.id
          }]
        },
        callback: () => {
          setAnchorStatusEl(null);
          setActiveMilestone({...activeMilestone, task_status: value.name, task_status_id: value.id})
        },
      })
    }
  }

  const tabContent = () => {
    switch (tab) {
      case "issues":
        return (
          <>
            <Table
              data={issuesTableData}
              headers={issuesHeadCells}
              onRequireList={issuesHandleRequestSort}
              fixedHeader
            />
            <Button title='Report Issue' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal('issueForTask')}/>
          </>
        );
      case "tasks":
        return (
          <>
            <Table
              data={tasksTableData}
              headers={tasksHeadCells}
              onRequireList={tasksHandleRequestSort}
              link={true}
              fixedHeader
            />
            <Button title='Add Task' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal('tasks')}/>
          </>
        );
      case "documents":
        return (
          <div>
            <p className='tab-details-title'>Documents</p>
            {activeMilestone.media.map((file, i) =>
              <div
                className="file-preview file-preview--download"
                key={i}
              >
                <div className="file-preview-info">
                  <div style={{ marginBottom: 5 }}>{file.media_name || file.field_name}</div>
                  <p>{fileSize(file.size)} | {file.file_type}</p>
                </div>
                <div className='file-preview-btns'>
                  <span
                    className="file-upload"
                    onClick={() => dispatch({ type: DELETE_MEDIA_REQUEST, payload: {auth: userData, file, id}, callback: () => setActiveMilestone({...activeMilestone, media: activeMilestone.media.filter(item => item.media_reference_id !== file.media_reference_id)}) })}>
                    <DeleteIcon />
                  </span>
                  <span className="file-upload" onClick={() => dispatch({ type: DOWNLOAD_MEDIA_REQUEST, payload: {auth: userData, file} })}>
                    <DownloadIcon />
                  </span>
                </div>
              </div>
            )}
          </div>
        );
      case "details":
        const dateOptions = {
          day: "2-digit",
          month: 'short',
          year: "numeric",
        }

        const timeOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }

        return (
          <div className='details'>
            <div className='tab-details-info'>
              <p className='tab-details-title'>Additional information</p>
              <div className='survey-modal-progress'>
                <span
                  className={`status ${getStatusClassname(activeMilestone.task_status)}`}
                  onClick={(e) => {
                    if(activeMilestone.parent_id && checkRights && !checkStatuses){
                      setAnchorStatusEl(e.currentTarget);
                    }
                  }}
                >
                  {activeMilestone.task_status}
                </span>
              </div>

              <ul className='tab-details-list'>
                {dates.map((item, i) =>
                  <li key={i} className='tab-details-item'>
                    <div className='tab-details-caption'>{item.title}</div>
                    <div className='tab-details-date'>
                      {item.editable && !!activeMilestone.parent_id && checkRights && !checkStatuses
                        ? <div className='tab-details-picker' onClick={(e) => {
                            setOpenedDatePicker({
                              anchorEl: e.currentTarget,
                              row: activeMilestone,
                              fieldName: item.fieldName,
                              type: item.type
                            });
                          }}>
                            {activeMilestone[item.field] ? new Date(activeMilestone[item.field]).toLocaleString('en-GB', dateOptions) : 'Add'}
                          </div>
                        : activeMilestone[item.field] ? new Date(activeMilestone[item.field]).toLocaleString('en-GB', dateOptions) : ''
                      }
                      <span>
                        {activeMilestone[item.field] && new Date(activeMilestone[item.field]).toLocaleString('en-GB', timeOptions)}
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className='tab-details-info'>
              <p className='tab-details-title'>Stakeholders ({activeMilestone.stakeholder.length})</p>
              <div className='tab-details-stakeholders'>
                {activeMilestone.stakeholder.map((item, i) =>
                  <div className='tab-details-stakeholder' key={i}>
                    {item.role_name}
                  </div>
                )}
              </div>
            </div>

            {openedDatePicker.anchorEl && (
              <DateCalendarField
                onClose={() => setOpenedDatePicker({})}
                openedDatePicker={openedDatePicker}
                activeMilestone={activeMilestone}
                setActiveMilestone={setActiveMilestone}
              />
            )}
          </div>
        );
      case "chat":
        return (
          <div
            className={`tab-pane fade ${tab === "chat" ? "in active" : ""}`}
            id='chat'
            role='tabpanel'
            aria-labelledby='chat-tab'>
            Chat
          </div>
        );
      case "changelog":
        return <Changelog data={activeMilestone.changelog}/>

      default:
        break;
    }
  };
  return (
    <div className='survey-modal'>
      <div className='survey-modal-header'>
        <div className='survey-modal-header__left'>
          <div onClick={() => setFullPage(!isFullPage)}>
            <ExpandIcon/>
          </div>
          <span>Open in {isFullPage ? "modal" : "page"}</span>
        </div>

        <div className='survey-modal-header__right'>
          {(isPMOTeam || isAssignedPM) && (['Approved', 'Rejected'].includes(activeMilestone.task_status) || (activeMilestone.task_status === 'Completed' && !activeMilestone.requires_approval)) && (
            <Button title='Re-open' color={BUTTON_COLORS.DEFAULT} onClick={() => setApproveState({stage: 'Re-Opened'})}/>
          )}
          {activeMilestone.task_status === 'Completed' && activeMilestone.requires_approval && activeMilestone.can_approve && (
            <>
              <Button title='Reject' color={BUTTON_COLORS.RED} onClick={() => setApproveState({stage: 'Rejected'})}/>
              <Button title='Approve' color={BUTTON_COLORS.GREEN} onClick={() => setApproveState({stage: 'Approved'})}/>
            </>
          )}

        {/*  <button onClick={() => changeModal('initiation')} className='action-btn'>*/}
        {/*    Initiate Feasibility Survey*/}
        {/*  </button>*/}
        {/*  <div className='action-links' onClick={() => changeModal('forecast')}>*/}
        {/*    <CalendarIcon/>*/}
        {/*    <span>Reforcast</span>*/}
        {/*  </div>*/}
        {/*  <div className='action-links'>*/}
        {/*    <MoreIcon/>*/}
        {/*  </div>*/}
        </div>
      </div>
      <div className='survey-modal-title'>{activeMilestone.task_name}</div>
      <div className='survey-modal-info'>
        <span className='title'>Actual Start:</span> {getHumanDate(activeMilestone.actual_start_date)}
        <span className='title'>Actual End:</span> {getHumanDate(activeMilestone.actual_end_date)}
        <span className='survey-modal-info__divider' />
        <span className='title'>Assigned to:</span>
        <UserIcon/>
        {activeMilestone.owner.user_name}
      </div>
      <div className='survey-modal-tabs'>
        <div className="tabs">
          <Tabs
            value={tab}
            onChange={(_, newValue) => setTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            orientation="horizontal"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="Details" value="details"/>
            <Tab label="Documents" value="documents"/>
            <Tab label="Issues" value="issues"/>
            {!activeMilestone.parent_id && <Tab label="Tasks" value="tasks"/>}
            <Tab label="Chat" value="chat" icon={<ChatIcon/>} iconPosition="start" style={{marginLeft: 'auto'}}/>
            <Tab label="Changelog" value="changelog" icon={<ChangelogIcon/>} iconPosition="start"/>
          </Tabs>
        </div>
        <div className='tab-content'>{tabContent()}</div>
      </div>

      <Menu
        className='menu'
        anchorEl={anchorStatusEl}
        open={Boolean(anchorStatusEl)}
        onClose={() => setAnchorStatusEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        {lookup?.['Task Status'].map(item => item.name !== 'Pending' &&
          <MenuItem key={item.id} onClick={() => changeTaskStatus(item)}>{item.name}</MenuItem>
        )}
      </Menu>

      {approveState && (
        <ConfirmModal
          onBackgroundClick={() => setApproveState(null)}
          open={Boolean(approveState)}
          onSubmitClick={saveTaskApproval}
          title={approveState.stage}
          subTitle='This action is permanent and can’t be undone'
          remarksOptional={approveState.is_approved}
          withOutRemarks={approveState.stage === 'Approved'}
          remarksText={approveState.remarksText || ''}
          setRemarksText={(value) => setApproveState({...approveState, remarksText: value})}
          additionalDisable={approveState.stage === 'Rejected' && !!approveState.rejectionReason}
        >
          <>
            {approveState.stage === 'Rejected' && (
              <div className="modal-row modal-field modal-select">
                <h6>Rejection reason</h6>
                <Select
                  value={approveState.rejectionReason || ''}
                  variant="outlined"
                  onChange={e => setApproveState({...approveState, rejectionReason: e.target.value})}
                >
                  <MenuItem className='hiddenOption' key="0" disabled value='none' >Not selected</MenuItem>
                  {lookup?.['Milestone Rejection Reason'].map(item =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  )}
                </Select>
              </div>
            )}
          </>
        </ConfirmModal>
      )}
    </div>
  );
};

export default SurveyModal;