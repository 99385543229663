import {isDevelopment} from "../env";

export const to = (path) => {
  if (isDevelopment()) {
    return `/${path}`;
  }

  return process.env.REACT_APP_ENDPOINT ? `${process.env.REACT_APP_ENDPOINT}/${path}` : `/${path}`;
};

export const getOptions = (data) => {
  const headers = {
    Authorization: `Bearer ${data.auth.access_token}`,
  }

  return { headers };
};
