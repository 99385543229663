import React, {useState} from "react";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/Modal/Modal";
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import {SAVE_MILESTONE_TEMPLATE_REQUEST} from "store/actions/project";

const AddProjectTemplateModal = ({onBackgroundClick, currentProject}) => {

  const [state, setState] = useState({
    template_name: currentProject?.name || '',
  })

  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.project.modalLoading);

  const handleSubmit = () => {
    const data = state;
    if(currentProject){
      data.template_id = currentProject.id;
    }
    dispatch({
      type: SAVE_MILESTONE_TEMPLATE_REQUEST,
      payload: data,
      callback: onBackgroundClick
    })
  }

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  return (
    <Modal
      className='small-width'
      onBackgroundClick={onBackgroundClick}
    >
      <div className='initiation-modal'>
        <h3>{currentProject ? 'Edit' : 'Add'} project template</h3>
        <ValidatorForm onSubmit={handleSubmit} className='modal-form'>
          <div className="modal-row modal-field">
            <h6>Template name</h6>
            <TextValidator
              value={state.template_name}
              name='template_name'
              type="text"
              placeholder='Template name'
              variant="outlined"
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </div>

          <div className='modal-btns'>
            <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={onBackgroundClick}/>
            <Button title='Submit' color={BUTTON_COLORS.DEFAULT} disabled={modalLoading}/>
          </div>
      </ValidatorForm>
    </div>
    </Modal>
  );
};

export default AddProjectTemplateModal;
