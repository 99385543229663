import {auth} from "./drivers/auth";
import {project} from "./drivers/project";
import {common} from "./drivers/common";
import {site} from "./drivers/site";
import {dashboard} from "./drivers/dashboard";
import {boards} from "./drivers/boards";
import {userManagement} from "./drivers/userManagement";

export const Api = {
  auth,
  project,
  common,
  site,
  dashboard,
  boards,
  userManagement,
};
