import React, {useEffect, useState} from 'react';
import {Table} from "components/Table";
import Skeleton from "components/common/Skeleton";
import {useDispatch, useSelector} from "react-redux";
import useTable from "hooks/useTable";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import AddUserModal from "./AddUserModal";
import {EditIcon} from "components/Icons";
import {GET_USERS_LIST_REQUEST} from "store/actions/userManagement";

const UserManagement = () => {

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.userManagement.loading);
  const usersList = useSelector((state) => state.userManagement.usersList);
  const userData = useSelector(state => state.auth.user);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const isAdmin = userData.is_admin;
  const [currentUser, setCurrentUser] = useState(null);

  const {tableData, handleRequestSort} = useTable(usersList);

  useEffect(() => {
    dispatch({type: GET_USERS_LIST_REQUEST, payload: {role: null}})
  }, [dispatch]);

  const headCells = [
    {attr: "first_name", title: "First Name"},
    {attr: "middle_name", title: "Middle Name"},
    {attr: "last_name", title: "Last Name"},
    {attr: "email", title: "email"},
    {attr: "role_name", title: "role"},
    {attr: "is_active", title: "Is Active"},
    {attr: "is_admin", title: "Is Admin"},
    {attr: "requires_2fa", title: "Requires 2FA"},
    {attr: "must_change_password", title: "Must Change Password"},
    ...isAdmin ? [{attr: "Actions", title: ""}] : [],
  ];


  const options = {

    Actions: {
      styles: { minWidth: "40px", maxWidth: "40px" },
      className: 'actions'
    }
  };

  const transformers = {
    is_active: (row) => row.is_active ? 'Y' : 'N',
    is_admin: (row) => row.is_admin ? 'Y' : 'N',
    requires_2fa: (row) => row.requires_2fa ? 'Y' : 'N',
    must_change_password: (row) => row.must_change_password ? 'Y' : 'N',
    Actions: (row) => {
      if(isAdmin){
        return (
          <div className='actions__wrap' onClick={(e) => {
            e.stopPropagation();
            setIsAddUserModalOpen(true);
            setCurrentUser(row);
          }}>
            <EditIcon/>
          </div>
        )
      }
    }
  }

  return (
    <div className='UserManagement'>
      <h1 className='page-title'>User Management</h1>
      {!loading
        ? <Table
          data={tableData}
          options={options}
          headers={headCells}
          onRequireList={handleRequestSort}
          link='/pdt/user-management'
          transformers={transformers}
          extraFilters={
            isAdmin && (
              <Button
                type="button"
                color={BUTTON_COLORS.DEFAULT}
                title='Add User'
                style={{marginRight: 10 }}
                onClick={() => setIsAddUserModalOpen(true)}
              />
            )
          }
        />
        : <Skeleton/>
      }
      {isAddUserModalOpen && (
        <AddUserModal
          onBackgroundClick={() => {
            setIsAddUserModalOpen(false);
            setCurrentUser(null);
          }}
          currentUser={currentUser}
        />
      )}

    </div>
  );
};

export default UserManagement;