import React, {useState} from "react";
import { Table } from "components/Table";
import useTable from "hooks/useTable";
import {getHumanDate, getStatusClassname} from "helpers";
import {MoreIcon} from "components/Icons";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import classNames from "classnames";
import DateRangeCalendarField from "../DateCalendarField/DateRangeCalendarField";
import {EDIT_TASKS, SAVE_TASK_REQUEST} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {SET_NOTIFICATION_MESSAGE} from "store/actions/notification";

const headCells = [
  { attr: "task_id", title: "№" },
  { attr: "task_name", title: "Task Name" },
  { attr: "task_status", title: "Status" },
  { attr: "actual_start_date", title: "Actual" },
  { attr: "forecast_start_date", title: "Forecast" },
  { attr: "plan_start_date", title: "Plan" },
  { attr: "owner", title: "Submitter" },
  { attr: "waiting_for", title: "Waiting for" },
  { attr: "Actions", title: "" },
];

export const MilestonesContent = ({
  setActiveMilestone,
  activeMilestone,
  loading,
  milestoneList,
  setProjectModal,
  id,
  activityDetails
}) => {

  const [anchorData, setAnchorData] = useState({})
  const [openedDatePicker, setOpenedDatePicker] = useState({});
  const userData = useSelector((state) => state.auth.user);
  const lookup = useSelector((state) => state.common.lookup);
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(true);
  const [editedTasks, setEditedTasks] = useState([]);

  const actionsHandler = (modal) => {
    setProjectModal(modal);
    setAnchorData({});
  }

  const findAndChangeTask = (tasks, taskId, updatedField) => {
    return tasks.map(task => {
      if (task.task_id === taskId) {
        return { ...task, ...updatedField };
      } else if (task.children) {
        return { ...task, children: findAndChangeTask(task.children, taskId, updatedField) };
      }
      return task;
    });
  }

  const validateTasks = (tasks) => {
    for (const task of tasks) {
      if (task.new_task_status === 'Completed' && ((!task.actual_start_date && !task.new_actual_start_date) || (!task.actual_end_date && !task.new_actual_end_date))) {
        return task;
      }
      if (task.children) {
        const childTask = validateTasks(task.children);
        if (childTask) {
          return childTask;
        }
      }
    }
    return undefined;
  };


  const changeStatus = (value) => {
    if(editMode){
      dispatch({type: EDIT_TASKS, payload: findAndChangeTask(milestoneList, activeMilestone.task_id, {new_task_status: value.name})});
      const index = editedTasks.findIndex(item => activeMilestone.task_id === item.id);
      if(index !== -1){
        setEditedTasks([...editedTasks.slice(0, index), {...editedTasks[index], status: value.id}, ...editedTasks.slice(index + 1)])
      }else {
        setEditedTasks([...editedTasks, {id: activeMilestone.task_id, status: value.id}]);
      }
      setAnchorData({});
    }else {
      if (value.name === 'Completed' && (!activeMilestone.actual_start_date || !activeMilestone.actual_end_date)) {
        dispatch({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "You must specify Actual Start and Actual End dates", snackbarSeverity: "error", openSnackbar: true}})
      }else{
        dispatch({
          type: SAVE_TASK_REQUEST,
          payload: {
            auth: userData,
            id: activeMilestone.activity_id,
            body: [{id: activeMilestone.task_id, status: value.id}]
          },
        })
      }
    }

  }

  const isPMOTeam = userData.role === 'PMO';
  const isAssignedPM = userData.user_name === activityDetails.pm_user_name;
  const checkStatuses = (item) => {
    const row = item ?? {};
    if(row.task_status === 'Approved'){
      return true;
    }
    if(row.task_status === 'Rejected'){
      return isPMOTeam || isAssignedPM;
    }
    if(row.task_status === 'Completed' && !row.requires_approval){
      return userData.user_id === row.owner.user_id;
    }
    return false;
  }

  const checkRights = (item) => (isPMOTeam || isAssignedPM || userData.user_id === item.owner.user_id);
  const checkParent = (item) => item.parent_id || (!item.parent_id && !item.children.length);

  const transformers = {
    task_status: (row) => {
      const value = editMode ? row.new_task_status || row.task_status : row.task_status;
      return (
        <span
          className={classNames(`status ${getStatusClassname(value)}`, {
            edited: editMode && row.new_task_status && row.new_task_status !== row.task_status
          })}
          onClick={(e) => {
            if(checkParent(row) && checkRights(row) && !checkStatuses(row)){
              setAnchorData({anchorEl: e.currentTarget, open: 'status'});
              setActiveMilestone(row);
            }
          }}
        >
        {value}
      </span>
      )
    },
    actual_start_date: (row) => {
      const isEditable = !!checkParent(row) && checkRights(row) && !checkStatuses(row);
      const startDate = editMode ? row.new_actual_start_date || row.actual_start_date : row.actual_start_date;
      const endDate = editMode ? row.new_actual_end_date || row.actual_end_date : row.actual_end_date;
      return (
        <div
          style={{display: 'flex', gap: '8px'}}
        >
        <span
          className={classNames({
            'editable-date-cell': isEditable,
            'empty': !startDate && !endDate,
            edited: editMode && (
              (row.new_actual_start_date && row.new_actual_start_date !== row.actual_start_date) ||
              (row.new_actual_end_date && row.new_actual_end_date !== row.actual_end_date)
            )
          })}
          onClick={(e) => {
            if(isEditable){
              setOpenedDatePicker({anchorEl: e.currentTarget, row, fieldName: 'actual', type: 'start'});
            }
          }}>
          {startDate ? getHumanDate(startDate) : !isEditable ? '' : 'Add'}
          {startDate && ' - '}
          {startDate && endDate ? getHumanDate(endDate) : !isEditable || !startDate ? '' : 'N/A'}
        </span>
        </div>
      )
    },
    forecast_start_date: (row) => {
      const isEditable = !!checkParent(row) && checkRights(row) && !checkStatuses(row);
      const startDate = editMode ? row.new_forecast_start_date || row.forecast_start_date : row.forecast_start_date;
      const endDate = editMode ? row.new_forecast_end_date || row.forecast_end_date : row.forecast_end_date;
      return (
        <div
          style={{display: 'flex', gap: '8px'}}
        >
        <span
          className={classNames({
            'editable-date-cell': isEditable,
            'empty': !startDate && !endDate,
            edited: editMode && (
              (row.new_forecast_start_date && row.new_forecast_start_date !== row.forecast_start_date) ||
              (row.new_forecast_end_date && row.new_forecast_end_date !== row.forecast_end_date)
            )
          })}
          onClick={(e) => {
            if(isEditable){
              setOpenedDatePicker({anchorEl: e.currentTarget, row, fieldName: 'forecast', type: 'start'});
              // setScrollElPosition(`row-${row.task_id}`);
            }
          }}>
          {startDate ? getHumanDate(startDate) : !isEditable ? '' : 'Add'}
          {startDate && ' - '}
          {startDate && endDate ? getHumanDate(endDate) : !isEditable || !startDate ? '' : 'N/A'}
        </span>
        </div>
      )
    },
    plan_start_date: (row) => (
      <div
        style={{display: 'flex', gap: '8px'}}
      >
        <span>
          {getHumanDate(row.plan_start_date)}
        </span>
        <span>
          {getHumanDate(row.plan_end_date)}
        </span>
      </div>
    ),
    owner: (row) => row.owner.user_name,
    Actions: (row) => {
      const isParent = !row.parent_id;
      // if(isParent || row.task_status !== 'Pending' || !isParent){
      if(isParent || checkRights(row) || row.can_approve){
        return (
          <div className='actions__wrap' onClick={(e) => {
            e.stopPropagation();
            setAnchorData({anchorEl: e.currentTarget, open: 'actions'});
            setActiveMilestone(row);
            // setScrollElPosition(`row-${row.task_id}`);
          }}>
            <MoreIcon/>
          </div>
        )
      }
    }
  };

  const {tableData, handleRequestSort} = useTable(milestoneList);

  const options = {
    task_id: {
      styles: { minWidth: "60px", flex: 0 },
    },
    task_name: {
      styles: { minWidth: "300px" },
      className: 'taskName'
    },
    forecast_start_date: {
      styles: { minWidth: "200px" },
    },
    actual_start_date: {
      styles: { minWidth: "200px", overflow: 'visible' },
    },
    plan_start_date: {
      styles: { minWidth: "200px" },
    },
    Actions: {
      styles: { minWidth: "40px", maxWidth: "40px" },
      className: 'actions'
    },
  };

  return (
    <div className='invoices-page'>
      <h2 className='subTitle'>Tasks</h2>
      {!loading ? (
        <Table
          title='Activities'
          data={tableData}
          options={options}
          transformers={transformers}
          headers={headCells}
          onRequireList={handleRequestSort}
          link={true}
          fixedHeader
          extraFilters={(
            <>
              {isPMOTeam && (
                <Button
                  type="button"
                  color={BUTTON_COLORS.DEFAULT}
                  title='Assign'
                  style={{ marginRight: 10 }}
                  onClick={() => actionsHandler('initiation')}
                />
              )}
              {(isPMOTeam || isAssignedPM) && (
                <Button
                  type="button"
                  color={BUTTON_COLORS.DEFAULT}
                  title={`${editMode ? 'Review' : 'Edit'} mode`}
                  style={{ marginRight: 10 }}
                  onClick={() => setEditMode(!editMode)}
                />
              )}
              {!!editedTasks.length && editMode && (
                <Button
                  type="button"
                  color={BUTTON_COLORS.GREEN}
                  title='Save'
                  style={{marginRight: 10 }}
                  onClick={() => {
                    const hasUnfilledFields = validateTasks(milestoneList);
                    if(hasUnfilledFields){
                      dispatch({type: SET_NOTIFICATION_MESSAGE, payload: {snackbarMessage: "You must specify Actual Start and Actual End dates", snackbarSeverity: "error", openSnackbar: true}})
                    }else{
                      dispatch({
                        type: SAVE_TASK_REQUEST,
                        payload: {id, body: editedTasks},
                      })
                    }
                  }}
                />
              )}

            </>
          )}
        />
      ) : (
        <h1>Loading...</h1>
      )}
      <Menu
        className='menu'
        anchorEl={anchorData.anchorEl}
        open={anchorData.open === 'actions'}
        onClose={() => setAnchorData({})}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        {(isPMOTeam || isAssignedPM) && !checkStatuses(activeMilestone) && <MenuItem onClick={() => actionsHandler('initiation')}>Assign Submitter and Stakeholders</MenuItem>}
        {/*<MenuItem onClick={() => actionsHandler('forecast')}>Set dates</MenuItem>*/}
        {activeMilestone?.parent_id && checkRights(activeMilestone) && !checkStatuses(activeMilestone) && <MenuItem onClick={() => actionsHandler('documents')}>Add documents</MenuItem>}
        <MenuItem onClick={() => actionsHandler('survey')}>{!activeMilestone?.parent_id ? 'Review' : 'See details'}</MenuItem>
      </Menu>
      <Menu
        className='menu'
        anchorEl={anchorData.anchorEl}
        open={anchorData.open === 'status'}
        onClose={() => setAnchorData({})}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        {lookup?.['Task Status'].map(item => item.name !== 'Pending' &&
          <MenuItem key={item.id} onClick={() => changeStatus(item)}>{item.name}</MenuItem>
        )}
      </Menu>

      {openedDatePicker.anchorEl && (
        <DateRangeCalendarField
          onClose={() => setOpenedDatePicker({})}
          openedDatePicker={openedDatePicker}
          activeMilestone={activeMilestone}
          setActiveMilestone={setActiveMilestone}
          editMode={editMode}
          editModeFunc={(value) => {
            const {row, fieldName} = openedDatePicker;
            const updatedFields = {[`${fieldName}_start_date`]: value.start, [`${fieldName}_end_date`]: value.end};
            dispatch({type: EDIT_TASKS, payload: findAndChangeTask(milestoneList, row.task_id, {[`new_${fieldName}_start_date`]: value.start, [`new_${fieldName}_end_date`]: value.end})});
            const index = editedTasks.findIndex(item => row.task_id === item.id);
            if(index !== -1){
              setEditedTasks([
                ...editedTasks.slice(0, index),
                {...editedTasks[index], ...updatedFields},
                ...editedTasks.slice(index + 1)
              ])
            }else {
              setEditedTasks([...editedTasks, {id: row.task_id, ...updatedFields}]);
            }
          }}
        />
      )}
    </div>
  );
};
