import React, {useState, useRef} from 'react';
import PropTypes from "prop-types";
import MyButton from 'components/common/Button/Button';
import './DropDown.scss';
import classNames from "classnames";
import close from 'assets/icons/table/close.svg';
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";

const DropDown = ({title, listItems, icon, IconNode, withoutArrow, name, filtersState, onChange}) => {

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState(20);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
	}

	return (
		<div className='dropDown'>
			<Button
				className={classNames({filtered: filtersState && !!filtersState[name].length})}
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<p>{title}</p>
				{icon && <img src={icon} alt=""/>}
				{IconNode && <IconNode/>}
				{!withoutArrow && <div className='arrow-down' />}
				{filtersState && !!filtersState[name].length && (
					<img
						className="clear"
						src={close}
						onClick={(e) => {
							e.stopPropagation();
							onChange(name, true);
						}}
						alt=''
					/>
				)}
			</Button>
			<Popper open={open} anchorEl={anchorRef.current} placement='bottom' transition disablePortal style={{zIndex: '1'}}>
				{({ TransitionProps }) => (
					<Grow{...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
									{Array.isArray(listItems)
										? <div className='dropDown-items'>
												{listItems?.slice(0, visibleItems).map(item => {
													const itemName = item.name ? item.name : item;
													const isChecked = filtersState[name].includes(itemName);
													return (
														<MenuItem key={itemName}>
															<label className="checkbox">
																<input
																	type="checkbox"
																	checked={isChecked}
																	onChange={() => onChange(name, false, isChecked, itemName)} />
																{item && <span>{itemName}</span>}
															</label>
														</MenuItem>
													)}
												)}
												{listItems?.length > visibleItems && (
													<MyButton color='ongoing' title='Load more' onClick={() => setVisibleItems(visibleItems + 20)}/>
												)}
											</div>
										: Object.entries(listItems).map(([name, func]) =>
												<MenuItem
													key={name}
													onClick={() => {
														func();
														setOpen(false);
													}}
												>{name}</MenuItem>
											)
									}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
};

DropDown.propTypes = {
	title: PropTypes.string,
	listItems: PropTypes.any,
};

export default DropDown;