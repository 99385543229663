import {useState} from "react";
import {getComparator, stableSort} from "../helpers";

const useTable = (data) => {
  const [order, setOrder] = useState('DESC');
  const [orderBy, setOrderBy] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({});

  const handleRequestSort = (property) => {
    setSearchValue(property.query.search.toLowerCase());
    setFilters(property.query.filters);
    setOrder(property.query.orderBy.type === 'ASC' ? 'DESC' : 'ASC');
    setOrderBy(property.query.orderBy.field);
  };

  const tableData = stableSort(data, getComparator(order, orderBy), filters, searchValue);

  return {
    tableData,
    handleRequestSort
  }
};

export default useTable;