import {
  GET_USER_DETAILS_FAIL,
  GET_USER_DETAILS_REQUEST, GET_USER_DETAILS_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS, RESET_EMAIL_FAIL,
  RESET_EMAIL_REQUEST,
  RESET_EMAIL_SUCCESS,
  SAVE_USER_FAIL,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS, TOGGLE_USER_AVAILABILITY_SUCCESS,
} from "../actions/userManagement";

const initialState = {
  loading: false,
  modalLoading: false,
  usersList: [],
  userDetails: {}
};

export default function userManagement(state = initialState, action) {
  switch (action.type) {

    case GET_USERS_LIST_REQUEST:
    case RESET_EMAIL_REQUEST:
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USERS_LIST_FAIL:
    case RESET_EMAIL_SUCCESS:
    case RESET_EMAIL_FAIL:
    case GET_USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };

    case SAVE_USER_REQUEST:
      return {
        ...state,
        modalLoading: true,
      };

    case SAVE_USER_SUCCESS:
    case SAVE_USER_FAIL:
      return {
        ...state,
        modalLoading: false,
      };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };

    case TOGGLE_USER_AVAILABILITY_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          is_active: !state.userDetails.is_active
        },
      };



    default:
      return state;
  }
}
