import axios from "axios";
import {getOptions} from "../../helpers/api";
import axiosInstance from "../services/axiosInstance";

export const boards = {

  getBoardTickets: (data) => {
    return axiosInstance.post('Ticket/GetTickets', data.body);
  },

  createTicket: (data) => {
    return axiosInstance.post('Ticket/SaveTicket', data.body);
  },

  removeTicket: (data) => {
    const { params, headers } = getOptions(data);
    return axios.post('helpdeskdashboard/remove/ticket', params, {headers});
  },

  addTicketRemarks: (data) => {
    return axiosInstance.post('Ticket/AddTicketRemarks', data.body);
  },

  getTicketDetails: (data) => {
    return axiosInstance.get(`Ticket/GetTicketDetails/${data.id}`);
  },

};