import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import {ADD_TICKET_REMARKS_REQUEST, GET_TICKET_DETAILS_REQUEST} from "store/actions/boards";
import './TicketSupportModal.scss';
import {COLUMN_TITLES} from "../../../../constants";
import Loader from "components/common/Loader";
import {Backdrop, Fade, Modal, Tab, Tabs, TextareaAutosize} from "@mui/material";
import {AttachIcon, AuditIcon, BookIcon, CalendarIcon, ArrowLeftIcon, InfoIcon} from "components/Icons";
import {getHumanDate} from "../../../../helpers";

const TicketSupportModal = ({open, setIsModalOpen, currentTicket, openAssignModal, openUpdateModal, setUpdateAction}) => {

  const {ticket, column} = currentTicket;
  const ticketDetails = useSelector(state => state.boards.ticketDetails);
  const [state, setState] = useState({});
  const [tabId, setTabId] = useState(0);
  const auth = useSelector(state => state.auth);
  const userData = useSelector((state) => state.auth.user);
  const modalsLoading = useSelector(state => state.boards.modalsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_TICKET_DETAILS_REQUEST,
      payload: {auth: userData, id: ticket.id}
    });
  }, []); // eslint-disable-line

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const addComment = () => {
    dispatch({
      type: ADD_TICKET_REMARKS_REQUEST,
      payload: {
        body: {ticket_id: ticket.id, remarks: state.comment_new},
        auth: userData
      },
      callback: () => dispatch({
        type: GET_TICKET_DETAILS_REQUEST,
        payload: {auth: userData, id: ticket.id}
      })
    });
    setState({});
  };

  const getBtns = () => {
    let btns = [];
    if(column === 'New'){
      btns = [
        {
          title: 'Assign to myself',
          color: BUTTON_COLORS.GRAY,
          action: () => closeModal(openAssignModal(ticket, column, true))
        },
        {
          title: 'Assign to Support Group',
          color: BUTTON_COLORS.DEFAULT,
          action: () => closeModal(openAssignModal(ticket, column, false))
        },
      ];
    }
    if(column === 'Assigned') {
      btns = [
        {
          title: 'Close',
          color: BUTTON_COLORS.GRAY,
          action: () => {
            closeModal(openUpdateModal(true));
            setUpdateAction('Closed');
          }
        },
        {
          title: 'Re-assign',
          color: BUTTON_COLORS.GRAY,
          action: () => closeModal(openAssignModal(ticket, column))
        },
      ];
      if(userData.user_id !== ticketDetails.assigned_to_user_id && auth.username !== 'admin') return btns;
      btns = [...btns, {
        title: 'Start work',
        color: BUTTON_COLORS.DEFAULT,
        action: () => {
          closeModal(openUpdateModal(true));
          setUpdateAction('In Progress');
        }
      }]
    }

    if (column === 'In Progress') {
      btns = [
        {
          title: 'Put on Hold',
          color: BUTTON_COLORS.GRAY,
          action: () => {
            closeModal(openUpdateModal(true));
            setUpdateAction('On Hold');
          }
        },
        {
          title: 'Re-assign',
          color: BUTTON_COLORS.GRAY,
          action: () => closeModal(openAssignModal(ticket, column))
        },
        {
          title: 'Mark as Done',
          color: BUTTON_COLORS.DEFAULT,
          action: () => {
            closeModal(openUpdateModal(true));
            setUpdateAction('Resolved');
          }
        }
      ];
    }

    if (column === 'On Hold') {
      btns = [
        {
          title: 'Unhold',
          color: BUTTON_COLORS.GRAY,
          action: () => {
            closeModal(openUpdateModal(true));
            setUpdateAction('In Progress');
          }
        },
      ];
    }

    return btns;
  };

  const openBase64 = (item) => {
    let fileWindow = window.open("");
    const src = `data:${item.media_type};base64,${encodeURI(item.base64_content)}`;
    fileWindow.document.write(`<iframe width='100%' height='100%' src=${src}></iframe>`);
    setTimeout(() => fileWindow.stop() , 1000);
  };

  const getSkeleton = () => {
    return (
      <Loader height={'80vh'} width='100%'>
        <rect x="0" y="12%" rx="0" ry="0" width="58%" height="8%" />
        <rect x="0" y="23%" rx="0" ry="0" width="58%" height="20%" />
        <rect x="0" y="46%" rx="0" ry="0" width="58%" height="54%" />
        <rect x="60%" y="0" rx="0" ry="0" width="40%" height="8%" />
        <rect x="60%" y="11%" rx="0" ry="0" width="40%" height="20%" />
        <rect x="60%" y="34%" rx="0" ry="0" width="40%" height="66%" />
      </Loader>
    )
  };

  return (
    <Modal
      className='modal-wrapper support-modal'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
        style: {opacity: 0}
      }}
    >
      <Fade in={open}>
        <div className='modal-content'>
          {!modalsLoading
            ? <>
                <div className="modal-btns">
                  {getBtns().map(item =>
                    <Button
                      key={item.title}
                      type="button"
                      title={item.title}
                      color={item.color}
                      onClick={item.action}
                    />
                  )}
                </div>
                <div className="support-left">
                  <div className="modal-row row-padding">
                    <div className="modal-field">
                      <div className='columnTitle'>
                        <span className={`color-${COLUMN_TITLES.findIndex(item => item.key === column)}`}>{column}</span>
                        {ticketDetails.helpdesk_id}
                      </div>
                    </div>
                  </div>
                  <div className="modal-row row-padding">
                    <div className="modal-row-icon">
                      <BookIcon/>
                    </div>
                    <div className="modal-field">{ticketDetails.description}</div>
                  </div>
                  {!!ticketDetails.media?.length && (
                    <div className="modal-row modal-file row-padding">
                      <div className="modal-row-icon">
                        <AttachIcon/>
                      </div>
                      <div className="modal-field">
                        <h5>Files Attached</h5>
                        {ticketDetails.media.map((item, index) =>
                          <div className="file-preview" key={`${item.media_name}-${index}`}>
                            <div onClick={() => openBase64(item)}>{item.media_name}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="modal-row row-padding">
                    <div className="modal-row-icon">
                      <AuditIcon/>
                    </div>
                    <div className="modal-field">
                      <Tabs
                        value={tabId}
                        onChange={(e, id) => setTabId(id)}
                      >
                        <Tab label="Comments" disableRipple={true}/>
                        <Tab label="Activity" disableRipple={true}/>
                      </Tabs>

                      {tabId === 0 && (
                        <div>
                          {column !== 'Archived' && (
                            <div className="comment-area">
                              <TextareaAutosize
                                aria-label="minimum height"
                                minRows={1}
                                placeholder="Add comment..."
                                value={state.comment_new}
                                name='comment_new'
                                onChange={onHandleChange}
                              />
                              {state.comment_new && (
                                <div className="comment-btn" onClick={addComment}>
                                  <ArrowLeftIcon/>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="modal-row">
                            {ticketDetails.remarks?.map((item, index) =>
                              <div key={index} className='comment'>
                                <div className="comment-icon">
                                  <span>{item.created_by_user.slice(0, 2)}</span>
                                </div>
                                <div className='comment-header'>
                                  {item.created_by_user} <span>{item.CreatedOn}</span>
                                </div>
                                <div className='comment-body'>
                                  <p>{item.remarks}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {tabId === 1 && (
                        <div className="modal-row activity">
                          {ticketDetails.activity?.map((item, index) =>
                            <div key={index} className="activity-item">
                              <div className='activity-type'>{item.type}</div>
                              <div className='activity-user'>{item.user}</div>
                              <div className='activity-date'>{item.create_date}</div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="support-right">
                  <div className="modal-row row-padding">
                    <div className="modal-row-icon">
                      <CalendarIcon/>
                    </div>
                    <div className="row-title">
                      Start/End Dates
                      <div className="row-item">
                        <div className='row-key'>Created</div>
                        <div className='row-item'>{getHumanDate(ticketDetails.date_created)}</div>
                      </div>
                    </div>
                    <div className="row-dates">
                      {ticketDetails.start_date
                        ? <span className='date-selected'>{getHumanDate(ticketDetails.start_date)}</span>
                        : <span>Start date not set</span>
                      }
                      {ticketDetails.due_date
                        ? <span className='date-selected'>{getHumanDate(ticketDetails.due_date)}</span>
                        : <span>End date not set</span>
                      }
                    </div>
                  </div>
                  <div className="modal-row row-padding general">
                    <div className="modal-row-icon">
                      <InfoIcon/>
                    </div>
                    <div className="row-title">General information</div>
                    <div className="row-item">
                      <div className="row-key">Created by</div>
                      <div className="row-value">{ticketDetails.created_by_user}</div>
                    </div>
                    {ticketDetails.affectedPerson && (
                      <div className="row-item">
                        <div className="row-key">Affected user</div>
                        <div className="row-value">{ticketDetails.affectedPerson.name} <span>{ticketDetails.affectedPerson.email}</span></div>
                      </div>
                    )}
                    {ticketDetails.assigned_to_user && (
                      <div className="row-item">
                        <div className="row-key">Assignee</div>
                        <div className="row-value">{ticketDetails.assigned_to_user}</div>
                      </div>
                    )}
                    <div className="row-item">
                      <div className="row-key">Category</div>
                      <div className="row-value">{ticketDetails.category}</div>
                    </div>
                    <div className="row-item">
                      <div className="row-key">Priority</div>
                      <div className={`row-value priority-${ticketDetails.priority}`}>{ticketDetails.priority}</div>
                    </div>
                  </div>
                </div>
              </>
            : getSkeleton()
          }

        </div>
      </Fade>
    </Modal>
  )
};

TicketSupportModal.propTypes = {
  open: PropTypes.bool,
  curLang: PropTypes.string,
  task: PropTypes.object,
};

export default TicketSupportModal;