import React from 'react';
import {Bar} from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import {dataLabelPlugin} from "helpers";

Chart.register(...registerables);


const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
  },
  layout: {
    padding: {
      top: 15
    }
  },
  scales : {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: false
      },
    }
  }
};

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 100, 50, 20, 33, 21, 43],
      backgroundColor: '#0579DF',
      pointRadius: 0,
      barThickness: 40
    },
  ],
};


const BarChart = () => {
  return (
    <div>
      <div className="chart">
        <div className="chart__header">
          <h5>Total Projects cost</h5>
        </div>
        <div className="chart__main">
          <Bar type='bar' data={data} options={options} plugins={[dataLabelPlugin]}/>
        </div>

      </div>
    </div>
  );
};

export default BarChart;