import React, {useEffect, useState} from "react";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {CREATE_ACTIVITY_BULK_REQUEST} from "store/actions/site";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/Modal/Modal";
import {GET_USERS_LIST_REQUEST} from "store/actions/userManagement";

const ReassignModal = ({onBackgroundClick, successCallback, selectedActivities}) => {

  const [state, setState] = useState('');
  const dispatch = useDispatch();
  const usersList = useSelector((state) => state.userManagement.usersList);
  const modalLoading = useSelector((state) => state.site.modalLoading);

  useEffect(() => {
    dispatch({type: GET_USERS_LIST_REQUEST, payload: {role: "NW PM"}})
  }, []); //eslint-disable-line

  const handleSubmit = () => {

    const data = selectedActivities.map(item => ({
      project_manager: state,
      object_id: item.object_id,
      project_id: item.project_id,
      unique_work_identifier: item.unique_work_identifier,
    }));

    dispatch({
      type: CREATE_ACTIVITY_BULK_REQUEST,
      payload: {
        body: data
      },
      callback: successCallback || onBackgroundClick
    })
  }

  return (
    <Modal
      className='small-width'
      onBackgroundClick={onBackgroundClick}
    >
    <div className='initiation-modal'>
      <h3>Reassign</h3>

      <div className="modal-field modal-select">
        <h6>Manager</h6>
        <Select
          value={state || 'none'}
          variant="outlined"
          onChange={e => setState(e.target.value)}
          displayEmpty={true}
        >
          <MenuItem className='hiddenOption' key="0" disabled value="none" >Not selected</MenuItem>
          {usersList?.map(item =>
            <MenuItem key={item.user_id} value={item.user_id}>{item.full_name}</MenuItem>
          )}
        </Select>
      </div>

      <div className='modal-btns'>
        <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={onBackgroundClick}/>
        <Button title='Submit' color={BUTTON_COLORS.DEFAULT} disabled={!state || modalLoading} onClick={handleSubmit}/>
      </div>
    </div>
    </Modal>
  );
};

export default ReassignModal;
