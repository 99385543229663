import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Projects.scss";
import {Table} from "components/Table";
import {GET_PROJECT_OPTIONS_REQUEST} from "store/actions/project";
import Skeleton from "components/common/Skeleton";
import useTable from "hooks/useTable";
import BulkUpload from "components/BulkUpload/BulkUpload";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import AddProjectModal from "./AddProjectModal";
import {EditIcon} from "components/Icons";
import template from 'assets/templates/projectsTemplate.xlsx';

const Projects = () => {

  const dispatch = useDispatch();
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const userData = useSelector(state => state.auth.user);
  const isPMOTeam = userData.role === 'PMO';
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const headCells = [
    {attr: "client", title: "Client"},
    {attr: "division", title: "Division"},
    {attr: "project_name", title: "Project Name"},
    {attr: "sub_project_name", title: "Subproject Name"},
    {attr: "customer_project_name", title: "Customer Project Name"},
    {attr: "milestone_template", title: "Milestone Template"},
    {attr: "object_type", title: "Object Type"},
    ...isPMOTeam ? [{attr: "Actions", title: ""}] : []
  ];

  useEffect(() => {
    dispatch({type: GET_PROJECT_OPTIONS_REQUEST});
  }, [dispatch]);


  const projectList = useSelector((state) => state.project.projectOptions);
  const loading = useSelector((state) => state.project.loading);

  const {tableData, handleRequestSort} = useTable(projectList);

  const options = {
    progress_percentage: {
      styles: {width: "100%"}, className: 'progress-cell'
    },
    active_milestone: {
      styles: {minWidth: "300px"},
    },
    Actions: {
      styles: { minWidth: "40px", maxWidth: "40px" },
      className: 'actions'
    }
  };

  const transformers = {
    Actions: (row) => {
      if(isPMOTeam){
        return (
          <div className='actions__wrap' onClick={(e) => {
            e.stopPropagation();
            setIsAddProjectModalOpen(true);
            setCurrentProject(row);
          }}>
            <EditIcon/>
          </div>
        )
      }
    }
  }


  return (
    <div className='Projects list-table-page'>
      <div className='sites__header'>
        <h1 className='page-title'>Projects</h1>
        {isPMOTeam && (
          <Button
            color={BUTTON_COLORS.GRAY}
            title="Bulk Upload"
            onClick={() => setIsBulkUploadOpen(true)}
          />
        )}
      </div>
      {!loading
        ? <Table
          data={tableData}
          options={options}
          headers={headCells}
          onRequireList={handleRequestSort}
          link='/pdt/projects'
          fixedHeader
          transformers={transformers}
          extraFilters={
            isPMOTeam && (
              <Button
                type="button"
                color={BUTTON_COLORS.DEFAULT}
                title='Add project'
                style={{marginRight: 10 }}
                onClick={() => setIsAddProjectModalOpen(true)}
              />
            )
          }
        />
        : <Skeleton/>
      }
      {isBulkUploadOpen && (
        <BulkUpload
          onBackgroundClick={() => setIsBulkUploadOpen(false)}
          completeUploadAction={() => dispatch({type: GET_PROJECT_OPTIONS_REQUEST})}
          parseApi='Project/SiteBulkUpload'
          template={{src: template, name: 'pdt project template'}}
        />
      )}

      {isAddProjectModalOpen && (
        <AddProjectModal
          onBackgroundClick={() => {
            setIsAddProjectModalOpen(false);
            setCurrentProject(null);
          }}
          currentProject={currentProject}
        />
      )}

    </div>
  );
};

export default Projects;
