import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_DETAILS_REQUEST,
  PROJECT_DETAILS_SUCCESS,
  PROJECT_DETAILS_FAIL,
  GET_PROJECT_USERS_SUCCESS,
  SAVE_TASK_STAKEHOLDER_REQUEST,
  SAVE_TASK_STAKEHOLDER_FAIL,
  SAVE_TASK_STAKEHOLDER_SUCCESS,
  SAVE_TASK_MEDIA_REQUEST,
  SAVE_TASK_MEDIA_FAIL,
  SAVE_TASK_MEDIA_SUCCESS,
  GET_PROJECT_DEFINITION_LIST_SUCCESS,
  GET_PROJECT_DEFINITION_LIST_REQUEST,
  GET_PROJECT_DEFINITION_LIST_FAIL,
  GET_ATTACHMENT_TYPE_SUCCESS,
  EDIT_TASKS,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_FAIL,
  GET_PROJECT_DOCUMENTS_REQUEST,
  GET_PROJECT_TICKETS_SUCCESS,
  GET_PROJECT_TICKETS_FAIL,
  GET_PROJECT_TICKETS_REQUEST,
  GET_PROJECT_OPTIONS_SUCCESS,
  GET_PROJECT_TASKS_SUCCESS,
  GET_PROJECT_ROLES_SUCCESS,
  GET_PROJECT_OPTIONS_REQUEST,
  GET_PROJECT_OPTIONS_FAIL,
  EDIT_ACTIVITIES,
  DELETE_ACTIVITIES_SUCCESS,
  DELETE_ACTIVITIES_REQUEST,
  DELETE_ACTIVITIES_FAIL,
  SAVE_MILESTONE_TEMPLATE_FAIL,
  SAVE_MILESTONE_TEMPLATE_REQUEST,
  SAVE_MILESTONE_TASK_REQUEST,
  SAVE_MILESTONE_TASK_FAIL,
  SAVE_MILESTONE_TEMPLATE_SUCCESS,
  SAVE_MILESTONE_TASK_SUCCESS,
  ACTIVITY_DETAILS_SUCCESS,
  ACTIVITY_DETAILS_REQUEST,
  ACTIVITY_DETAILS_FAIL,
  GET_MILESTONE_TEMPLATE_LIST_SUCCESS,
  GET_MILESTONE_TEMPLATE_LIST_FAIL,
  GET_MILESTONE_TEMPLATE_LIST_REQUEST,
  GET_MILESTONE_TEMPLATE_DETAILS_REQUEST,
  GET_MILESTONE_TEMPLATE_DETAILS_FAIL,
  GET_MILESTONE_TEMPLATE_DETAILS_SUCCESS,
  GET_ACTIVE_PROJECT_LIST_SUCCESS,
  GET_ALL_PROJECT_LIST_SUCCESS,
  REARRANGE_MILESTONE_TASK_REQUEST,
  REARRANGE_MILESTONE_TASK_FAIL,
} from "../actions/project";

const initialState = {
  projectList: {
    columns: [],
    data: []
  },
  projectDetails: {},
  activity: {
    ActivityDetails: {},
    Tasks: [],
    Changelog: [],
  },
  projectInformation: {},
  loading: true,
  secondaryLoading: false,
  modalLoading: false,
  projectRoles: null,
  projectUsers: null,
  projectDefinitionList: null,
  mileStoneTemplateDetails: {
    ProjectDetails: {},
    Tasks: []
  },
  fileTypes: [],
  projectDocuments: [],
  projectTickets: [],
  projectOptions: [],
  projectTasks: [],
  mileStoneTemplateList: [],
  activeProjectList: [],
  allProjectList: []
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case PROJECT_LIST_REQUEST:
    case PROJECT_DETAILS_REQUEST:
    case ACTIVITY_DETAILS_REQUEST:
    case GET_PROJECT_DEFINITION_LIST_REQUEST:
    case GET_MILESTONE_TEMPLATE_DETAILS_REQUEST:
    case GET_PROJECT_OPTIONS_REQUEST:
    case DELETE_ACTIVITIES_REQUEST:
    case REARRANGE_MILESTONE_TASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectList: action.payload,
        loading: false,
      };

    case PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: action.payload,
        loading: false,
      };

    case ACTIVITY_DETAILS_SUCCESS:
      return {
        ...state,
        activity: action.payload,
        loading: false,
      };

    case PROJECT_LIST_FAIL:
    case PROJECT_DETAILS_FAIL:
    case ACTIVITY_DETAILS_FAIL:
    case GET_PROJECT_DEFINITION_LIST_FAIL:
    case GET_MILESTONE_TEMPLATE_DETAILS_FAIL:
    case GET_PROJECT_OPTIONS_FAIL:
    case DELETE_ACTIVITIES_FAIL:
    case REARRANGE_MILESTONE_TASK_FAIL:
      return {
        ...state,
        loading: false,
      };

    case GET_PROJECT_ROLES_SUCCESS:
      return {
        ...state,
        projectRoles: action.payload,
      };

    case GET_PROJECT_USERS_SUCCESS:
      return {
        ...state,
        projectUsers: action.payload,
      };

    case GET_PROJECT_DEFINITION_LIST_SUCCESS:
      return {
        ...state,
        projectDefinitionList: action.payload,
        loading: false
      };

    case SAVE_TASK_STAKEHOLDER_REQUEST:
    case SAVE_TASK_MEDIA_REQUEST:
    case GET_PROJECT_DOCUMENTS_REQUEST:
    case GET_PROJECT_TICKETS_REQUEST:
    case SAVE_MILESTONE_TEMPLATE_REQUEST:
    case SAVE_MILESTONE_TASK_REQUEST:
      return {
        ...state,
        modalLoading: true,
      };

    case SAVE_TASK_STAKEHOLDER_SUCCESS:
    case SAVE_TASK_STAKEHOLDER_FAIL:
    case SAVE_TASK_MEDIA_FAIL:
    case SAVE_TASK_MEDIA_SUCCESS:
    case GET_PROJECT_DOCUMENTS_FAIL:
    case GET_PROJECT_TICKETS_FAIL:
    case SAVE_MILESTONE_TEMPLATE_FAIL:
    case SAVE_MILESTONE_TEMPLATE_SUCCESS:
    case SAVE_MILESTONE_TASK_FAIL:
    case SAVE_MILESTONE_TASK_SUCCESS:
      return {
        ...state,
        modalLoading: false,
      };

    case GET_MILESTONE_TEMPLATE_DETAILS_SUCCESS:
      return {
        ...state,
        mileStoneTemplateDetails: action.payload,
        loading: false
      };


    case GET_ATTACHMENT_TYPE_SUCCESS:
      return {
        ...state,
        fileTypes: action.payload,
      };

    case EDIT_TASKS:
      return {
        ...state,
        activity: {
          ...state.activity,
          Tasks: action.payload,
        },
      };

    case GET_PROJECT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        projectDocuments: action.payload,
        modalLoading: false
      };

    case GET_PROJECT_TICKETS_SUCCESS:
      return {
        ...state,
        projectTickets: action.payload,
        modalLoading: false
      };

    case GET_PROJECT_OPTIONS_SUCCESS:
      return {
        ...state,
        projectOptions: action.payload,
        loading: false,
      };

    case GET_PROJECT_TASKS_SUCCESS:
      return {
        ...state,
        projectTasks: action.payload,
      };

    case EDIT_ACTIVITIES:
    case DELETE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        projectList: {
          ...state.projectList,
          data: action.payload
        },
        loading: false
      };

    case GET_MILESTONE_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        secondaryLoading: true
      };
    case GET_MILESTONE_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        mileStoneTemplateList: action.payload,
        secondaryLoading: false
      };
    case GET_MILESTONE_TEMPLATE_LIST_FAIL:
      return {
        ...state,
        secondaryLoading: false
      };

    case GET_ACTIVE_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        activeProjectList: action.payload
      };

    case GET_ALL_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        allProjectList: action.payload
      };


    default:
      return state;
  }
}
