import React, { useState } from "react";
import Button, {BUTTON_COLORS} from "../common/Button/Button";
import Modal from "@mui/material/Modal";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ConfirmModal = (props) => {

  const {
    open, onBackgroundClick, onSubmitClick,
    remarksText, setRemarksText, withOutRemarks, remarksOptional,
    title, children, subTitle, inputPlaceholder,
    checkboxLabel, onCheckboxChange, additionalDisable
  } = props;

  const [checked, setChecked] = useState(false);

  const handleSubmit = () => {
    onSubmitClick();
    onBackgroundClick();
  };

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
    onCheckboxChange && onCheckboxChange(e.target.checked);
  }

  return (
    <Modal open={open} onClose={onBackgroundClick} className='modal-wrapper ConfirmModal'>
      <div className='modal-content'>
        <h3>{title}?</h3>
        <div className="modal-body">
          <p>{subTitle}</p>
          {children}
          {!withOutRemarks && (
            <TextareaAutosize
              value={remarksText}
              minRows={4}
              placeholder={inputPlaceholder || "Type your reason"}
              onChange={(e) => setRemarksText(e.target.value)}
              required={!remarksOptional}
            />
          )}
          {checkboxLabel &&
            <div className="modal-row">
              <div className="modal-field">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value="CloseNCR"
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                    )}
                    label={checkboxLabel}
                  />
                </FormGroup>
              </div>
            </div>
          }
        </div>
        <div className="modal-buttons">
          <Button
            title="Cancel"
            color={BUTTON_COLORS.GRAY}
            onClick={onBackgroundClick}
          />
          <Button
            title="Confirm"
            disabled={!withOutRemarks && !remarksOptional && !remarksText && !additionalDisable}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  )
};

export default ConfirmModal;