import {
  CREATE_ACTIVITY_BULK_FAIL,
  CREATE_ACTIVITY_BULK_REQUEST,
  CREATE_ACTIVITY_BULK_SUCCESS,
  DELETE_SITES_FAIL,
  DELETE_SITES_REQUEST,
  DELETE_SITES_SUCCESS,
  SITE_DETAILS_FAIL,
  SITE_DETAILS_REQUEST,
  SITE_DETAILS_SUCCESS,
  SITE_LIST_FAIL,
  SITE_LIST_REQUEST,
  SITE_LIST_SUCCESS
} from "../actions/site";

const initialState = {
  siteList: [],
  siteDetails: {
    ActivityDetails: {},
    Tasks: [],
    Changelog: [],
  },
  loading: true,
  modalLoading: false,
};

export default function site(state = initialState, action) {
  switch (action.type) {
    case SITE_LIST_REQUEST:
    case SITE_DETAILS_REQUEST:
    case DELETE_SITES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SITE_LIST_SUCCESS:
      return {
        ...state,
        siteList: action.payload,
        loading: false,
      };

    case SITE_DETAILS_SUCCESS:
      return {
        ...state,
        siteDetails: action.payload,
        loading: false,
      };

    case SITE_LIST_FAIL:
    case SITE_DETAILS_FAIL:
    case DELETE_SITES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_ACTIVITY_BULK_REQUEST:
      return {
        ...state,
        modalLoading: true,
      };

    case CREATE_ACTIVITY_BULK_FAIL:
    case CREATE_ACTIVITY_BULK_SUCCESS:
      return {
        ...state,
        modalLoading: false,
      };

    case DELETE_SITES_SUCCESS:
      return {
        ...state,
        siteList: action.payload,
        loading: false
      };


    default:
      return state;
  }
}
