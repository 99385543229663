import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes, Outlet} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Activity from "./Activity/Activity";
import Header from "components/Header/Header";
import MenuList from "components/MenuList/MenuList";
import {HamburgerIcon} from "components/Icons";
import 'assets/styles/globals.scss';
import Activities from "./Activities/Activities";
import Auth from "./Auth/Auth";
import Sites from "./Sites/Sites";
import Site from "./Site/Site";
import ProjectTemplates from "./Settings/ProjectTemplates/ProjectTemplates";
import ProjectTemplate from "./Settings/ProjectTemplate/ProjectTemplate";
import Dashboard from "./Dashboard/Dashboard";
import {GET_LOOKUP_LIST_REQUEST} from "store/actions/common";
import Notification from "components/Notification/Notification";
import Boards from "./Boards/Boards";
import {useClearCacheCtx} from "react-clear-cache";
import Projects from "./Projects/Projects";
import Project from "./Project/Project";
import UserManagement from "./Settings/UserManagement/UserManagement";
import UserDetails from "./Settings/UserDetails/UserDetails";

const Protected = () => {
  const user = useSelector((state) => state.auth.user);
  return user ? <Outlet /> : <Navigate to="/pdt/auth" />;
};


const App = () => {

  const [navHide, setNavHide] = useState(localStorage.getItem("navHide") || false);
  const dispatch = useDispatch();
  const lookup = useSelector((state) => state.common.lookup);
  const user = useSelector((state) => state.auth.user);
  const {isLatestVersion, emptyCacheStorage} = useClearCacheCtx();

  useEffect(() => {
    if(!lookup && user) {
      dispatch({type: GET_LOOKUP_LIST_REQUEST});
    }
  }, [user]); //eslint-disable-line

  const toggleNav = () => {
    localStorage.setItem("navHide", navHide ? "" : true);
    setNavHide(!navHide);
  };

  return (
    <>
      <Routes>
        <Route path="/pdt/auth/*" element={<Auth/>} exact/>
        <Route
          path='*'
          element={
            <div className={`app-container ${navHide ? 'navHide' : ''}`}>
              <Header/>
              <div className='nav-section'>
                <MenuList navHide={navHide}/>
                <div className='nav-btn' onClick={toggleNav}>
                  <HamburgerIcon/>
                </div>
              </div>
              <div className="content-section">
                <Routes>
                  <Route exact path='/' element={<Navigate to="/pdt/projects"/>}/>
                  <Route exact path='/pdt' element={<Navigate to="/pdt/projects"/>}/>
                  <Route element={<Protected />}>
                    <Route path="/pdt/activities-list/:id" element={<Activities/>} exact/>
                    <Route path="/pdt/activities/:id" element={<Activity/>} exact/>
                    <Route path="/pdt/projects" element={<Projects/>} exact/>
                    <Route path="/pdt/projects/:id" element={<Project/>} exact/>
                    <Route path="/pdt/sites" element={<Sites/>} exact/>
                    <Route path="/pdt/sites/:id" element={<Site/>} exact/>
                    <Route path="/pdt/project-templates" element={<ProjectTemplates/>} exact/>
                    <Route path="/pdt/project-templates/:id" element={<ProjectTemplate/>} exact/>
                    <Route path="/pdt/user-management" element={<UserManagement/>} exact/>
                    <Route path="/pdt/user-management/:id" element={<UserDetails/>} exact/>
                    <Route path="/pdt/dashboard" element={<Dashboard/>} exact/>
                    <Route path="/pdt/tickets" element={<Boards/>} exact/>
                  </Route>
                </Routes>
              </div>
              {!isLatestVersion && (
                <div className="update-version">
                  <p>You have got a new build version.</p>
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      emptyCacheStorage();
                    }}
                  >
                    Update
                  </a>
                </div>
              )}
            </div>
          }
        />
      </Routes>
      <Notification />
    </>
  );
};

export default App;
