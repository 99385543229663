import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {SAVE_TASK_REQUEST} from "store/actions/project";
import {Popover} from "@mui/material";
import {DateCalendar} from "@mui/x-date-pickers";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";

const DateCalendarField = ({onClose, openedDatePicker, activeMilestone, setActiveMilestone, editMode, editModeFunc}) => {

  const params = useParams();
  const {id} = params;
  const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const {row, fieldName, type, anchorEl} = openedDatePicker;
  const field = `${fieldName}_${type}_date`;
  const [value, setValue] = useState((!row?.[`new_${field}`] && !row?.[field]) ? new Date() : new Date(editMode ? row?.[`new_${field}`] || row?.[field] : row?.[field]));

  const handleSubmit = () => {
    if(editMode){
      editModeFunc(value);
    }else {
      dispatch({
        type: SAVE_TASK_REQUEST,
        payload: {
          auth: userData,
          id,
          body: [{
            id: row.task_id,
            [field]: value
          }
          ]        },
        callback: () => setActiveMilestone && setActiveMilestone({...activeMilestone, [field]: value}),
      })
    }
    onClose();
  }

  const checkDates = (dateType) => {
    const opposite = type === 'start' ? 'end' : 'start';
    if((!row?.[`new_${fieldName}_${opposite}_date`] && !row?.[`${fieldName}_${opposite}_date`]) || dateType !== type) return null;
    if(editMode) return new Date(row?.[`new_${fieldName}_${opposite}_date`] || row?.[`${fieldName}_${opposite}_date`]);
    return new Date(row?.[`${fieldName}_${opposite}_date`]);
  }

  const onHandleDateChange = (date) => {
    if (date) {
      date.setHours((-1 * date.getTimezoneOffset()) / 60);
    }
    setValue(date);
  }

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{vertical: 'top', horizontal: 'left'}}
      transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
    >
      <DateCalendar
        value={value}
        onChange={onHandleDateChange}
        maxDate={checkDates('start')}
        minDate={checkDates('end')}
      />
      <div className='calendar-btns'>
        <Button title='Cancel' onClick={onClose} color={BUTTON_COLORS.GRAY} />
        <Button title='Apply' color={BUTTON_COLORS.DEFAULT} onClick={handleSubmit} />
      </div>
    </Popover>
  )
}

export default DateCalendarField;