import React from 'react';
import {getHumanDate} from "helpers";
import {CreatedIcon, MilestoneIcon, UserIcon} from "components/Icons";
import './Changelog.scss';

const Changelog = ({data}) => {

  const getIcon = (type) => {
    switch (type){
      case 'project start':
        return <CreatedIcon/>
      case 'user assignment':
        return <UserIcon/>
      default:
        return <MilestoneIcon/>
    }
  }


  return (
    <div className='changelog'>
      {data.map((item, i) =>
        <div className='changelog__item' key={i}>
          <div className='changelog__item__left'>
            {getIcon(item.log_type)}
          </div>
          <div className='changelog__item__right'>
            <div className='title'>{item.title}</div>
            <span>{item.done_by} - {getHumanDate(item.date_completed)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Changelog;