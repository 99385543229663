import axiosInstance from "../services/axiosInstance";

export const project = {
  getProjectList: (data) => {
    return axiosInstance.get(`/Project/GetActivityList/${data ? data.id : ''}`);
  },

  getProjectDetails: (data) => {
    return axiosInstance.get(`Project/GetProjectDetails/${data.id}`);
  },

  getActivityDetails: (data) => {
    return axiosInstance.get(`Project/GetActivityDetails/${data.id}`);
  },

  saveTask: (data) => {
    return axiosInstance.post('Project/SaveTaskMultiple', data.body);
  },

  saveTaskStakeholder: (data) => {
    return axiosInstance.post('Project/SaveTaskStakeholder', data.body);
  },

  saveTaskMedia: (data) => {
    return axiosInstance.post('Project/SaveTaskMedia', data.body);
  },

  getProjectRoles: () => {
    return axiosInstance.get('Project/GetProjectRoles');
  },

  getProjectUsers: () => {
    return axiosInstance.get('Project/GetProjectUsers');
  },

  getProjectDefinitionList: () => {
    return axiosInstance.get('Project/GetProjectDefinitionList');
  },

  saveTaskApproval: (data) => {
    return axiosInstance.post('Project/SaveTaskApproval', data.body);
  },

  getAttachmentType: (data) => {
    return axiosInstance.get(`Project/GetAttachmentType?FieldName=${data.FieldName}&TableName=dbo.task`);
  },

  getProjectDocumentList: (data) => {
    return axiosInstance.get(`Project/GetProjectDocumentList/${data.id}`);
  },

  getProjectOptions: () => {
    return axiosInstance.get('Project/GetProjectList');
  },

  getProjectTaskList: (data) => {
    return axiosInstance.get(`Project/GetActivityTaskList/${data.id}`);
  },

  deleteActivities: (data) => {
    return axiosInstance.post(`Project/DeleteActivity`, data);
  },

  saveMilestoneTemplate: (data) => {
    return axiosInstance.post(`Project/SaveMilestoneTemplate`, data);
  },

  saveMilestoneTask: (data) => {
    return axiosInstance.post(`Project/SaveMilestoneTask`, data);
  },

  getMilestoneTemplateList: () => {
    return axiosInstance.get(`Project/GetMilestoneTemplateList`);
  },

  getMilestoneTemplateDetails: (data) => {
    return axiosInstance.get(`Project/GetMilestoneTemplateDetails/${data.id}`);
  },

  saveProject: (data) => {
    return axiosInstance.post(`Project/SaveProject`, data);
  },

  getActiveProjectList: () => {
    return axiosInstance.get(`Project/GetActiveProjectList`);
  },

  rearrangeMilestoneTask: (data) => {
    return axiosInstance.post(`Project/RearrangeMilestoneTask`, data);
  },

};
