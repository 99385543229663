import React, {useEffect, useState} from "react";
import Button, {BUTTON_COLORS} from "components/common/Button/Button";
import {useDispatch, useSelector} from "react-redux";
import Modal from "components/Modal/Modal";
import {ValidatorForm, TextValidator, SelectValidator} from "react-material-ui-form-validator";
import {
  GET_MILESTONE_TEMPLATE_LIST_REQUEST, SAVE_PROJECT_REQUEST
} from "store/actions/project";
import MenuItem from "@mui/material/MenuItem";

const AddProjectModal = ({onBackgroundClick, currentProject}) => {

  const [state, setState] = useState({
    project_name: currentProject?.project_name || '',
    sub_project_name: currentProject?.sub_project_name || '',
    customer_project_name: currentProject?.customer_project_name || '',
    division: currentProject?.division || '',
    client: currentProject?.client || '',
    milestone_template_id: currentProject?.milestone_template_id || '',
    object_type: currentProject?.object_type_id || '',
  })

  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.project.modalLoading);
  const mileStoneTemplateList = useSelector((state) => state.project.mileStoneTemplateList);
  const lookup = useSelector((state) => state.common.lookup);

  useEffect(() => {
    if(!mileStoneTemplateList.length){
      dispatch({type: GET_MILESTONE_TEMPLATE_LIST_REQUEST})
    }
  }, [])//eslint-disable-line

  const handleSubmit = () => {
    const data = state;
    if(currentProject){
      data.project_id = currentProject.id;
    }
    dispatch({
      type: SAVE_PROJECT_REQUEST,
      payload: data,
      callback: onBackgroundClick
    })
  }

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  return (
    <Modal
      className='small-width'
      onBackgroundClick={onBackgroundClick}
    >
      <div className='initiation-modal'>
        <h3>{currentProject ? 'Edit' : 'Add'} project</h3>
        <ValidatorForm onSubmit={handleSubmit} className='modal-form'>
          <div className="modal-row modal-field">
            <h6>Project name</h6>
            <TextValidator
              value={state.project_name}
              name='project_name'
              type="text"
              placeholder='Project name'
              variant="outlined"
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </div>
          <div className="modal-row modal-field">
            <h6>Sub project name</h6>
            <TextValidator
              value={state.sub_project_name}
              name='sub_project_name'
              type="text"
              placeholder='Sub project name'
              variant="outlined"
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </div>
          <div className="modal-row modal-field">
            <h6>Customer project name</h6>
            <TextValidator
              value={state.customer_project_name}
              name='customer_project_name'
              type="text"
              placeholder='Customer project name'
              variant="outlined"
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </div>
          <div className="modal-row modal-field">
            <h6>Division</h6>
            <TextValidator
              value={state.division}
              name='division'
              type="text"
              placeholder='Division'
              variant="outlined"
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </div>
          <div className="modal-row modal-field">
            <h6>Client</h6>
            <TextValidator
              value={state.client}
              name='client'
              type="text"
              placeholder='Client'
              variant="outlined"
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          </div>
          <div className="modal-field modal-select">
            <h6>Milestone template</h6>
            <SelectValidator
              value={state.milestone_template_id || ''}
              variant="outlined"
              name='milestone_template_id'
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            >
              {mileStoneTemplateList.map(item =>
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              )}
            </SelectValidator>
          </div>
          <div className="modal-field modal-select">
            <h6>Object type</h6>
            <SelectValidator
              value={state.object_type || ''}
              variant="outlined"
              name='object_type'
              onChange={onHandleChange}
              validators={['required']}
              errorMessages={['This field is required']}
            >
              {lookup?.['Project Object Type'].map(item =>
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              )}
            </SelectValidator>
          </div>

          <div className='modal-btns'>
            <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={onBackgroundClick}/>
            <Button title='Submit' color={BUTTON_COLORS.DEFAULT} disabled={modalLoading}/>
          </div>
        </ValidatorForm>
      </div>
    </Modal>
  );
};

export default AddProjectModal;
