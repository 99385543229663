import axiosInstance from "../services/axiosInstance";

export const userManagement = {

  getUserList: (data) => {
    return axiosInstance.post('UserManagement/Users', data);
  },

  saveUser: (data) => {
    return axiosInstance.post('UserManagement/SaveUser', data);
  },

  resetEmail: (data) => {
    return axiosInstance.post(`UserManagement/User/${data}/Password/ResetEmail`);
  },

  getUserDetails: (data) => {
    return axiosInstance.get(`UserManagement/${data}/UserDetail`);
  },

  updatePassword: (data) => {
    return axiosInstance.post(`UserManagement/User/${data.user_id}/Password/Update`, data.body);
  },

  toggleUserAvailability: ({user_id, status}) => {
    return axiosInstance.post(`UserManagement/User/${user_id}/Availability/${status}`);
  }

};
