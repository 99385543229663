import { call, put, all, takeLatest } from "redux-saga/effects";
import { Api } from "../../api";
import * as authActions from "../actions/auth";
import TokenService from "../../api/services/token.service";

const removeUserData = () => {
  TokenService.removeToken();
  localStorage.removeItem("user");
}


function* login(action) {
  try {
    const res = yield call(Api.auth.login, action.payload);
    const {token, ...user} = res.data;
    // localStorage.setItem('user', JSON.stringify(res.data));
    action.navigate('/', { replace: true })
    TokenService.setToken(token);
    localStorage.setItem('user', JSON.stringify(user))
    yield put({type: authActions.AUTH_LOGIN_SUCCESS, payload: {user}});
    if(action.callback) action.callback()
  } catch (err) {
    removeUserData();
    yield put({ type: authActions.AUTH_LOGIN_FAIL, payload: {error: err.response?.data?.error || 'An error occurred'}});
  }
}

function* logout(action) {
  try {
    // const data = {client_id: 'pdt-web', refresh_token: TokenService.getLocalRefreshToken()};
    // yield call(Api.auth.logout, data);
    action.navigate('/pdt/auth', { replace: true })
    removeUserData()
    yield put({ type: authActions.AUTH_LOGOUT_SUCCESS });
  } catch (err) {
    yield put({ type: authActions.AUTH_LOGOUT_FAIL });
  }
}

export default all([
  takeLatest(authActions.AUTH_LOGIN_REQUEST, login),
  takeLatest(authActions.AUTH_LOGOUT_REQUEST, logout),
])