import React, {useEffect, useState} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import PropTypes from "prop-types";
import Button from "components/common/Button/Button";
import user from 'assets/icons/user.svg';
import arrowLeft from 'assets/icons/chevron-left-sm.svg';
import {useDispatch, useSelector} from "react-redux";
import {CREATE_TICKET_REQUEST, GET_BOARDS_TICKETS_REQUEST,} from "store/actions/boards";
import './AssignTicketModal.scss';
import Loader from "components/common/Loader";
import {getRequiredTicketFields} from "helpers";
import {
  Autocomplete,
  Backdrop,
  Fade,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextareaAutosize
} from "@mui/material";
import {ChatIcon} from "components/Icons";
import {GET_PROJECT_USERS_REQUEST} from "store/actions/project";

const AssignTicketModal = ({open, setIsModalOpen, openTicketSupportModal, currentTicket, assignAction, filterData}) => {

  const initialState = {
    assignee: 'For myself',
    severity: 'Minor',
    addInfo: '',

    assigned_to: null,
  };

  const {ticket, forMySelf} = currentTicket;
  const [state, setState] = useState(initialState);
  const userData = useSelector(state => state.auth.user);
  const modalsLoading = useSelector(state => state.boards.modalsLoading);
  const projectUsers = useSelector(state => state.project.projectUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!projectUsers){
      dispatch({type: GET_PROJECT_USERS_REQUEST, payload: {auth: userData}})
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    if(ticket) setState({...initialState, ...ticket, assigned_to: {user_id: ticket.assigned_to_user_id, full_name: ticket.assigned_to_user}, assignee: forMySelf ? 'For myself' : 'For other person'});
  },[ticket]); //eslint-disable-line

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModalAndGetTickets = () => {
    setIsModalOpen(false);
    dispatch({
      type: GET_BOARDS_TICKETS_REQUEST,
      payload: {auth: userData, body: filterData}
    });
  };

  const assignTicket = () => {
    assignAction && dispatch(assignAction);
    dispatch({
      type: CREATE_TICKET_REQUEST,
      payload: {
        auth: userData,
        body: {
          ...getRequiredTicketFields(state),
          status: 'Assigned',
          assigned_to: state.assignee === 'For other person' ? state.assigned_to.user_id : userData.user_id,
          // remarks: state.addInfo,
          // severity: state.severity,
        },
      }, callback: closeModalAndGetTickets});
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const changeOtherPerson = (e, value) => {
    setState({...state, assigned_to: value})
  };

  return (
    <Modal
      className='modal-wrapper assign-modal'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
        style: {opacity: 0}
      }}
    >
      <Fade in={open}>
        <div className='modal-content medium-width'>
          {!modalsLoading
            ? <>
                <div className="row-padding">
                  <div className="modal-row-icon cursor-pointer" onClick={() => closeModal(openTicketSupportModal(true))}>
                    <img src={arrowLeft} alt=""/>
                  </div>
                  <h3>Assign task</h3>
                </div>
                <ValidatorForm onSubmit={assignTicket} className='modal-form'>
                <div className="modal-row row-padding">
                  {(typeof forMySelf === 'undefined') && (
                    <div className="modal-simple-radio">
                      <RadioGroup aria-label="assignee" name="assignee" value={state.assignee} onChange={onHandleChange}>
                        <FormControlLabel value="For myself" control={<Radio color="primary"/>} label="For myself" />
                        <FormControlLabel value="For other person" control={<Radio color="primary"/>} label="For other person" />
                      </RadioGroup>
                    </div>
                  )}
                  {state.assignee === 'For other person' && (
                    <div className="modal-row">
                      <div className="modal-field modal-field-grow">
                        <span className='form-icon' style={{backgroundImage: `url(${user})`}}/>
                        <Autocomplete
                          options={projectUsers?.owner.map((option) => option) || []}
                          getOptionLabel={(option) => option.full_name || ''}
                          onChange={changeOtherPerson}
                          value={state.assigned_to}
                          renderInput={(params) => (
                            <TextValidator
                              {...params}
                              variant="outlined"
                              value={state.assigned_to}
                              placeholder='Person name'
                              validators={['required']}
                              errorMessages={['This field is required']}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/*<div className="modal-row row-padding">*/}
                {/*  <h4>Severity</h4>*/}
                {/*  <div className="modal-field modal-select">*/}
                {/*    <div className="modal-radio">*/}
                {/*      {SEVERITY_OPTIONS.map((item, index) =>*/}
                {/*        <label key={item}>*/}
                {/*          <input type="radio" name='severity' value={item} checked={item === state.severity} onChange={onHandleChange}/>*/}
                {/*          <span className={`color-${index}`}>{item}</span>*/}
                {/*        </label>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*    <span className='modal-caption'>Tickets with the Major severity are going to be first to process</span>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="modal-row row-padding additional-row">
                  <h4>Additional information</h4>
                  <div className="modal-row row-padding">
                    <div className="modal-row-icon">
                      <ChatIcon/>
                    </div>
                    <div className="modal-field">
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={1}
                        placeholder="Add comment"
                        value={state.addInfo}
                        name='addInfo'
                        onChange={onHandleChange}
                      />
                    </div>
                  </div>

                </div>

                <div className="modal-btns">
                  <Button
                    type='submit'
                    title='Submit assigment'
                    color='ongoing'
                  />
                </div>
              </ValidatorForm>
              </>
            : <Loader height={'80vh'} width='100%'>
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
              </Loader>
          }

        </div>
      </Fade>
    </Modal>
  )
};

AssignTicketModal.propTypes = {
  open: PropTypes.bool,
  ticket: PropTypes.object,
};

export default AssignTicketModal;