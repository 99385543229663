import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import "./Activity.scss";
import SideNav from "./components/SideNav/SideNav";
import ProjectModal from "./components/Modal";
import {
  ForecastIcon,
  MilestoneIcon,
  MoreIcon,
  UserIcon
} from "components/Icons";
import {ACTIVITY_DETAILS_REQUEST} from "store/actions/project";
import Loader from "components/common/Loader";
import ProjectHeader from "./components/ProjectHeader/ProjectHeader";
import {getHumanDate, getStatusClassname} from "helpers";
import classnames from "classnames";
import AdditionalInfo from "./components/AdditionalInfo/AdditionalInfo";
import {MilestonesContent} from "./components/MilestonesContent/MilestonesContent";

const Activity = () => {
  const params = useParams();

  const {id} = params;
  const dispatch = useDispatch();
  const [activeMilestone, setActiveMilestone] = useState(null);
  const [projectModal, setProjectModal] = useState(null);
  const activity = useSelector((state) => state.project.activity);
  const loading = useSelector((state) => state.project.loading);
  const [scrollElPosition, setScrollElPosition] = useState('');
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [openedAdditionalInfo, setOpenedAdditionalInfo] = useState(null);

  useEffect(() => {
    dispatch({
      type: ACTIVITY_DETAILS_REQUEST,
      payload: {id},
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (scrollElPosition) {
      document.getElementById(scrollElPosition)?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [activity]); //eslint-disable-line

  const loginUser = "Samir Habib";

  const getSkeleton = () => {
    return (
      <Loader height={670} width='100%'>
        <rect x='0' y={0} rx='4' ry='4' width='10%' height='40px'/>
        <rect x='70%' y={0} rx='4' ry='4' width='30%' height='40px'/>

        <rect x='0' y={44} rx='4' ry='4' width='100%' height='80px'/>

        <rect x='0' y={136} rx='4' ry='4' width='32%' height='30px'/>
        <rect x='0' y={168} rx='4' ry='4' width='40%' height='160px'/>

        <rect x='0' y={342} rx='4' ry='4' width='32%' height='40px'/>

        <rect x='0' y={392} rx='4' ry='4' width='32%' height='40px'/>
        <rect x='80%' y={392} rx='4' ry='4' width='20%' height='40px'/>

        <rect x='0' y={436} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={480} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={524} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={568} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={612} rx='4' ry='4' width='100%' height='40px'/>
        <rect x='0' y={656} rx='4' ry='4' width='100%' height='40px'/>
      </Loader>
    );
  };


  return (
    <>
      <div className={classnames('Activity', {isSidebarHidden})}>
        {!loading
          ? <>
            {!openedAdditionalInfo
              ? <div className='project-left'>
                <ProjectHeader
                  data={activity.ActivityDetails}
                  toggleSidebar={() => setIsSidebarHidden(!isSidebarHidden)}
                />
                {!!activity.ActiveMilestones?.length && (
                  <div className='active-milestone'>
                    <div className='active-milestone__head'>
                      <MilestoneIcon/>
                      Active Tasks/Milestones
                    </div>

                    <div className="active-milestone-wrap">
                      {activity.ActiveMilestones.map((item, i) =>
                          <div
                            key={i}
                            onClick={() => {
                              setActiveMilestone(item);
                              setProjectModal('survey');
                            }}
                            className='active-milestone__card'>
                            <span className={`status ${getStatusClassname(item.task_status)}`}>{item.task_status}</span>
                            <div className="more-btn">
                              <MoreIcon/>
                            </div>
                            <h2>
                              {item.SubStage || item.task_name}
                            </h2>
                            <div className='content'>
                              <UserIcon/>
                              <span className='content__name'>
                                {item.owner?.user_name || "Unassigned"}
                              </span>{" "}
                              {item.owner?.user_name === loginUser && "(you)"}
                              <span className='content__divider'/>
                              <ForecastIcon/>
                              {getHumanDate(item.forecast_start_date)}
                            </div>
                          </div>
                      )}

                    </div>
                  </div>
                )}

                <MilestonesContent
                  setActiveMilestone={setActiveMilestone}
                  activeMilestone={activeMilestone}
                  milestoneList={activity.Tasks}
                  activityDetails={activity.ActivityDetails}
                  setProjectModal={setProjectModal}
                  setScrollElPosition={setScrollElPosition}
                  id={id}
                />
              </div>
              : <AdditionalInfo type={openedAdditionalInfo} data={activity.ActivityDetails} setProjectModal={setProjectModal}/>
            }
          </>
          : getSkeleton()
        }

        <div className='project-right'>
          <SideNav
            data={activity.Changelog}
            openedAdditionalInfo={openedAdditionalInfo}
            setOpenedAdditionalInfo={setOpenedAdditionalInfo}
          />
        </div>
      </div>
      {projectModal && (
        <ProjectModal
          actions // For modal action button at the top right
          statusProgress
          projectModal={projectModal}
          setProjectModal={setProjectModal}
          activeMilestone={activeMilestone}
          setActiveMilestone={setActiveMilestone}
          id={id}
        />
      )}
    </>
  );
};

export default Activity;
