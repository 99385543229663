import React, {useEffect, useState} from 'react';
import {ValidatorForm} from 'react-material-ui-form-validator';
import PropTypes from "prop-types";
import Button from "components/common/Button/Button";
import arrowLeft from 'assets/icons/chevron-left-sm.svg';
import { useDispatch, useSelector } from "react-redux";
import {EDIT_TICKET_REQUEST, GET_BOARDS_TICKETS_REQUEST} from "store/actions/boards";
import './UpdateTicketModal.scss';
import {getRequiredTicketFields} from "helpers";
import {Backdrop, Fade, Modal, TextareaAutosize} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {ChatIcon} from "components/Icons";

const UpdateTicketModal = ({open, setIsModalOpen, openTicketSupportModal, currentTicket, assignAction, actionName, filterData}) => {

  const initialState = {
    addInfo: '',
    date_forecast: new Date()
  };

  const {ticket} = currentTicket;
  const [state, setState] = useState(ticket || initialState);
  const userData = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if(ticket) setState({...initialState, ...ticket});
  },[ticket]); // eslint-disable-line

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModalAndGetTickets = () => {
    setIsModalOpen(false);
    dispatch({
      type: GET_BOARDS_TICKETS_REQUEST,
      payload: {auth: userData, body: filterData}
    });
  };

  const updateTicket = () => {
    assignAction && dispatch(assignAction);
    const requestPayload = {
      auth: userData,
      body: {
        ...getRequiredTicketFields(state),
        status: actionName
        // action: actionName,
        // remarks: state.addInfo,
      },
    };
    if (actionName === 'In Progress') {
      requestPayload.body.due_date = state.date_forecast;
      requestPayload.body.start_date = new Date();
    }
    dispatch({
      type: EDIT_TICKET_REQUEST,
      payload: requestPayload,
      callback: closeModalAndGetTickets
    });
  };

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  return (
    <Modal
      className='modal-wrapper assign-modal'
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
        style: {opacity: 0}
      }}
    >
      <Fade in={open}>
        <div className='modal-content medium-width'>
          <div className="row-padding">
            <div className="modal-row-icon cursor-pointer" onClick={() => closeModal(openTicketSupportModal(true))}>
              <img src={arrowLeft} alt=""/>
            </div>
            <h3>Update task</h3>
          </div>
          <ValidatorForm onSubmit={updateTicket} className='modal-form'>
            <div className="modal-row row-padding additional-row">
              <h4>Additional information</h4>
              <div className="modal-row row-padding">
                <div className="modal-row-icon">
                  <ChatIcon/>
                </div>
                <div className="modal-field">
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={1}
                    placeholder="Add comment"
                    value={state.addInfo}
                    name='addInfo'
                    onChange={onHandleChange}
                  />
                </div>
              </div>
            </div>
            {actionName === 'In Progress' && <div className="modal-row row-padding additional-row">
              <h4>Forecast Date</h4>
              <div className="modal-row row-padding">
                <div className="modal-field">
                  <DatePicker
                    views={['year', 'month', 'day']}
                    value={state.date_forecast || new Date()}
                    onChange={value => setState({...state, date_forecast: value})}
                    format="dd/MM/yyyy"
                  />
                </div>
              </div>
            </div>}

            <div className="modal-btns">
              <Button
                type='submit'
                title='Submit assigment'
                color='ongoing'
              />
            </div>
          </ValidatorForm>
        </div>
      </Fade>
    </Modal>
  )
};

UpdateTicketModal.propTypes = {
  open: PropTypes.bool,
  ticket: PropTypes.object,
};

export default UpdateTicketModal;