import React from "react";
import PropTypes from 'prop-types'
import "./index.scss";

export class Checkbox extends React.Component {

  static propTypes = {
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    title: "",
    checked: false,
    onChange: null,
  };

  onChange = (e) => {
    const { onChange } = this.props;

    if (onChange) {
      return onChange.call(this, e.currentTarget.checked);
    }
  };

  render () {
    const { title, checked } = this.props;

    return (
      <label className="checkbox">
        <input onChange={this.onChange} type="checkbox" checked={checked} name='checkbox'/>
        {title && <span>{title}</span>}
      </label>
    );
  }
}