export const GET_USERS_LIST_REQUEST = "GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAIL = "GET_USERS_LIST_FAIL";

export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAIL = "SAVE_USER_FAIL";

export const RESET_EMAIL_REQUEST = "RESET_EMAIL_REQUEST";
export const RESET_EMAIL_SUCCESS = "RESET_EMAIL_SUCCESS";
export const RESET_EMAIL_FAIL = "RESET_EMAIL_FAIL";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const TOGGLE_USER_AVAILABILITY_REQUEST = "TOGGLE_USER_AVAILABILITY_REQUEST";
export const TOGGLE_USER_AVAILABILITY_SUCCESS = "TOGGLE_USER_AVAILABILITY_SUCCESS";
export const TOGGLE_USER_AVAILABILITY_FAIL = "TOGGLE_USER_AVAILABILITY_FAIL";