import {SET_NOTIFICATION_MESSAGE} from "../actions/notification";

const initialState = {
  snackbarMessage: '',
  openSnackbar: false,
  snackbarSeverity: 'info',
};

export default function notification(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
