import React from 'react';
import classnames from "classnames";
import './UserChip.scss';

const UserChip = ({checked, title, avatar, onClick}) => {
  return (
    <div className={classnames('userChip', {checked})} onClick={onClick}>
      <p>{title}</p>
    </div>
  );
};

export default UserChip;