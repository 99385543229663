import React from 'react';
import './AdditionalInfo.scss';
import SiteGeneralInfo from "containers/Site/components/SiteGeneralInfo/SiteGeneralInfo";
import Documents from "./Documents";
import Issues from "./Issues";

const AdditionalInfo = ({type, data, setProjectModal}) => {

  const getContent = () => {
    switch (type){
      case 'general':
        return <SiteGeneralInfo data={data}/>
      case 'documents':
        return <Documents/>
      case 'issues':
        return <Issues setProjectModal={setProjectModal}/>
      default:
        return null
    }
  }
  return (
    <div className='additionalInfo project-left'>
      {getContent()}
    </div>
  );
};

export default AdditionalInfo;