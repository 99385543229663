import classnames from 'classnames';
import React, {useEffect} from "react";
import PropTypes from 'prop-types'
import "./Modal.scss";

const Modal = ({ onBackgroundClick, className, children }) => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset'
    };
  }, []);

  return (
    <div className="modal-wrapper">
      <div className={classnames("modal-content", className)} onClick={e => e.stopPropagation()}>
        <div onClick={onBackgroundClick} className="close-icon">×</div>
        {children}
      </div>
    </div>
  )
};

Modal.defaultProps = {
  className: "",
  onBackgroundClick: null,
};

Modal.propTypes = {
  className: PropTypes.string,
  onBackgroundClick: PropTypes.func
};

export default Modal;