import React, {useEffect, useState} from 'react';
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import {InputAdornment} from "@mui/material";
import {EyeClosedIcon, EyeIcon} from "components/Icons";
import {UPDATE_PASSWORD_REQUEST} from "../../../store/actions/userManagement";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const UpdatePassword = () => {

  const [state, setState] = useState({
    password: '',
    confirm_password: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const onHandleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => value === state.password);
  }, [state.password]);

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordValid', (value) => {
      return value.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/);
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordValid');
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: UPDATE_PASSWORD_REQUEST,
      payload: {
        user_id: window.location.search.slice(4),
        body: {
          password: state.password
        }
      },
      navigate
    })
  }

  return (
    <div className="auth-card">
      <div className="auth-card-head">
        <h2>Update password</h2>
        <p>New password must be at least 8 characters long and contain at least one letter and one digit</p>
      </div>
      <ValidatorForm className="auth-card-form" onSubmit={handleSubmit}>
        <div className="auth-field">
          <h6>New password</h6>
          <TextValidator
            type={showPassword ? 'text' : 'password'}
            value={state.password}
            placeholder='New password'
            variant="outlined"
            name='password'
            onChange={onHandleChange}
            validators={['required', 'minStringLength:8', 'isPasswordValid']}
            errorMessages={['This field is required', 'New password must be at least 8 characters long', 'Password must contain at least one uppercase letter, one number, and one special character']}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <EyeClosedIcon/> : <EyeIcon />}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="auth-field">
          <h6>Re-enter new password</h6>
          <TextValidator
            type={showConfirmPassword ? 'text' : 'password'}
            value={state.confirm_password}
            placeholder='Re-enter new password'
            variant="outlined"
            name='confirm_password'
            onChange={onHandleChange}
            validators={['isPasswordMatch', 'required']}
            errorMessages={['password mismatch', 'this field is required']}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => setConfirmShowPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <EyeClosedIcon/> : <EyeIcon />}
                </InputAdornment>
              ),
            }}
          />
        </div>

        <button
          type="submit"
        >
          Update password
        </button>
      </ValidatorForm>
    </div>
  );
};

export default UpdatePassword;