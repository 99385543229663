import React, {useEffect} from 'react';
import AgingTable from "./components/AgingTable/AgingTable";
import {useDispatch, useSelector} from "react-redux";
import {GET_AGING_DATA_REQUEST, GET_DASHBOARD_SUMMARY_REQUEST} from "store/actions/dashboard";
import './Dashboard.scss';
import {ReactComponent as HelpIcon} from 'assets/icons/help.svg';
import Loader from "components/common/Loader";
import LineChart from "./components/LineChart/LineChart";
import BarChart from "./components/BarChart/BarChart";
import {convertMilliseconds} from "helpers";

const Dashboard = () => {

  const dispatch = useDispatch();
  const agingData = useSelector((state) => state.dashboard.agingData);
  const {loading, chart: dashboardSummary} = useSelector((state) => state.dashboard.dashboardSummary);

  const dashboardSummaryNaming = {
    active_project: 'Active projects',
    average_cost: 'Avg. Project cost',
    average_progress_percentage: 'Avg. Project progress',
    pending_start: 'Projects pending start',
    total_cost: 'Total cost',
  }

  const healthData = [
    {title: 'Ahead of schedule', value: 46, percents: 33,},
    {title: 'Active tasks', value: 671, percents: '',},
    {title: 'Tasks overdue', value: 32, percents: 2,},
    {title: 'Under budged', value: 102, percents: 87,},
    {title: 'Over budged', value: 4, percents: 1,},
  ];
  const healthData2 = [
    {title: 'Guy Hawkins', value: 23, percents: 33,},
    {title: 'Annette Black', value: 22, percents: '',},
    {title: 'Tasks overdue', value: 20, percents: 2,},
    {title: 'Under budged', value: 16, percents: 87,},
    {title: 'Over budged', value: 12, percents: 1,},
  ]
  const healthData3 = [
    {title: 'Upload SAQ Documents', value: 248451454.925982},
    {title: 'Handover to Civil (HOC)', value: -146388759.782044},
    {title: 'Review and Validate the SHO Documents based on the JV', value: -31247334.167369},
    {title: 'Update Date', value: 42314734.167369},
    {title: 'Conduct Joint Visit (JV)', value: 13407068.368104},
    {title: 'Update HOC Date', value: -23107068.368104},
  ]


  useEffect(() => {
    dispatch({type: GET_DASHBOARD_SUMMARY_REQUEST})
    dispatch({type: GET_AGING_DATA_REQUEST})
  }, [dispatch]);

  return (
    <main className='dashboard'>
      <h1 className='page-title'>Dashboard</h1>
      <section className='overview'>
        {!loading
          ?  <div className='overview__items'>
              {dashboardSummary && Object.keys(dashboardSummary).map((item, i) =>
                <div className='overview__item' key={i}>
                  <h5>{dashboardSummary[item]}</h5>
                  <span>{dashboardSummaryNaming[item]}</span>
                </div>
              )}
            </div>
          : <Loader height={114} width="100%">
            {[...new Array(5)].map((val, i) => {
              return (<rect key={i} x={i * 20 + '%'} y="0" rx="4" ry="4" width="19%" height="100%"/>);
            })}
          </Loader>
        }
      </section>

      <section className='dashboard-info'>
        <div className='dashboard-main'>
          <LineChart/>
          <BarChart/>
          <AgingTable data={agingData}/>
        </div>

        <aside className='dashboard-aside'>
          <div className='box'>
            <div className='box__header'>
              <h5>Health</h5>
              <HelpIcon/>
            </div>

            <ul className='box__main'>
              {healthData.map((item, i) => (
                <li key={i}>
                  <h5>{item.title}</h5>
                  <span>
                    {item.value} {item.percents && '(' + item.percents + '%)'}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="box taskOverdue">
            <div className="box__header">
              <h5>Task Overdue</h5>
            </div>
            <ul className='box__main'>
              {healthData3.map((item, i) =>
                <li key={i}>
                  <h5>{item.title}</h5>
                  <div className="lines">
                    {['negative', 'positive'].map(el =>
                      <div className={`line line--${el}`} key={el}>
                        {((item.value < 0 && el === 'negative') || (item.value > 0 && el === 'positive')) && (
                          <div className='filled' style={{width: `${Math.abs(item.value / healthData3[0].value * 50)}%`}}>
                            <span>{item.value < 0 ? '-' : '+'}{convertMilliseconds(Math.abs(item.value))}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="box">
            <div className="box__header">
              <h5>Tasks assigned</h5>
            </div>
            <ul className='box__main'>
              {healthData2.map((item, i) =>
                <li key={i}>
                  <h5>{item.title}</h5>
                  <div className="line">
                    <div className='filled' style={{width: `${item.value / healthData2[0].value * 90}%`}}>
                      <span>{item.value}</span>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>

        </aside>
      </section>
    </main>
  );
};

export default Dashboard;