import axiosInstance from "../services/axiosInstance";

export const site = {

  getSiteList: () => {
    return axiosInstance.get('Project/GetSiteList');
  },

  getSiteDetails: (data) => {
    return axiosInstance.get(`Project/GetSiteDetails/${data.id}`);
  },

  createActivityBulk: (data) => {
    return axiosInstance.post('Project/CreateActivityBulk', data.body);
  },

  deleteSite: (data) => {
    return axiosInstance.post(`Project/DeleteSite`, data);
  },
};
