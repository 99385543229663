import React from "react";
import PropTypes from 'prop-types'
import { TableHeader, TableContent } from "../../Table";

class Table extends React.Component {

  static propTypes = {
    data: PropTypes.array,
    headers: PropTypes.array,
    popularGroupOptions: PropTypes.array,
    options: PropTypes.any,
    transformers: PropTypes.object,
    userData: PropTypes.object,
    onRequireList: PropTypes.func,
    onRequireGroups: PropTypes.func,
    withExport: PropTypes.bool,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    totalSubmittals: PropTypes.number,
    loadInvoiceSubmittal: PropTypes.func,
    activeTabIndex: PropTypes.string,
    extraFilters: PropTypes.any,
    onCheckboxChange: PropTypes.func,
    fixedHeader: PropTypes.bool,
    onDragAndDrop: PropTypes.func,
    hideSettings: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    headers: [],
    initialData: [],
    popularGroupOptions: [],
    options: {},
    transformers: {},
    userData: {},
    onRequireList: null,
    setRejectionList: null,
    withExport: false,
    link: undefined,
    totalSubmittals: null,
    loadInvoiceSubmittal: null,
    extraFilters: null,
    onCheckboxChange: undefined,
    fixedHeader: false,
    onDragAndDrop: null,
    hideSettings: false
  };

  state = {
    headers: this.props.headers,
    popularGroupOptions: this.props.popularGroupOptions,
    options: this.props.options,
    searchText: "",
    groupBy: [],
    isFilterBarOpen: false,
    filter: {},
    orderBy: {},
    page: 0,
    limit: 10,
    groupQuery: [],
    fixedLeft: this.props.fixedLeft || [],
    fixedRight: this.props.fixedRight || [],
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeTabIndex !== this.props.activeTabIndex) {
      this.setState({ page: 0 })
    }
  }

  onRequireList() {
    const { onRequireList } = this.props;
    const { page, limit, filter, groupQuery, searchText, orderBy } = this.state;

    onRequireList && onRequireList.call(this, {
      query: {
        page,
        limit,
        orderBy,
        groups: groupQuery,
        filters: filter,
        search: searchText
      }
    });
  }

  onSearchChange = value => {
    this.setState({
      searchText: value,
      page: 0
    }, () => {
      this.onRequireList();
    });
  };

  onSearchClose = () => {
    this.setState({
      searchText: ""
    }, () => {
      this.onRequireList();
    });
  };

  onSettingsSave = (ordered, fixedLeft, fixedRight) => {
    this.setState({
      headers: ordered,
      fixedLeft: fixedLeft,
      fixedRight: fixedRight,
    });
  };

  onFilterClick = (isFilterBarOpen) => {
    this.setState({ isFilterBarOpen });
  };

  onFilterChange = (filter) => {
    this.setState({
      filter
    }, () => {
      this.onRequireList();
    });
  };

  onPageChange = (page, limit) => {
    this.setState({
      page,
      limit
    }, () => {
      this.onRequireList();
    });
  };

  onSort = (attr, sort) => {
    this.setState({
      orderBy: {
        field: attr,
        type: sort
      }
    }, () => {
      this.onRequireList();
    });
  };

  onPaginationChange = (limit) => {
    this.setState({
      limit
    }, () => {
      this.onRequireList();
    });
  };

  onGroupSave = (attributes) => {
    const { onRequireGroups } = this.props;

    if (attributes.length === 0) {
      this.setState({
        page: 0,
        groupBy: [],
        groupQuery: []
      }, () => {
        this.onRequireList();
      });
      return;
    }

    this.setState({
      groupBy: attributes
    }, () => {
      onRequireGroups && onRequireGroups.call(this, attributes);
    });
  };

  renderRows(data) {
    const { transformers } = this.props;

    return data.map((entry, i) => {
      const transform = {};

      Object.keys(transformers).forEach(key => {
        transform[key] = transformers[key].apply(this, [entry, i]);
      });

      const transformedEntry = {
        ...entry,
        ...transform
      };

      if (entry.children && entry.children.length > 0) {
        transformedEntry.children = this.renderRows(entry.children);
      }

      return transformedEntry;
    });
  }

  render() {
    const {
      onStarChange,
      onCheckboxChange,
      groupBySite,
      setGroupBySite,
      userData,
      withExport,
      link,
      totalSubmittals,
      loadInvoiceSubmittal,
      loading,
      fixedHeader,
      onDragAndDrop,
      hideSettings
    } = this.props;

    const {
      page,
      headers,
      popularGroupOptions,
      searchText,
      isFilterBarOpen,
      filter,
      groupBy,
      fixedLeft,
      fixedRight,
      options,
    } = this.state;

    return (
      <React.Fragment>
        <TableHeader
          searchText={searchText}
          onSearchChange={this.onSearchChange}
          onSearchClose={this.onSearchClose}
          groupOptions={headers}
          popularGroupOptions={popularGroupOptions}
          columns={headers}
          onSettingsSave={this.onSettingsSave}
          onFilterClick={this.onFilterClick}
          onGroupSave={this.onGroupSave}
          onGroupReset={this.onGroupSave}
          filteredAmount={Object.keys(filter).length}
          groupBySite={groupBySite}
          setGroupBySite={setGroupBySite}
          withExport={withExport}
          userData={userData}
          data={this.props.data}
          fixedRight={fixedRight}
          extraFilters={this.props.extraFilters}
          hideSettings={hideSettings}
        />

        <TableContent
          loading={loading}
          headers={headers}
          search={searchText}
          fixedLeft={fixedLeft}
          fixedRight={fixedRight}
          isFilterBarOpen={isFilterBarOpen}
          options={options}
          groupBy={groupBy}
          onStarChange={onStarChange}
          onCheckboxChange={onCheckboxChange}
          onFilterChange={this.onFilterChange}
          onPageChange={this.onPageChange}
          onSort={this.onSort}
          onPaginationChange={this.onPaginationChange}
          rows={this.renderRows(this.props.data)}
          page={page}
          link={link}
          totalSubmittals={totalSubmittals}
          loadInvoiceSubmittal={loadInvoiceSubmittal}
          fixedHeader={fixedHeader}
          onDragAndDrop={onDragAndDrop}
        />
      </React.Fragment>
    );
  }
}

export { Table };
