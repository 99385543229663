import React, {useState} from 'react';
import {FormControl, TextField} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {SAVE_TASK_REQUEST} from "store/actions/project";
import {useDispatch, useSelector} from "react-redux";
import Button, {BUTTON_COLORS} from "../../../../components/common/Button/Button";

const ForecastModal = ({activeMilestone, setProjectModal}) => {

  const [forecastStart] = useState(new Date(activeMilestone.forecast_start_date));
  const [forecastEnd, setForecastEnd] = useState(new Date(activeMilestone.forecast_end_date))
  const [actualStart, setActualStart] = useState(new Date(activeMilestone.actual_start_date));
  const [actualEnd, setActualEnd] = useState(new Date(activeMilestone.actual_end_date))
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.user);

  const changeDate = () => {
    dispatch({
      type: SAVE_TASK_REQUEST,
      payload: {
        auth: userData,
        body: {
          id: activeMilestone.task_id,
          activity: activeMilestone.activity_id,
          task_definition_id: activeMilestone.task_id,
          plan_start_date: activeMilestone.plan_start_date,
          plan_end_date: activeMilestone.plan_end_date,
          actual_start_date: actualStart,
          actual_end_date: actualEnd,
          owner: activeMilestone.owner,
          forecast_start_date: forecastStart,
          forecast_end_date: forecastEnd,
          status: 'Pending'
        }
      }
    })

  }

  return (
    <div className='initiation-modal'>
      <h3>Reforcast</h3>

      <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <p>Forecast date</p>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <DatePicker
            label="End"
            value={forecastEnd}
            onChange={(value) => {
              setForecastEnd(value);
            }}
            minDate={forecastStart}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </FormControl>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <p>Actual date</p>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <DatePicker
            label="Start"
            value={actualStart}
            onChange={(value) => setActualStart(value)}
            maxDate={actualEnd}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <DatePicker
            label="End"
            value={actualEnd}
            onChange={(value) => setActualEnd(value)}
            minDate={actualStart}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </FormControl>
      </div>

      <div className='modal-btns'>
        <Button title='Cancel' color={BUTTON_COLORS.GRAY} onClick={() => setProjectModal(null)}/>
        <Button title='Submit' color={BUTTON_COLORS.DEFAULT} onClick={changeDate}/>
      </div>
    </div>
  );
};

export default ForecastModal;