import {
  CLEAR_PREVIOUS_BULK_UPLOAD,
  GET_LOOKUP_LIST_SUCCESS,
  PARSE_BULK_UPLOAD_FAIL,
  PARSE_BULK_UPLOAD_REQUEST,
  PARSE_BULK_UPLOAD_SUCCESS,
  UPLOAD_MEDIA_ALL_FAIL,
  UPLOAD_MEDIA_ALL_REQUEST,
  UPLOAD_MEDIA_ALL_SUCCESS,
  UPLOAD_MEDIA_FAIL,
  UPLOAD_MEDIA_REQUEST,
  UPLOAD_MEDIA_SUCCESS,
} from "../actions/common";

const initialState = {
  loadingMedia: false,
  lookup: null,
  parsedSummary: {},
};

export default function common(state = initialState, action) {
  switch (action.type) {

    case UPLOAD_MEDIA_REQUEST:
    case UPLOAD_MEDIA_ALL_REQUEST:
    case PARSE_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        loadingMedia: true
      };
    case UPLOAD_MEDIA_FAIL:
    case UPLOAD_MEDIA_SUCCESS:
    case UPLOAD_MEDIA_ALL_FAIL:
    case UPLOAD_MEDIA_ALL_SUCCESS:
    case PARSE_BULK_UPLOAD_FAIL:
      return {
        ...state,
        loadingMedia: false
      };

    case GET_LOOKUP_LIST_SUCCESS:
      return {
        ...state,
        lookup: action.payload,
      };

    case PARSE_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        parsedSummary: action.payload,
        loadingMedia: false
      };

    case CLEAR_PREVIOUS_BULK_UPLOAD:
      return {
        ...state,
        parsedSummary: {},
      };


    default:
      return state;
  }
}
